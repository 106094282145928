import { useState } from 'react';
import * as chainHelper from '../../chain/chain-service/helper'
import { getRarityColor } from '../../chain/helpers/parseChainData'
import { monsterImg } from '../../store/image-service'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MonsterStats from './MonsterStats';
// JSON Data
import * as statParser from '../../chain/helpers/parseStats'
import rarity from '../../chain/enumarations/rarity.json'
import localMonsters from '../../chain/data/monsters.json'


function MonsterCard({ handleClick, encounter, index, isLocked }) {
    const MySwal = withReactContent(Swal)
    const localMonster = localMonsters[encounter?.monster?.id]
    const openStats = (encounter) => {
        MySwal.fire({
            html: (
                <MonsterStats encounter={encounter} />
            ),
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            background: '#1a1b20'
        })
    }
    return (
        <div key={index} className="latest-games-items mb-30">
            <div className="latest-games-thumb">
                {isLocked ? '' : (
                    <>
                        <div className="hp-tag">
                            <a className="button-hover-custom"> ❤️ {statParser.getHealtPoints(encounter?.monster?.bcData?.getProp('stats'))}</a>
                        </div>
                        <div className="stamina-tag">
                            <a className="button-hover-custom"> ⚡ -{chainHelper.calculateValue(encounter?.details?.bcData?.getProp('staminacost'))}</a>
                        </div>
                    </>
                )}
                <div className="level-tag monster">
                    <a className="button-hover-custom" style={isLocked ? { color: 'red' } : {}}> lv {encounter?.details?.bcData?.getProp('requiredLevel')}</a>
                </div>
                <a onClick={() => handleClick(encounter)} className="button-hover-custom" ><img style={isLocked ? { filter: 'brightness(20%)' } : {}} src={monsterImg(encounter?.monster?.id)} alt="" /></a>
            </div>
            <div style={getRarityColor(encounter?.monster?.bcData)} className="latest-games-content">
                {isLocked ? (
                    <>
                        <div className="lg-tag-red"  >
                            <a style={{ background: 'red' }} className="button-hover-custom">Locked</a>
                        </div>
                        <p>Level Up your hero to unlock encounter.</p>

                    </>
                ) : (
                    <>
                        <div className="lg-tag">
                            <a className="button-hover-custom">{isLocked ? 'Locked' : rarity[encounter?.monster?.bcData.getProp('rarity')]}</a>
                        </div>
                        <div className="stat-tag">
                            <a onClick={() => openStats(encounter)} className="btn transparent-btn" style={{ color: 'white' }}> <i className="fas fa-info" /> info</a>
                        </div>
                        <h6><a className="button-hover-custom" >{localMonster?.name} <span></span></a></h6>
                        {/* <p>Experience Reward: <span style={{ color: 'yellow' }}>{chainHelper.calculateValue(encounter?.details?.experienceReward)} 🟡</span></p> */}
                    </>
                )}
            </div>
        </div >
    )
}

export default MonsterCard