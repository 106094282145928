import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { equipmentImg, equipmentSubtypeImg, slotImg, subtypeImg } from "../../store/image-service"
import EquipmentStats from "./EquipmentStats"
import { getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData';
import { selectedHeroSelector } from '../../store/selectors'
// JSON Data
import slotName from '../../chain/enumarations/slotName.json'
import subTypes from '../../chain/enumarations/subTypes.json'
import equipments from '../../chain/data/equipmentPool.json'
import rarity from '../../chain/enumarations/rarity.json'

function EquipmentCard({ equipment, index, handleClick, selectedHero, chainId }) {
    const poolEquipment = equipments[equipment.poolId]
    const MySwal = withReactContent(Swal)
    const openStats = (equipment) => {
        MySwal.fire({
            html: (
                <div className="pt-50">
                    <EquipmentStats equipment={equipment} chainId={chainId} />
                </div>
            ),
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            background: '#1a1b20'
        })
    }
    return (
        <div key={index} className="latest-games-items mb-30">
            <div className="latest-games-thumb">
                <div className="level-tag">
                    {selectedHero && selectedHero?.level ? (<a style={{ color: (parseInt(selectedHero.level) < parseInt(equipment?.levelRequirement) ? 'red' : 'white') }}> lv {equipment?.levelRequirement}</a>) : (<a> lv {equipment?.levelRequirement}</a>)}
                </div>
                <div className="subtype-tag">
                    <img src={slotImg(subTypes[poolEquipment?.bcData?.subtypeId]?.slotId, poolEquipment?.bcData?.rarity)} />
                </div>
                <a><img onClick={() => handleClick(equipment)} src={equipmentSubtypeImg(poolEquipment?.bcData?.subtypeId)} alt="" /></a>
            </div>
            <div style={getRarityColor(poolEquipment?.bcData)} className=" latest-games-content">
                <div className="stat-tag">
                    <a onClick={() => openStats(equipment)} style={{ color: 'white' }} className="btn transparent-btn"> <i className="fas fa-info" /> info</a>
                </div>
                <h6><a style={getRarityTextColor(poolEquipment?.bcData)}>{poolEquipment?.name} <span>(+{equipment?.upgradeLevel})</span></a></h6>
            </div>
        </div>
    )
}

export default EquipmentCard