import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';

import EquipmentDetail from './EquipmentDetail';

function EquipmentDetailPage() {
    return (
        <>
            <HeaderCustom />
            <main>
                <EquipmentDetail />
            </main>
            <Footer />
        </>
    )
}

export default EquipmentDetailPage
