import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import HeaderCustom from "../../components/header/HeaderCustom";
import "./hideout.css";

const Hideout = () => {
  const navigate = useHistory();
  const [isCraftHover, setIsCraftHover] = React.useState(false);
  const [isHireHoreHover, setIsHireHeroHover] = React.useState(false);
  const [isUpgradeHover, setIsUpgradeHover] = React.useState(false);
  const [isMarketHover, setIsMarketHover] = React.useState(false);
  const [isZoneHover, setIsZoneHover] = React.useState(false);
  const [isArenaHover, setIsArenaHover] = React.useState(false);
  const [isCastleHover, setIsCastleHover] = React.useState(false);
  const [isFontainHover, setIsFontainHover] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  let iframeReady = false;
  let messagesQueue = [];
  let iframeContent;

  const parseMessage = (event) => {
    if (!event.data || !event.data.type) return;
    switch (event.data.type) {
      case "IFrameReady":
        iframeReady = true;
        // --- send queue messages
        // this.messagesQueue.forEach(message => {
        //   this.sendMessage(message.type, message.data);
        // });
        break;
      case "StartGame":
        break;
      case "Navigation":
        switch (event.data.path) {
          case "Crafter":
            navigate.push('/select-crafting-bucket')
            break;
          case "Zones":
            navigate.push('/zones')
            break;
          case "Refinery":
            navigate.push('/upgrade-equipment')
            break;
          case "PUS":
            navigate.push('/premium-store')
            break;
          case "Inventory":
            navigate.push('/inventory')
            break;
          case "HeroHQ":
            navigate.push('/select-hero-bucket');
            break;
        }
        break;
    }
  }

  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
    iframeContent = document.getElementById("game-iframe").contentWindow;
    window.addEventListener("message", parseMessage.bind(this));
  }, []);


  return (
    <section className="latest-match-area">
      <div className="container-fluid">
        <div className="row">
          <iframe id='game-iframe' width={'100%'} src="https://playcanv.as/e/p/yg44qNrL"></iframe>
        </div>
        <HeaderCustom />
        {/* <div className={isMobile ? "mobile-main-page-body" : ''}>
        <img
          className={isMobile ? "" : "main-screen-img"}
          src="/assets/img/mainpage/Map.webp"
          alt=""
        />
        <button
          onClick={() => navigate.push("/select-crafting-bucket")}
          onMouseEnter={() => setIsCraftHover(true)}
          onMouseLeave={() => setIsCraftHover(false)}
          className="crafting-button"
        >
          <span className="hideout-button">Crafters</span>
        </button>
        {isCraftHover && (
          <img
            src="/assets/img/mainpage/Craft.webp"
            alt=""
            className="craft-hover-img"
          />
        )}
        {isZoneHover && (
          <img
            src="/assets/img/mainpage/Zone.webp"
            alt=""
            className="zone-hover-img"
          />
        )}
        <button
          onMouseEnter={() => setIsZoneHover(true)}
          onMouseLeave={() => setIsZoneHover(false)}
          onClick={() => navigate.push("/zones")}
          className="zone-button"
        >
          <span className="hideout-button">Portal</span>
        </button>
        {isCastleHover && (
          <img
            src="/assets/img/mainpage/Castle"
            alt=""
            className="castle-hover-img"
          />
        )}
        <button
          onMouseEnter={() => setIsCastleHover(true)}
          onMouseLeave={() => setIsCastleHover(false)}
          onClick={() => navigate.push("/inventory")}
          className="guild-hall-button"
        >
          <span className="hideout-button">Guild Hall</span>
        </button>
        {isArenaHover && (
          <img
            src="/assets/img/mainpage/Arena.webp"
            alt=""
            className="arena-hover-img"
          />
        )}
        <button
          onMouseEnter={() => setIsArenaHover(true)}
          onMouseLeave={() => setIsArenaHover(false)}
          // onClick={() => navigate('/aetherforge-isle')}
          className="arena-button"
        >
          <span className="hideout-button">Arena(Soon)</span>
        </button>
        {isHireHoreHover && (
          <img
            src="/assets/img/mainpage/MintHero.webp"
            alt=""
            className="mint-hero-hover-img"
          />
        )}
        <button
          onMouseEnter={() => setIsHireHeroHover(true)}
          onMouseLeave={() => setIsHireHeroHover(false)}
          onClick={() => navigate.push("/select-hero-bucket")}
          className="guild-hire-button"
        >
          <span className="hideout-button">Hero Headquarters</span>
        </button>
        {isMarketHover && (
          <img
            src="/assets/img/mainpage/Market.webp"
            alt=""
            className="market-hover-img"
          />
        )}
        <button
          onMouseEnter={() => setIsMarketHover(true)}
          onMouseLeave={() => setIsMarketHover(false)}
          onClick={() => navigate.push("/premium-store")}
          className="market-button"
        >
          <span className="hideout-button">Premium Market</span>
        </button>
        {isUpgradeHover && (
          <img
            src="/assets/img/mainpage/Upgrade.webp"
            alt=""
            className="upgrade-hover-img"
          />
        )}
        <button
          onMouseEnter={() => setIsUpgradeHover(true)}
          onMouseLeave={() => setIsUpgradeHover(false)}
          onClick={() => navigate.push("/upgrade-equipment")}
          className="upgrade-button"
        >
          <span className="hideout-button">Refinery</span>
        </button>
        {isFontainHover && (
          <img
            src="/assets/img/mainpage/Fontain.webp"
            alt=""
            className="fontain-hover-img"
          />
        )}
        <button
          onMouseEnter={() => setIsFontainHover(true)}
          onMouseLeave={() => setIsFontainHover(false)}
          className="fontain-button"
        >

        </button>
      </div> */}

      </div>
    </section>
  );
};

export default Hideout;
