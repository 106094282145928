import { store } from '../../store'
import Web3 from 'web3'

// ABIS
import storeManagerABI from '../../chain/abis/abi_StoreManager.json'

export const getStoreItems = async () => {
  const storeManagerAddress = await store.getState().managers.managers.storeManagerAddress
  const web3 = new Web3(window.ethereum)
  const storeManagerContract = new web3.eth.Contract(
    storeManagerABI,
    storeManagerAddress
  )
  return await storeManagerContract.methods.getStoreItems().call()
}
