import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    masters: null
}

export const setMasters = (state) => async (dispatch) => {
    await dispatch(SET_MASTERS({ state }))
}

export const mastersSlice = createSlice({
    name: 'masters',
    initialState,
    reducers: {
        SET_MASTERS: (state, action) => {
            state.masters = action.payload
        }
    }
})

export const {
    SET_MASTERS
} = mastersSlice.actions
export default mastersSlice.reducer
