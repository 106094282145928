import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import ScrollToTop from "react-scroll-to-top";
import { useDispatch, useSelector } from "react-redux";
import { heroImg } from "../../store/image-service";
import { ethers } from "ethers";
import Web3 from "web3";
import * as transactionModals from '../../components/modals/Transactions.jsx'
// Selectors
import {
  selectedHeroSelector,
  walletSelector,
  managersSelector,
} from "../../store/selectors";
// ChainPicker
import { chainPicker } from "../../chain/helpers/chainInfo";
import { SET_WALLET } from "../../store/modules/wallet";
import { SET_COLLECTIONS } from "../../store/modules/collections";
import { SET_MANAGERS } from "../../store/modules/managers";
import { SET_MASTERS } from "../../store/modules/masters";
import { SET_SELECTED_HERO } from "../../store/modules/selectedHero";
import { SET_TRANSACTION_HISTORY } from "../../store/modules/transactionHistory";
import { SET_LATEST_TRANSACTION } from "../../store/modules/latestTransaction";
// ABIS
import gameMasterABI from "../../chain/abis/abi_GameMaster.json";
// Chain Services
import * as chainHelper from "../../chain/chain-service/helper";
// JSON Data
import characterClasses from "../../chain/data/characterClasses.json";
import race from "../../chain/enumarations/race.json";
import { getRarityTextColor } from "../../chain/helpers/parseChainData";
import { Fade } from "react-reveal";


export default function HeaderCustom() {

  const [isMobile, setIsMobile] = React.useState(false);
  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
    // //SubMenu Dropdown Toggle
    // if ($('.menu-area li.menu-item-has-children ul').length) {
    //     $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
    // }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      // var mobileMenuContent = $('.menu-area .push-menu').html();
      // $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      // //Dropdown Button
      // $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
      //     $(this).toggleClass('open');
      //     $(this).prev('ul').slideToggle(500);
      // });

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll && isMobile) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
      }
    });
  }, []);

  const handleChainChange = async () => {
    await disconnectWallet();
    await connectWallet();
  };
  const setCollectionsAndManagers = async (
    gameMasterContract,
    gameMasterAddress
  ) => {
    const heroManagerAddress = await gameMasterContract.methods
      .heroManager()
      .call();
    const equipmentMasterAddress = await gameMasterContract.methods
      .equipmentMaster()
      .call();
    const zoneManagerAddress = await gameMasterContract.methods
      .zoneManager()
      .call();
    const storeManagerAddress = await gameMasterContract.methods
      .storeManager()
      .call();
    const materialManagerAddress = await gameMasterContract.methods
      .materialManager()
      .call();
    const craftingBucketManagerAddress = await gameMasterContract.methods
      .craftingBucketManager()
      .call();
    const heroBucketManagerAddress = await gameMasterContract.methods
      .heroBucketManager()
      .call();
    const equipmentManagerAddress = await gameMasterContract.methods
      .equipmentManager()
      .call();
    const heroCollectionAddress = await gameMasterContract.methods
      .heroCollection()
      .call();
    const equipmentCollectionAddress = await gameMasterContract.methods
      .equipmentCollection()
      .call();
    const materialCollectionAddress = await gameMasterContract.methods
      .materialCollection()
      .call();
    dispatch(
      SET_MASTERS({
        gameMasterAddress,
        equipmentMasterAddress,
      })
    );
    dispatch(
      SET_MANAGERS({
        heroManagerAddress,
        zoneManagerAddress,
        storeManagerAddress,
        materialManagerAddress,
        craftingBucketManagerAddress,
        heroBucketManagerAddress,
        equipmentManagerAddress,
      })
    );
    dispatch(
      SET_COLLECTIONS({
        heroCollectionAddress,
        equipmentCollectionAddress,
        materialCollectionAddress,
      })
    );
  };

  const [currencySymbol, setCurrencySymbol] = React.useState("ETH");
  const { wallet } = useSelector(walletSelector);
  const { selectedHero } = useSelector(selectedHeroSelector);
  const { managers } = useSelector(managersSelector);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const web3 = new Web3(window.ethereum);
  const connectWallet = async () => {
    if (!window.ethereum) {
      alert("Please install MetaMask");
      window.open("https://metamask.io/download.html", "_blank");
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    try {
      const [account] = await provider.send("eth_requestAccounts", []);
      if (account) {
        console.log(account)
        const balance = await provider.getBalance(account);
        const chainId = await provider
          .getNetwork()
          .then(({ chainId }) => chainId);
        try {
          const { gameMasterAddress, symbol } = chainPicker(chainId);
          const gameMasterContract = new web3.eth.Contract(
            gameMasterABI,
            gameMasterAddress
          );
          setCurrencySymbol(symbol);
          dispatch(
            SET_WALLET({
              address: account,
              balance: ethers.utils.formatEther(balance),
              chainId: chainId.toString(),
              symbol: symbol,
            })
          );
          await setCollectionsAndManagers(gameMasterContract, gameMasterAddress);
        } catch (err) {
          transactionModals.NotSupportedNetworkError("Chain is not supported yet! ChainId:" + chainId)
          console.log(err);
          console.log("Chain is not supported yet! ChainId:" + chainId);
          return;
        }
      }
    } catch (err) {
      transactionModals.TransactionError('Please unlock Metamask')
    }
  };

  const disconnectWallet = async () => {
    dispatch(SET_WALLET(null));
    dispatch(SET_MASTERS(null));
    dispatch(SET_MANAGERS(null));
    dispatch(SET_COLLECTIONS(null));
    dispatch(SET_SELECTED_HERO(null));
    dispatch(SET_TRANSACTION_HISTORY(null));
    dispatch(SET_LATEST_TRANSACTION(null));
    navigate.push("/");
  };

  const checkConnection = async () => {
    // const accounts = await ethereum.request({ method: "eth_accounts" });

    // if (accounts.length !== 0) {
    //   const account = accounts[0];
    //   console.log("Found an authorized account:", account);
    //   setCurrentAccount(account);
    // } else {
    //   console.log("No authorized account found");
    // }
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const [account] = await provider.send("eth_requestAccounts", []);
    } catch (err) {
      console.log(err)
      transactionModals.TransactionError('Please unlock Metamask')
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        handleChainChange();
      });
      window.ethereum.on("accountsChanged", () => {
        handleChainChange();
      });
    }
  });

  useEffect(() => {
    if (window.ethereum && wallet) {
      checkConnection();
    }
  }, []);
  return (
    <header>
      <ScrollToTop smooth top="500" color="#000" />
      <div className="header-top-area s-header-top-area d-none d-lg-block">
        <div className="container-fluid s-container-full-padding">
          <div className="row align-items-center">
            <div className="col-lg-6 d-none d-lg-block"></div>
          </div>
        </div>
      </div>
      <div id="sticky-header" style={window.location.pathname == '/aetherforge-isle' ? { backgroundImage: 'none', backgroundColor: 'transparent' } : {}} className="transparent-header menu-area">
        <div className="container-fluid s-container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler two">
                <i className="fas fa-bars" />
              </div>

              <Fade up >
                <div style={window.location.pathname == '/aetherforge-isle' ? { backgroundImage: 'none', backgroundColor: 'transparent' } : {}} className="main-menu  menu-style-two">
                  <nav>
                    <div className="logo">
                      {/* <h2> GoC - v0.0.6</h2> */}
                      <Link to="/"><img src="assets/img/goc/logo/GocLogo.webp" alt="Logo" /></Link>
                    </div>
                    <div className="navbar-wrap push-menu d-none d-lg-flex">
                      <ul className="navigation">
                        {/* <li className="menu-item-has-children">
                        <Link to="/">Home</Link>
                      </li> */}
                        {/* <li className="menu-item-has-children">
                        <Link to="/zones">Zones</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/zones">Select Zone</Link>
                          </li>
                          <li>
                            <Link to="/emerald-canopy">EMERALD CANOPY</Link>
                          </li>
                        </ul>
                      </li> */}
                        {wallet ? (<li>
                          <Link style={{ color: 'gold' }} to="/aetherforge-isle">Aetherforge Isle</Link>
                        </li>) : ''}
                        {/* <li>
                        <Link
                          onClick={() =>
                            navigate.push("/select-crafting-bucket")
                          }
                          className="red-color"
                          to="#"
                        >
                          Craft
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => navigate.push("/inventory")}
                          className="red-color"
                          to="#"
                        >
                          Inventory
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => navigate.push("/upgrade-equipment")}
                          className="red-color"
                          to="#"
                        >
                          Upgrade
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => navigate.push("/premium-store")}
                          className="red-color"
                          to="#"
                        >
                          Premium Store
                        </Link>
                      </li> */}

                        {/* <li className='menu-item-has-children'><Link to="/about-us">Pages</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/about-us">About Story</Link></li>
                                                    <li><Link to="/upcoming-games">Upcoming Games</Link></li>
                                                    <li><Link to="/game-single">Game Single</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/overview">Overview</Link></li>
                                            <li><Link to="/community">Community</Link></li>
                                            <li><Link to="/shop">Store</Link></li>
                                            <li className='menu-item-has-children'><Link to="/blogs">Blog</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/blogs">News Page</Link></li>
                                                    <li><Link to="/blog-details">News Details</Link></li>
                                                </ul>
                                            </li> */}
                        {wallet ? (
                          <li className="header-shop-cart">
                            <Link to="#">
                              {wallet.address.slice(0, 4)}...
                              {wallet.address.slice(-5)} (
                              {Number(wallet.balance).toFixed(2) +
                                " " +
                                currencySymbol})
                            </Link>
                            <ul className="minicart">
                              <li>
                                <div className="total-price">
                                  <span className="f-right small">
                                    {wallet.address}
                                  </span>
                                </div>
                                <div className="total-price">
                                  <span className="f-left">Balance:</span>
                                  <span className="f-right">
                                    {Number(wallet.balance).toFixed(2) +
                                      " " +
                                      currencySymbol}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="checkout-link">
                                  <a
                                    onClick={() =>
                                      navigate.push("/transaction-history")
                                    }
                                  >
                                    {" "}
                                    Transaction History
                                  </a>
                                  <Link
                                    onClick={disconnectWallet}
                                    className="red-color"
                                    to="#"
                                  >
                                    Disconnect Wallet
                                  </Link>
                                </div>
                              </li>
                            </ul>
                          </li>
                        ) : (
                          <li>
                            <Link onClick={connectWallet} to="#">
                              Connect Wallet
                            </Link>
                          </li>
                        )}
                        {wallet ? (
                          <li className="header-shop-cart">
                            <Link to="/select-hero">
                              {" "}
                              {selectedHero && selectedHero.name
                                ? selectedHero.name
                                : "Select Hero"}
                              <span>
                                {selectedHero && selectedHero.level
                                  ? selectedHero.level
                                  : ""}
                              </span>{" "}
                              <br />{" "}
                              {selectedHero &&
                                selectedHero.level &&
                                selectedHero.staminaLastValue
                                ? "⚡ " +
                                chainHelper.calculateValue(
                                  selectedHero.staminaLastValue
                                ) +
                                "/" +
                                chainHelper.calculateStatValueArray(
                                  selectedHero?.stats[12]
                                )
                                : ""}{" "}
                            </Link>
                            <ul className="minicart">
                              {selectedHero && selectedHero.name ? (
                                <>
                                  <li className="d-flex align-items-start">
                                    <div className="cart-img">
                                      <a>
                                        <img
                                          src={heroImg(selectedHero?.poolId)}
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div className="cart-content">
                                      <h4 style={getRarityTextColor(selectedHero)}>{selectedHero ? selectedHero.name : ""}</h4>
                                      <div className="cart-price">
                                        <span className="new">
                                          Level:{" "}
                                          {selectedHero ? selectedHero.level : ""}
                                        </span>
                                      </div>
                                      <div className="cart-price">
                                        <span className="new">
                                          Race:{" "}
                                          {selectedHero ? race[selectedHero.race] : ""}
                                        </span>
                                      </div>
                                      <div className="cart-price">
                                        <span className="new">
                                          Class:{" "}
                                          {selectedHero
                                            ? characterClasses[
                                              selectedHero.characterClass
                                            ]?.name
                                            : ""}
                                        </span>
                                      </div>
                                      <div className="cart-price">
                                        <span className="new">
                                          Max Stamina:{" "}
                                          {selectedHero && selectedHero.stats
                                            ? chainHelper.calculateStatValue(
                                              selectedHero.stats[12]
                                            )
                                            : ""}
                                        </span>
                                      </div>
                                      <div className="cart-price">
                                        <a onClick={() => navigate.push('/inventory')} className="btn">view details</a>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="total-price">
                                      <span className="f-left">Experience:</span>
                                      <span className="f-right">
                                        {chainHelper.calculateValue(
                                          selectedHero?.experience
                                        )} 🟡
                                      </span>
                                    </div>
                                  </li>
                                </>) : ''}
                              <li>
                                <div className="checkout-link">
                                  <Link className="red-color" to="/select-hero">
                                    Select Hero
                                  </Link>
                                  <a
                                    onClick={() =>
                                      navigate.push("/select-hero-bucket")
                                    }
                                  >
                                    New Hero
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </li>
                        ) : ''}
                      </ul>
                    </div>
                  </nav>
                </div>
              </Fade>
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    {/* <h2> GoC - v0.0.6</h2> */}
                    <Link to="/"><img src="assets/img/goc/logo/GocLogo.webp" alt="Logo" /></Link>
                  </div>
                  <div className="menu-outer">
                    <div className="navbar-wrap push-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li className="show  menu-item-has-children">
                          <Link to="/">Home</Link>
                          {/* <ul className="submenu">
                                                        <li ><Link to="/">Home One</Link></li>
                                                        <li><Link to="/index-2">Home Two</Link></li>
                                                        <li><Link to="/index-3">Home Three</Link></li>
                                                        <li ><Link to="/index-4">Home Four</Link></li>
                                                        <li><Link to="/index-5">Home Five</Link></li>
                                                        <li><Link to="/index-6">Home Six</Link></li>
                                                        <li><Link to="/index-7">Home Seven</Link></li>

                                                    </ul> */}
                        </li>
                        <li className="menu-item-has-children">
                          <Link to="/zones">Zones</Link>
                          <ul className="submenu">
                            <li>
                              <Link to="/zones">Zones</Link>
                            </li>
                            <li>
                              <Link to="/emerald-canopy">Emerald Canopy</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link style={{ color: 'gold' }} to="/aetherforge-isle">Aetherforge Isle</Link>
                        </li>
                        <li>
                          <Link
                            onClick={() =>
                              navigate.push("/select-crafting-bucket")
                            }
                            className="red-color"
                            to="#"
                          >
                            Craft
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => navigate.push("/inventory")}
                            className="red-color"
                            to="#"
                          >
                            Inventory
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => navigate.push("/upgrade-equipment")}
                            className="red-color"
                            to="#"
                          >
                            Upgrade
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => navigate.push("/premium-store")}
                            className="red-color"
                            to="#"
                          >
                            Premium Store
                          </Link>
                        </li>
                        {/* <li className='menu-item-has-children'><Link to="/about-us">Pages</Link>
                                                    <ul className="submenu">
                                                        <li><Link to="/about-us">About Story</Link></li>
                                                        <li><Link to="/upcoming-games">Upcoming Games</Link></li>
                                                        <li><Link to="/game-single">Game Single</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/overview">Overview</Link></li>
                                                <li><Link to="/community">Community</Link></li>
                                                <li><Link to="/shop">Store</Link></li>
                                                <li className='menu-item-has-children'><Link to="/blogs">Blog</Link>
                                                    <ul className="submenu">
                                                        <li><Link to="/blogs">News Page</Link></li>
                                                        <li><Link to="/blog-details">News Details</Link></li>
                                                    </ul>
                                                </li> */}
                        {wallet ? (
                          <li className="header-shop-cart">
                            <Link to="/transaction-history">
                              {wallet.address.slice(0, 4)}...
                              {wallet.address.slice(-5)} <br /> Balance:{" "}
                              {Number(wallet.balance).toFixed(2) +
                                " " +
                                currencySymbol}
                            </Link>
                            <ul className="minicart">
                              <li>
                                <div className="total-price">
                                  <span className="f-right small">
                                    {wallet.address}
                                  </span>
                                </div>
                                <div className="total-price">
                                  <span className="f-left">Balance:</span>
                                  <span className="f-right">
                                    {Number(wallet.balance).toFixed(2) +
                                      " " +
                                      currencySymbol}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="checkout-link">
                                  <a
                                    onClick={() =>
                                      navigate.push("/transaction-history")
                                    }
                                  >
                                    {" "}
                                    Transaction History
                                  </a>
                                  <Link
                                    onClick={() => disconnectWallet()}
                                    className="red-color"
                                    to="#"
                                  >
                                    Disconnect Wallet
                                  </Link>
                                </div>
                              </li>
                            </ul>
                          </li>
                        ) : (
                          <li>
                            <Link onClick={() => connectWallet()} to="#">
                              Connect Wallet
                            </Link>
                          </li>
                        )}
                        <li className="header-shop-cart">
                          <Link to="/select-hero">
                            {selectedHero && selectedHero.name
                              ? selectedHero.name + " "
                              : "Select Hero"}
                            lv.
                            {selectedHero && selectedHero.level
                              ? selectedHero.level
                              : ""}

                            <br />{" "}
                            {selectedHero &&
                              selectedHero.level &&
                              selectedHero.staminaLastValue
                              ? "⚡ " +
                              chainHelper.calculateValue(
                                selectedHero.staminaLastValue
                              ) +
                              "/" +
                              chainHelper.calculateStatValueArray(
                                selectedHero?.stats[12]
                              )
                              : ""}
                            {selectedHero && selectedHero.name ? (<>
                              <br />
                              Experience: {chainHelper.calculateValue(
                                selectedHero?.experience
                              )} 🟡
                            </>) : ''}

                          </Link>
                        </li>
                        <li>
                          <Link className="red-color" to="/select-hero-bucket">
                            New Hero
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={disconnectWallet}
                            className="red-color"
                            to="#"
                          >
                            Disconnect Wallet
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li><a target='_blank' href="https://discord.gg/atycQemZ8g"><i className="fab fa-discord" /></a></li>
                      <li><a target='_blank' href="https://twitter.com/guildofchains"><i className="fab fa-twitter" /></a></li>
                      <li><a target='_blank' href="https://medium.com/@guildofchains"><i className="fab fa-medium" /></a></li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
            {/* Modal Search */}
            <div
              className="modal fade"
              id="search-modal"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." />
                    <button>
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </header >
  );
}
