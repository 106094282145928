import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { walletSelector, managersSelector, selectedHeroSelector, craftingBucketSelector } from '../../store/selectors'
import Slider from 'react-slick';
import { materialImg } from '../../store/image-service';
import * as transactionModals from '../../components/modals/Transactions.jsx'
import * as craftingResultModals from '../../components/modals/CraftingResult.jsx'
import PoolEquipmentCard from '../../components/equipment/PoolEquipmentCard';
// Chain Services
import * as heroManager from '../../chain/chain-service/heroManager'
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import * as heroBucketManager from '../../chain/chain-service/heroBucketManager'
import * as gameMaster from '../../chain/chain-service/gameMaster'
import * as materialCollection from '../../chain/chain-service/materialCollection'
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import craftingBuckets from '../../chain/data/craftingBuckets.json'
import equipments from '../../chain/data/equipmentPool.json'
import materialData from '../../chain/data/materials.json'
import slotName from '../../chain/enumarations/slotName.json'
import subTypes from '../../chain/enumarations/subTypes.json'
import stats from '../../chain/enumarations/stats.json'
import rarity from '../../chain/enumarations/rarity.json'
import { getRarityColor } from '../../chain/helpers/parseChainData';

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-right"></i></button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-left"></i></button>
  );
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function EquipmentList() {
  const settings = {

    dots: false,
    infinite: false,
    speed: 1000,
    nav: true,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          Padding: '50px 50px',
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ]
  }

  const { wallet } = useSelector(walletSelector)
  const { managers } = useSelector(managersSelector)
  const { craftingBucket } = useSelector(craftingBucketSelector)
  const { selectedHero } = useSelector(selectedHeroSelector)
  const [equipmentList, setEquipmentList] = useState([])
  const [materialInventory, setMaterialInventory] = useState([])
  const navigation = useHistory()
  const craftingBucketData = craftingBuckets[craftingBucket?.id]

  const getEquipmentList = () => {
    try {
      let equipmentList = []
      craftingBucketData?.bcData?.craftableEquipments?.map(({ id }) => {
        equipmentList.push(equipments[id])
      })
      setEquipmentList(equipmentList)
    } catch (err) {
      transactionModals.TransactionError(err.message)
    }
  }

  const getPlayerBalance = async () => {
    const playerBalance = await materialCollection.balanceOfBatch(
      Array(170).fill(wallet.address),
      Array.from(Array(170).keys())
    )
    setMaterialInventory(playerBalance)
  }

  const checkMaterialAmount = (materialId, index) => {
    if (parseInt(materialInventory?.[materialId]) >= parseInt(craftingBucketData?.bcData?.requiredMaterialAmounts[index])) {
      return { color: 'green' }
    } else {
      return { color: 'red' }
    }
  }

  const craftEquipment = async () => {
    try {
      transactionModals.StartTransaction()
      const [txGas, gameMasterContract] = await gameMaster.estimateGasCraftEquipment(wallet.address, craftingBucket.id,)
      const txResult = await gameMaster
        .craftEquipment(gameMasterContract, wallet.address, craftingBucket.id, txGas)
        .on('sending', async () => {
        })
        .on('transactionHash', async () => {
          transactionModals.TransactionSent()
        })
        .on('receipt', async (tx) => {
          const txEvents = chainHelper.getCraftingResultTxEvents(tx.events)
          const itemCrafted = chainHelper.parseItemCrafted(txEvents?.itemCrafted)
          const craftedEquipment = await equipmentManager.getEquipment(itemCrafted.equipmentId)
          craftingResultModals.CraftingResult(craftedEquipment)
          getEquipmentList()
          getPlayerBalance()
        })
        .on('error', (err) => {
          transactionModals.TransactionError(err.message)
        })
    } catch (err) {
      if (err.message.includes('burn amount exceeds')) { transactionModals.TransactionError('Not enough materials.') }
      else {
        transactionModals.TransactionError(err.message)
      }
    }
  }

  useEffect(() => {
    if (managers && craftingBucket) {
      delay(500)
      getPlayerBalance()
      getEquipmentList()
    }
  }, [managers, craftingBucket])
  return (

    <div className="area-bg-crafting pt-60">
      {/* latest-games-area */}
      <section className="latest-games-area pt-120">
        <div className="container">
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-8">
                    <div className="justify-content-center pb-20">
                      <a onClick={() => navigation.push('/select-crafting-bucket')} className="btn btn-info">Back</a>
                    </div>
                    <div className="section-title title-style-two text-center mb-60">
                      <span>Required</span>
                      <h2> <span>Materials:</span></h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {craftingBucketData?.bcData?.requiredMaterialIds?.map((materialId, index) => (
                    <div key={index} className="col-lg-12">
                      <div className="latest-match-box mb-30">
                        <div className="just-gamers-list-icon">
                          <img src={materialImg(materialId)} alt="" />
                        </div>
                        <div className="just-gamers-list-content justify-content-center fix">
                          <h5 style={checkMaterialAmount(materialId, index)} > {materialData[materialId].name} </h5>
                          <p style={checkMaterialAmount(materialId, index)} >Amount: {materialInventory?.[materialId]}/{craftingBucketData?.bcData?.requiredMaterialAmounts[index]} </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='col-lg-8'>
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-8">
                    <div className="section-title title-style-two text-center mb-60">
                      <span>Crafting</span>
                      <h2> Recipe: <span>{craftingBucket?.name}</span></h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {equipmentList.length > 0 ? (
                      <Slider className="latest-games-active slick" {...settings}>
                        {equipmentList.map((equipment, index) => (
                          <>
                            <PoolEquipmentCard equipment={equipment} index={index} selectedHero={selectedHero} handleClick={console.log} dropChance={chainHelper.calculateEquipmentDropChance(craftingBucketData, index) + '%'} />
                          </>
                        ))}
                      </Slider>
                    ) : ''}
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8">
                        <div className="section-title title-style-two text-center mb-60">
                          <a onClick={() => craftEquipment()} className="btn btn-info">craft!</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* latest-games-area-end */}
      {/* about-us-area */}

      {/* about-us-area-end */}
    </div>
  )
}

export default EquipmentList;
