import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import HeroList from './HeroList';

function HeroBucketDetails() {
    return (
        <>
            <HeaderCustom />
            <main>
                <HeroList />
            </main>
            <Footer />
        </>
    )
}

export default HeroBucketDetails
