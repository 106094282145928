import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';

import HeroDetail from './HeroDetail';

function HeroDetailPage() {
    return (
        <>
            <HeaderCustom />
            <main>
                <HeroDetail />
            </main>
            <Footer />
        </>
    )
}

export default HeroDetailPage
