import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    craftingBucket: null
}

export const setCraftingBucket = (state) => async (dispatch) => {
    await dispatch(SET_CRAFTING_BUCKET(state))
}

export const craftingBucketSlice = createSlice({
    name: 'craftingBucket',
    initialState,
    reducers: {
        SET_CRAFTING_BUCKET: (state, action) => {
            state.craftingBucket = action.payload
        }
    }
})

export const { SET_CRAFTING_BUCKET } = craftingBucketSlice.actions
export default craftingBucketSlice.reducer
