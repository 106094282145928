import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import EquipmentList from './EquipmentList';

function CraftingBucketDetails() {
    return (
        <>
            <HeaderCustom />
            <main>
                <EquipmentList />
            </main>
            <Footer />
        </>
    )
}

export default CraftingBucketDetails
