import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { materialImg } from '../../store/image-service'
import { collectionsSelector, mastersSelector, walletSelector } from '../../store/selectors'
import * as transactionModals from '../../components/modals/Transactions.jsx'
import * as upgradeModals from '../../components/modals/Upgrade.jsx'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// Chain Services
import * as gameMaster from '../../chain/chain-service/gameMaster'
import * as storeManager from '../../chain/chain-service/storeManager'
import * as materialCollection from '../../chain/chain-service/materialCollection'
import * as equipmentCollection from '../../chain/chain-service/equipmentCollection'
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import * as materialManager from '../../chain/chain-service/materialManager'
import { getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData'
// JSON Data
import subTypes from '../../chain/enumarations/subTypes.json'
import EquipmentCard from '../../components/equipment/EquipmentCard'
import poolEquipments from '../../chain/data/equipmentPool.json'
function StoreList() {

  const { wallet } = useSelector(walletSelector)
  const { collections } = useSelector(collectionsSelector)
  const { masters } = useSelector(mastersSelector)
  const [storeItems, setStoreItems] = useState([])
  const upgradeScreenRef = useRef(null)
  const resultScreenRef = useRef(null)
  const [itemAmounts, setItemAmounts] = useState({})
  const [selectedEquipment, setSelectedEquipment] = useState()
  const [resultEquipment, setResultEquipment] = useState()
  const [selectedItemType, setSelectedItemType] = useState('')
  const [selectedRarity, setSelectedRarity] = useState('')
  const [zoneUpgradeScroll, setZoneUpgradeScroll] = useState()
  const [premiumUpgradeScroll, setPremiumUpgradeScroll] = useState()
  const [premiumUpgradeScrollSlots, setPremiumUpgradeScrolllSlots] = useState([false, false, false, false, false, false, false, false])
  const [premiumUpgradeScrollSlot1, setPremiumUpgradeScrolllSlot1] = useState(false)
  const [premiumUpgradeScrollSlot2, setPremiumUpgradeScrolllSlot2] = useState(false)
  const [premiumUpgradeScrollSlot3, setPremiumUpgradeScrolllSlot3] = useState(false)
  const [premiumUpgradeScrollSlot4, setPremiumUpgradeScrolllSlot4] = useState(false)
  const [premiumUpgradeScrollSlot5, setPremiumUpgradeScrolllSlot5] = useState(false)
  const [premiumUpgradeScrollSlot6, setPremiumUpgradeScrolllSlot6] = useState(false)
  const [premiumUpgradeScrollSlot7, setPremiumUpgradeScrolllSlot7] = useState(false)
  const [premiumUpgradeScrollSlot8, setPremiumUpgradeScrolllSlot8] = useState(false)

  const [inventoryEquipments, setInventoryEquipments] = useState([])
  const [filterInventoryEquipments, setFilterInventoryEquipments] = useState([])

  const openUpgradeItemApproval = async () => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Are you sure? You may lose your equipment during upgrade.',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      background: '#1a1b20',
      customClass: {
        confirmButton: 'btn btn-green',
        cancelButton: 'btn btn-danger'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        upgradeItem()
      } else if (result.isCanceled) {
        MySwal.fire('Upgrade canceled', '', 'info')
      }
    })
  }
  const upgradeItem = async () => {
    try {
      if (selectedEquipment) {
        const pusCount = premiumUpgradeScrollSlots.filter(Boolean).length;
        const txGas = await gameMaster.estimateGasUpgradeEquipment(
          wallet.address,
          selectedEquipment.id,
          pusCount
        )
        await gameMaster
          .upgradeEquipment(masters.gameMasterAddress, wallet.address, selectedEquipment.id, pusCount, txGas)
          .on('sending', async () => {
            transactionModals.StartTransaction()
          })
          .on('transactionHash', async () => {
            transactionModals.TransactionSent()
          })
          .on('receipt', async () => {
            const resultady = await equipmentCollection.ownerOf(selectedEquipment.id)
            if (resultady.toUpperCase() === wallet.address.toUpperCase()) {
              upgradeModals.UpgradeSucccess()
              setResultEquipment(await equipmentManager.getEquipment(selectedEquipment.id))
              setSelectedEquipment(null)
              setUpgradeScroll(selectedEquipment)
              setPUS()
            } else {
              upgradeModals.UpgradeFailure()
              setSelectedEquipment(null)
              setResultEquipment(null)
              setZoneUpgradeScroll(null)
            }
            getEquipmentList()
          })
          .on('error', (err) => {
            transactionModals.TransactionError(err.message)
          })
      } else {
        transactionModals.TransactionError('Please select equipment first.')
      }
    } catch (err) {
      if (err.message.includes('burn amount exceeds balance')) {
        transactionModals.TransactionError('Not enough Upgrade scroll.')
      }
      else {
        transactionModals.TransactionError(err.message)
      }
    }
  }

  const getEquipmentList = async () => {
    setSelectedItemType('')
    setSelectedRarity('')
    setInventoryEquipments([])
    setFilterInventoryEquipments([])
    const equipmentIds = await equipmentCollection.tokensOfOwner(wallet.address);
    const equipmentList = await equipmentManager.getEquipments(equipmentIds.returnData)
    setInventoryEquipments(equipmentList)
    setFilterInventoryEquipments(equipmentList)
  }

  const selectEquipment = async (equipment) => {
    upgradeScreenRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setUpgradeScroll(equipment)
    setSelectedEquipment(equipment)
    setResultEquipment(null)
  }

  const setUpgradeScroll = async (equipment) => {
    const upgradeScrollId = await materialManager.getUpgradeScrollOfZoneById(poolEquipments[equipment?.poolId]?.bcData?.zoneId)
    const playerUpgradeScrollBalance = await materialCollection.balanceOfBatch(
      [wallet.address],
      [upgradeScrollId]
    )
    setZoneUpgradeScroll({ upgradeScrollId, playerUpgradeScrollBalance })
  }

  const setPUS = async () => {
    const premiumUpgradeScrollId = await materialManager.getPremiumUpgradeScrollId()
    const playerPremiumUpgradeScrollBalance = await materialCollection.balanceOfBatch(
      [wallet.address],
      [premiumUpgradeScrollId]
    )
    setPremiumUpgradeScroll({ premiumUpgradeScrollId, playerPremiumUpgradeScrollBalance })
  }

  const updatePremiumUpgradeScrollSlot = async (slotId) => {
    if (slotId === "slot1") {
      setPremiumUpgradeScrolllSlot1(!premiumUpgradeScrollSlot1)
      premiumUpgradeScrollSlots[0] = !premiumUpgradeScrollSlots[0]
    }
    if (slotId === "slot2") {
      setPremiumUpgradeScrolllSlot2(!premiumUpgradeScrollSlot2)
      premiumUpgradeScrollSlots[1] = !premiumUpgradeScrollSlots[1]
    }
    if (slotId === "slot3") {
      setPremiumUpgradeScrolllSlot3(!premiumUpgradeScrollSlot3)
      premiumUpgradeScrollSlots[2] = !premiumUpgradeScrollSlots[2]
    }
    if (slotId === "slot4") {
      setPremiumUpgradeScrolllSlot4(!premiumUpgradeScrollSlot4)
      premiumUpgradeScrollSlots[3] = !premiumUpgradeScrollSlots[3]
    }
    if (slotId === "slot5") {
      setPremiumUpgradeScrolllSlot5(!premiumUpgradeScrollSlot5)
      premiumUpgradeScrollSlots[4] = !premiumUpgradeScrollSlots[4]
    }
    if (slotId === "slot6") {
      setPremiumUpgradeScrolllSlot6(!premiumUpgradeScrollSlot6)
      premiumUpgradeScrollSlots[5] = !premiumUpgradeScrollSlots[5]
    }
    if (slotId === "slot7") {
      setPremiumUpgradeScrolllSlot7(!premiumUpgradeScrollSlot7)
      premiumUpgradeScrollSlots[6] = !premiumUpgradeScrollSlots[6]
    }
    if (slotId === "slot8") {
      setPremiumUpgradeScrolllSlot8(!premiumUpgradeScrollSlot8)
      premiumUpgradeScrollSlots[7] = !premiumUpgradeScrollSlots[7]
    }
  }

  const getStoreItems = async () => {
    setStoreItems([])
    const tempStoreItems = await storeManager.getStoreItems()
    await getPlayerStoreItemBalance(tempStoreItems)
  }
  const filterItemType = (categItem) => {
    setSelectedItemType(categItem)
    const updatedItems = filterInventoryEquipments.filter((curElem) => {
      return subTypes[curElem.subtypeId].slotId === parseInt(categItem);
    })
    setInventoryEquipments(updatedItems);
  }

  const filterItemRarity = (categItem) => {
    setSelectedRarity(categItem)
    const updatedItems = filterInventoryEquipments.filter((curElem) => {
      if (selectedItemType === '')
        return curElem?.rarity === categItem;
      else return curElem?.rarity === categItem && subTypes[curElem.subtypeId].slotId === parseInt(selectedItemType);
    })
    setInventoryEquipments(updatedItems);
  }

  const getPlayerStoreItemBalance = async (tempStoreItems) => {
    setStoreItems([])
    tempStoreItems.map(async (details, index) => {
      console.log(details)
      const playerBalance = await materialCollection.balanceOfBatch(
        [wallet.address],
        [details.materialId]
      )
      itemAmounts[details.id] = 1
      setStoreItems((prev) => [...prev, { details, playerBalance }])
      return true
    })
  }

  useEffect(() => {
    if (wallet && collections) {
      getStoreItems()
      getEquipmentList()
      setPUS()
    }
  }, [wallet, collections])
  return (
    <>
      <section ref={upgradeScreenRef} className="latest-match-area upgrade-equipment-bg pt-115 pb-30">
        <div className="container pt-120">
          <div className="row">
            {selectedEquipment ? (
              <div onClick={() => console.log('')} className="col-xl-3 col-lg-3 col-md-12 mb-40">
                <EquipmentCard equipment={selectedEquipment} handleClick={console.log} />
              </div >
            ) : (
              <div onClick={() => console.log('')} className="col-xl-3 col-lg-3 col-md-12 mb-40">
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href ><img src="assets/img/images/equipment_placeholder.webp" alt="" /></a>
                  </div>
                  <div style={getRarityColor({ rarity: "0" })} className=" latest-games-content">
                    <h4><a href >Please select equipment <span></span></a></h4>
                  </div>
                </div>
              </div >
            )
            }
            <div onClick={() => console.log('')} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-40">
              <div className="row mb-100 justify-content-center"></div>
              <div className="row mb-10 justify-content-center">
                <div className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    <img src={materialImg(zoneUpgradeScroll?.upgradeScrollId)} alt="" />
                    <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{zoneUpgradeScroll?.playerUpgradeScrollBalance}</h6>
                  </div>
                </div>
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot1")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot1 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot2")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot2 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
              </div>
              <div className="row mb-10 justify-content-center">
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot3")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot3 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot4")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot4 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot5")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot5 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
              </div>
              <div className="row mb-100 justify-content-center">
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot6")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot6 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot7")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot7 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
                <div onClick={() => updatePremiumUpgradeScrollSlot("slot8")} className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <div className="just-gamers-list-icon">
                    {premiumUpgradeScrollSlot8 ? (
                      <>
                        <img src={materialImg(premiumUpgradeScroll?.premiumUpgradeScrollId)} alt="" />
                        <h6 style={{ marginTop: '-15px', marginLeft: '35px' }}>{premiumUpgradeScroll?.playerPremiumUpgradeScrollBalance}</h6>
                      </>
                    ) : 'Add PUS'}
                  </div>
                </div>
              </div>
              <div className="row mb-10 justify-content-center">
                <div className="col-lg-2 col-md-2 col-sm-2 col-4">
                  <a href onClick={() => openUpgradeItemApproval(wallet.address)} className="btn btn-info">Upgrade!</a>
                </div>
              </div>
            </div>
            {resultEquipment ? (
              <div ref={resultScreenRef} className="col-xl-3 col-lg-3 col-md-12 mb-40">
                <EquipmentCard equipment={resultEquipment} handleClick={selectEquipment} />
              </div >
            ) : (
              <div ref={resultScreenRef} onClick={() => console.log('')} className="col-xl-3 col-lg-3 col-md-12 mb-40">
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href ><img src="assets/img/images/equipment_placeholder.webp" alt="" /></a>
                  </div>
                  <div style={getRarityColor({ rarity: "0" })} className=" latest-games-content">
                    <h4><a href > <span></span></a></h4>
                  </div>
                </div>
              </div >
            )
            }
          </div >
        </div >
      </section >
      <section className="latest-match-area latest-match-bg pt-115 pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three white-title text-center mb-20">
                <h2> <span>Equipment</span> List</h2>
                <p>Click on item to upgrade</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tournament-top-wrap">
                <div className="section-title tournament-title">
                  <h2>Slot <span>Filter</span></h2>
                </div>
                <div className="tournament-menu">
                  <button className={selectedItemType === '' ? 'active' : ''} data-filter="*" onClick={() => getEquipmentList()}>All</button>
                  <button className={selectedItemType === '0' ? 'active' : ''} data-filter=".cat-one" onClick={() => filterItemType("0")}>MAINHAND</button>
                  <button className={selectedItemType === '1' ? 'active' : ''} data-filter=".cat-two" onClick={() => filterItemType("1")}>HELMET</button>
                  <button className={selectedItemType === '2' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("2")}>CHEST</button>
                  <button className={selectedItemType === '3' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("3")}>GLOVES</button>
                  <button className={selectedItemType === '4' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("4")}>BOOTS</button>
                  <button className={selectedItemType === '5' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("5")}>NECKLACE</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-40">
            <div className="col-12">
              <div className="tournament-top-wrap">
                <div className="section-title tournament-title">
                  <h2>Rarity <span>Filter</span></h2>
                </div>
                <div className="tournament-menu">
                  <button className={selectedRarity === '' ? 'active' : ''} data-filter="*" onClick={() => getEquipmentList()}>All</button>
                  <button style={getRarityTextColor({ rarity: '5' })} className={selectedRarity === '5' ? 'active' : ''} data-filter=".cat-one" onClick={() => filterItemRarity("5")}>MYTHIC</button>
                  <button style={getRarityTextColor({ rarity: '4' })} className={selectedRarity === '4' ? 'active' : ''} data-filter=".cat-two" onClick={() => filterItemRarity("4")}>LEGENDARY</button>
                  <button style={getRarityTextColor({ rarity: '3' })} className={selectedRarity === '3' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("3")}>EPIC</button>
                  <button style={getRarityTextColor({ rarity: '2' })} className={selectedRarity === '2' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("2")}>RARE</button>
                  <button style={getRarityTextColor({ rarity: '1' })} className={selectedRarity === '1' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("1")}>UNCOMMON</button>
                  <button style={getRarityTextColor({ rarity: '0' })} className={selectedRarity === '0' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("0")}>COMMON</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {inventoryEquipments?.map((equipment, index) => (
              <div key={index} className="col-xl-3 col-lg-3 col-md-12 mb-40">
                <EquipmentCard equipment={equipment} handleClick={selectEquipment} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default StoreList