import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { walletSelector, managersSelector, heroBucketSelector } from '../../store/selectors'

import * as heroMintResultModals from '../../components/modals/HeroMintResult.jsx'
import * as transactionModals from '../../components/modals/Transactions.jsx'
// Chain Services
import * as heroManager from '../../chain/chain-service/heroManager'
import * as gameMaster from '../../chain/chain-service/gameMaster'
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import heroes from '../../chain/data/poolHeroes.json'
import heroBuckets from '../../chain/data/heroBuckets.json'
import { Fade } from 'react-reveal';
import PoolHeroCard from '../../components/hero/PoolHeroCard';

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-right"></i></button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-left"></i></button>
  );
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function HeroList() {
  const settings = {

    dots: false,
    infinite: false,
    speed: 1000,
    nav: true,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          Padding: '100px 50px',
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ]
  }
  const navigation = useHistory()
  const { wallet } = useSelector(walletSelector)
  const { managers } = useSelector(managersSelector)
  const { heroBucket } = useSelector(heroBucketSelector)
  const [heroChest, setHeroChest] = useState({})
  const [heroList, setHeroList] = useState([])

  const getHeroBucket = async () => {
    const heroBucketData = heroBuckets[heroBucket]
    setHeroChest(heroBucketData)
    await getHeroList(heroBucketData)
  }

  const getHeroList = (heroBucketData) => {
    let heroList = []
    heroBucketData.bcData.getProp('heroes').map(({ id }) => {
      heroList.push(heroes[id])
    })
    setHeroList(heroList)
  }

  const mintHero = async () => {
    try {
      console.log(heroChest)
      transactionModals.StartTransaction()
      const [txGas, gameMasterContract] = await gameMaster.estimateGasBuyHero(heroChest.id, wallet.address, heroChest.bcData.getProp('cost'))
      await gameMaster
        .buyHero(gameMasterContract, heroChest.id, wallet.address, heroChest.bcData.getProp('cost'), txGas)
        .on('transactionHash', () => {
          transactionModals.TransactionSent()
        })
        .on('receipt', async (tx) => {
          const txEvents = chainHelper.getHeroMintTxEvents(tx.events)
          const heroHired = chainHelper.parseHeroHired(txEvents?.heroHired)
          const hero = await heroManager.getHero(heroHired.heroId)
          heroMintResultModals.HeroMintResult(hero, navigation)
        })
        .on('error', (err) => {
          transactionModals.TransactionError(err.message)
        })
    } catch (err) {
      transactionModals.TransactionError(err.message)
    }
  }

  useEffect(() => {
    if (managers && heroBucket) {
      getHeroBucket()
    }
  }, [managers, heroBucket])
  return (
    <div className="area-bg-hero-bucket-details pt-60">
      {/* latest-games-area */}
      <section className="latest-games-area pt-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <div className="section-title title-style-two text-center mb-20">
                <span>HIRING NEW HERO</span>
                <h2>Hero<span> Campaign:</span>{heroChest ? heroChest.id : ''}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-8">
                  <div className="section-title text-center">
                    <span>Price: 0 {wallet?.symbol}</span>
                    <a onClick={() => mintHero()} className="btn btn-info">MINT HERO</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <section className="latest-games-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <div className="section-title title-style-two text-center pt-20 mb-20">
                <h2>Hero <span> Pool</span></h2>
                <p>Scroll to see more details</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center">
                {heroList.length > 0 ? (
                  <>
                    {heroList.map((hero, index) => {
                      return (
                        <div className="col-lg-3 col-6">
                          <Fade up>
                            <PoolHeroCard hero={hero} index={index} handleClick={console.log} dropChance={chainHelper.calculateHeroDropChance(heroChest, index) + '%'} />
                          </Fade>
                        </div>
                      )
                    })}
                  </>
                ) : ''}
              </div>
            </div>
          </div>
        </div>
      </section >
    </div>
  )
}

export default HeroList;
