
import React, { useState, useEffect } from 'react';
import { statImg } from '../../store/image-service';
import EquipmentCard from '../equipment/EquipmentCard';
import ClipboardCopy from './ClipboradCopy';
import { store } from '../../store'
// Chain Services
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import { getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData'
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import characterClasses from '../../chain/data/characterClasses.json'
import race from '../../chain/enumarations/race.json'
import stats from '../../chain/enumarations/stats.json'


function HeroStats({ hero, isShareable, chainId }) {
    const primaryStats = [9, 10, 11, 0, 1, 2, 5, 6, 7, 8]
    const secondaryStats = [3, 4, 15, 14, 17]
    const miscellaneousStats = [12, 13, 16, 18]
    const [isStat1, setIsStat1] = useState(true)
    const [isStat2, setIsStat2] = useState(false)
    const [isStat3, setIsStat3] = useState(false)
    const [isCharacter, setIsCharacter] = useState(false)
    const [monsterStats, setMonsterStats] = useState(primaryStats)
    const [heroEquipments, setHeroEquipments] = useState([])

    const selectPrimaryStats = () => {
        setIsStat1(true)
        setIsStat2(false)
        setIsStat3(false)
        setIsCharacter(false)
        setMonsterStats(primaryStats)
    }

    const selectStat2 = () => {
        setIsStat1(false)
        setIsStat2(true)
        setIsStat3(false)
        setIsCharacter(false)
        setMonsterStats(secondaryStats)
    }

    const selectStat3 = () => {
        setIsStat1(false)
        setIsStat2(false)
        setIsStat3(true)
        setIsCharacter(false)
        setMonsterStats(miscellaneousStats)
    }

    const selectCharacter = () => {
        setIsStat1(false)
        setIsStat2(false)
        setIsStat3(false)
        setIsCharacter(true)
    }

    const getEquipmentOfHero = async () => {
        setHeroEquipments([])
        const arr = [0, 1, 2, 3, 4, 5]
        arr.map(async (slotId) => {
            const equipmentId = await equipmentManager.getEquipmentOfHero(
                hero.id,
                slotId
            )
            if (equipmentId > 0) {
                const equipment = await equipmentManager.getEquipment(equipmentId)
                setHeroEquipments((prev) => [...prev, { slotId, equipment }])
            }
        })
    }

    const getEquipmentOfHeroFallback = async () => {
        setHeroEquipments([])
        const arr = [0, 1, 2, 3, 4, 5]
        arr.map(async (slotId) => {
            const equipmentId = await equipmentManager.getEquipmentOfHeroFallback(
                hero.id,
                slotId,
                chainId
            )
            if (equipmentId > 0) {
                const equipment = await equipmentManager.getEquipmentFallback(equipmentId, chainId)
                setHeroEquipments((prev) => [...prev, { slotId, equipment }])
            }
        })
    }

    useEffect(() => {
        if (chainId) {
            getEquipmentOfHeroFallback()
        } else {
            getEquipmentOfHero()
        }
    }, [])
    return (<>
        <div className="row justify-content-center">
            <div className="features-head ">
                <h4 style={getRarityTextColor(hero)}>{hero?.name}</h4>
                <p>Race: <span style={getRarityTextColor(hero)}>{race[hero.race]}</span></p>
                <p>Class: <span style={getRarityTextColor(hero)}>{characterClasses[hero.characterClass]?.name}</span></p>
            </div>
        </div >
        <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 order-2 order-lg-2">
                <div className="features-item" style={getRarityColor(hero)}>
                    <ul class="nav nav-tabs">
                        <div className="col-lg-6 col-12">
                            <li class="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectPrimaryStats()} className={"nav-link " + (isStat1 ? "active" : '')}><h4>Primary</h4></a>
                            </li>
                        </div>
                        <div className="col-lg-6 col-12">
                            <li className="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat2()} className={"nav-link " + (isStat2 ? "active" : '')}><h4>Secondary</h4></a>
                            </li>
                        </div>
                        <div className="col-12">
                            <li className="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat3()} className={"nav-link " + (isStat3 ? "active" : '')}><h4>Miscellaneous</h4></a>
                            </li>
                        </div>
                        <div className="col-12">
                            <li className="nav-item">
                                <a style={{ color: '#ffc107', cursor: 'pointer' }} onClick={() => selectCharacter()} className={"nav-link " + (isCharacter ? "active" : '')}><h4 style={{ color: '#ffc107' }}>Equipments</h4></a>
                            </li>
                        </div>
                    </ul>

                    <div className="features-list-wrap" >
                        <ul>
                            {isCharacter ? (
                                <>
                                    {heroEquipments?.map((heroEquipment, index) => {
                                        return (<>
                                            <div className='row pt-30'>
                                                <div className="col-2"></div>
                                                <div className="col-8">
                                                    <EquipmentCard equipment={heroEquipment.equipment} index={index} handleClick={console.log} />
                                                </div>
                                                <div className="col-2"></div>
                                            </div>
                                        </>
                                        )
                                    })}
                                </>
                            ) :
                                (
                                    <>{monsterStats?.map((stat, index) => {
                                        if (index % 2 == 1) {
                                            return
                                        }
                                        return (
                                            <li>
                                                <div className='col-lg-12'>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-12'>
                                                            <div className="features-list-content">
                                                                <div className="stat-item">
                                                                    <div className="fact-icon">
                                                                        <img src={statImg(stats[stat])} alt="" />
                                                                    </div>
                                                                    <div className="counter-item">
                                                                        <h5 className="stat-value"><span>{chainHelper.calculateStatValue(hero?.stats?.[stat])}</span></h5>
                                                                        <h6>{stats[stat].slice(0, 7)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-12'>
                                                            {hero?.stats?.[monsterStats[index + 1]] ? (
                                                                <div className="features-list-content">
                                                                    <div className="stat-item">
                                                                        <div className="fact-icon">
                                                                            <img src={statImg(stats[monsterStats[index + 1]])} alt={stats[monsterStats[index + 1]]} />
                                                                        </div>
                                                                        <div className="counter-item">
                                                                            <h5 className="stat-value"><span>{chainHelper.calculateStatValue(hero?.stats?.[monsterStats[index + 1]])}</span></h5>
                                                                            <h6>{stats[monsterStats[index + 1]].slice(0, 7)}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}</>)
                            }
                        </ul>
                    </div>
                </div>
                {isShareable ? (<div className='col-12 pt-20'>
                    <ClipboardCopy copyText={chainId ? "https://guildofchains.com/hero?chainId=" + chainId + "&id=" + hero.id : "https://guildofchains.com/hero?chainId=" + store.getState().wallet.wallet.chainId + "&id=" + hero.id} />
                </div>) : ''}
            </div>
        </div>
    </>)
}


export default HeroStats