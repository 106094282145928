import { store } from '../../store'
import { chainPicker } from '../helpers/chainInfo'
import Web3 from 'web3'

// ABIS
import equipmentCollectionABI from '../../chain/abis/abi_EquipmentCollection.json'
import multicallABI from '../../chain/abis/abi_Multicall.json'

export const balanceOf = async (address) => {
  const equipmentCollectionAddress = await store.getState().collections.collections.equipmentCollectionAddress
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  return await equipmentCollectionContract.methods.balanceOf(address).call()
}

export const ownerOf = async (tokenId) => {
  const equipmentCollectionAddress = await store.getState().collections.collections.equipmentCollectionAddress
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  return await equipmentCollectionContract.methods.ownerOf(tokenId).call()
}

export const tokenOfOwnerByIndex = async (address, i) => {
  const equipmentCollectionAddress = await store.getState().collections.collections.equipmentCollectionAddress
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  return await equipmentCollectionContract.methods.tokenOfOwnerByIndex(address, i).call()
}

export const tokenURI = async (tokenId) => {
  const equipmentCollectionAddress = await store.getState().collections.collections.equipmentCollectionAddress
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  return await equipmentCollectionContract.methods.tokenURI(tokenId).call()
}

export const isApprovedForAll = async (address) => {
  const equipmentMasterAddress = await store.getState().masters.masters.equipmentMasterAddress
  const equipmentCollectionAddress = await store.getState().collections.collections.equipmentCollectionAddress
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  return await equipmentCollectionContract.methods.isApprovedForAll(address, equipmentMasterAddress).call()
}

export const setApprovalForAll = (equipmentCollectionAddress, address, txGas) => {
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  return equipmentCollectionContract.methods.setApprovalForAll(address, true).send({ from: store.getState().wallet.wallet.address, gas: txGas })
}

export const estimateGasSetApprovalForAll = async () => {
  const { gasMultiplier } = chainPicker(store.getState().wallet.wallet.chainId)
  const equipmentCollectionAddress = await store.getState().collections.collections.equipmentCollectionAddress
  const equipmentMasterAddress = await store.getState().masters.masters.equipmentMasterAddress
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  return ((await equipmentCollectionContract.methods.setApprovalForAll(equipmentMasterAddress, true).estimateGas({ from: store.getState().wallet.wallet.address })) * gasMultiplier).toFixed()
}

export const tokensOfOwner = async (address) => {
  const {multicallAddress} = chainPicker(store.getState().wallet.wallet.chainId)
  const equipmentCollectionAddress = await store.getState().collections.collections.equipmentCollectionAddress
  const balance = await balanceOf(address);
  const web3 = new Web3(window.ethereum)
  const equipmentCollectionContract = new web3.eth.Contract(
    equipmentCollectionABI,
    equipmentCollectionAddress
  )
  const multicallContract = new web3.eth.Contract(
    multicallABI,
    multicallAddress
  )

  const inputs = []

  for (let i = 0; i < balance; i++) {
    inputs.push({target:equipmentCollectionAddress, callData:equipmentCollectionContract.methods.tokenOfOwnerByIndex(address, i).encodeABI()})
  }

  return await multicallContract.methods.aggregate(inputs).call()
}