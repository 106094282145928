import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Home from './pages/homes/Home';
import HomeTwo from './pages/homes/HomeTwo';
import HomeOne from './pages/homes/HomeOne';
import HomeThree from './pages/homes/HomeThree';
import Shop from './pages/store/Shop';
import Contact from './pages/contact/Contact';
import Community from './pages/community/Community';
import Overview from './pages/overview/Overview';
import AboutUs from './pages/aboutpage/aboutuspage/AboutUs';
import Upcoming from './pages/aboutpage/upcoming/Upcoming';
import GameSingle from './pages/aboutpage/singlegame/GameSingle';
import Blogs from './pages/blogs/Blogs';
import BlogDetails from './pages/blogdetails/BlogDetails'
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import HomeFour from './pages/homes/HomeFour';
import HomeFive from './pages/homes/HomeFive';
import HomeSix from './pages/homes/HomeSix';
import HomeSeven from './pages/homes/HomeSeven';
import Zones from './pages/zones/Zones';
import Zone1 from './pages/zone1/Zone1';
import SelectHero from './pages/selecthero/SelectHero'
import SelectHeroBucket from './pages/selectherobucket/SelectHeroBucket';
import Hideout from './pages/hideout/Hideout';
import HeroBucketDetails from './pages/herobucketdetails/HeroBucketDetails';
import NewHeroDetails from './pages/newherodetails/NewHeroDetails';
import TransactionHistory from './pages/transactionhistory/TransactionHistory';
import EncounterResult from './pages/encounterresult/EncounterResult';
import PremiumStore from './pages/premiumstore/PremiumStore';
import SelectCraftingBucket from './pages/selectcraftingbucket/SelectCraftingBucket';
import CraftingBucketDetails from './pages/craftingbucketdetails/CraftingBucketDetails';
import NewEquipmentDetails from './pages/newequipmentdetails/NewEquipmentDetails';
import Inventory from './pages/inventory/Inventory';
import UpgradeEquipment from './pages/upgradeequipment/UpgradeEquipment';
import EquipmentDetailPage from './pages/pagefromid/EquipmentDetailPage';
import HeroDetailPage from './pages/pagefromid/HeroDetailPage';


function App() {
  return (

    <div className="App">

      <Router>
        <Switch>
          {/* <ScrollToTopRoute exact={true} path='/home-2'>
            <HomeTwo />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-2'>
            <HomeOne />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-3'>
            <HomeThree />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/shop'>
            <Shop />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/contact'>
            <Contact />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/community'>
            <Community />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/overview'>
            <Overview />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path='/about-us'>
            <AboutUs />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/upcoming-games'>
            <Upcoming />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/game-single'>
            <GameSingle />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path='/blogs'>
            <Blogs />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path='/blog-details'>
            <BlogDetails />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-4'>
            <HomeFour />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-5'>
            <HomeFive />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-6'>
            <HomeSix />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-7'>
            <HomeSeven />
          </ScrollToTopRoute> */}

          {/* GoC Pages */}
          <ScrollToTopRoute exact={true} path='/'>
            <Home />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/zones'>
            <Zones />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/emerald-canopy'>
            <Zone1 />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/select-hero'>
            <SelectHero />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/select-hero-bucket'>
            <SelectHeroBucket />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/hero-bucket-details'>
            <HeroBucketDetails />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/new-hero-details'>
            <NewHeroDetails />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/transaction-history'>
            <TransactionHistory />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/encounter-result'>
            <EncounterResult />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/premium-store'>
            <PremiumStore />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/select-crafting-bucket'>
            <SelectCraftingBucket />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/crafting-bucket-details'>
            <CraftingBucketDetails />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/new-equipment-details'>
            <NewEquipmentDetails />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/inventory'>
            <Inventory />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/upgrade-equipment'>
            <UpgradeEquipment />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/equipment'>
            <EquipmentDetailPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/hero'>
            <HeroDetailPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/aetherforge-isle'>
            <Hideout />
          </ScrollToTopRoute>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
