import React from 'react'
import Index from '../../components/index/index/Index'

function Home() {

    return (
        <Index />
    )
}

export default Home
