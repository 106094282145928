import React, { useState, useEffect } from 'react';

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className='row'>
      <div className='col-12 col-lg-12'>
        <div className='row'>
          <input style={{ backgroundColor: '#1a1b20', color: '#fff', width: '100%' }} type="text" value={copyText} readOnly />
        </div>
        {/* Bind our handler function to the onClick button property */}
        <div className='row'>
          <div className='col-12 col-lg-12 pt-10'>
            <button className='btn' onClick={handleCopyClick}>
              <span>{isCopied ? 'Copied!' : 'Copy'}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClipboardCopy