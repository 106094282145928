
import React, { useState, useEffect } from 'react';
import { materialImg, monsterImg, statImg } from '../../store/image-service';
import EquipmentCard from '../equipment/EquipmentCard';
import ClipboardCopy from './ClipboradCopy';
import { store } from '../../store'
// Chain Services
import * as equipmentCollection from '../../chain/chain-service/equipmentCollection'
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import * as gameMaster from '../../chain/chain-service/gameMaster'
import { getRarityBorderColor, getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData'
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import characterClasses from '../../chain/data/characterClasses.json'
import race from '../../chain/enumarations/race.json'
import stats from '../../chain/enumarations/stats.json'
import rarity from '../../chain/enumarations/rarity.json'


function PoolHeroStats({ hero, isShareable, chainId }) {
    const characterClass = characterClasses[hero?.bcData?.characterClass]
    const heroStats = characterClass?.bcData?.baseStats
    const primaryStats = [9, 10, 11, 0, 1, 2, 5, 6, 7, 8]
    const secondaryStats = [3, 4, 15, 14, 17]
    const miscellaneousStats = [12, 13, 16, 18]
    const [isStat1, setIsStat1] = useState(true)
    const [isStat2, setIsStat2] = useState(false)
    const [isStat3, setIsStat3] = useState(false)
    const [monsterStats, setMonsterStats] = useState(primaryStats)

    const selectPrimaryStats = () => {
        setIsStat1(true)
        setIsStat2(false)
        setIsStat3(false)
        setMonsterStats(primaryStats)
    }

    const selectStat2 = () => {
        setIsStat1(false)
        setIsStat2(true)
        setIsStat3(false)
        setMonsterStats(secondaryStats)
    }

    const selectStat3 = () => {
        setIsStat1(false)
        setIsStat2(false)
        setIsStat3(true)
        setMonsterStats(miscellaneousStats)
    }
    return (<>
        <div className="row justify-content-center">
            <div className="features-head ">
                <h4 style={getRarityTextColor(hero?.bcData)}>{hero?.name}</h4>
                <p>Race: <span style={getRarityTextColor(hero?.bcData)}>{race[hero?.bcData?.race]}</span></p>
                <p>Class: <span style={getRarityTextColor(hero?.bcData)}>{characterClasses[hero?.bcData?.characterClass]?.name}</span></p>
            </div>

        </div >
        <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 order-2 order-lg-2">
                <div className="features-item" style={getRarityColor(hero?.bcData)}>
                    <ul class="nav nav-tabs">
                        <div className="col-lg-6 col-12">
                            <li class="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectPrimaryStats()} className={"nav-link " + (isStat1 ? "active" : '')}><h4>Primary</h4></a>
                            </li>
                        </div>
                        <div className="col-lg-6 col-12">
                            <li className="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat2()} className={"nav-link " + (isStat2 ? "active" : '')}><h4>Secondary</h4></a>
                            </li>
                        </div>
                        <div className="col-12">
                            <li className="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat3()} className={"nav-link " + (isStat3 ? "active" : '')}><h4>Miscellaneous</h4></a>
                            </li>
                        </div>
                    </ul>

                    <div className="features-list-wrap" >
                        <ul>
                            {
                                (
                                    <>{monsterStats?.map((stat, index) => {
                                        if (index % 2 == 1) {
                                            return
                                        }
                                        return (
                                            <li>
                                                <div className='col-lg-12'>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-12'>
                                                            <div className="features-list-content">
                                                                <div className="stat-item">
                                                                    <div className="fact-icon">
                                                                        <img src={statImg(stats[stat])} alt="" />
                                                                    </div>
                                                                    <div className="counter-item">
                                                                        <h5 className="stat-value"><span>{chainHelper.calculateStatValue(heroStats?.[stat]) || 0}</span></h5>
                                                                        <h6>{stats[stat].slice(0, 7)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-12'>
                                                            {stats?.[monsterStats[index + 1]] ? (
                                                                <div className="features-list-content">
                                                                    <div className="stat-item">
                                                                        <div className="fact-icon">
                                                                            <img src={statImg(stats[monsterStats[index + 1]])} alt={stats[monsterStats[index + 1]]} />
                                                                        </div>
                                                                        <div className="counter-item">
                                                                            <h5 className="stat-value"><span>{chainHelper.calculateStatValue(heroStats?.[monsterStats[index + 1]]) || 0}</span></h5>
                                                                            <h6>{stats[monsterStats[index + 1]].slice(0, 7)}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}</>)
                            }
                        </ul>
                    </div>
                </div>
                {isShareable ? (<div className='col-12 pt-20'>
                    <ClipboardCopy copyText={chainId ? "https://guildofchains.com/hero?chainId=" + chainId + "&id=" + hero.id : "https://guildofchains.com/hero?chainId=" + store.getState().wallet.wallet.chainId + "&id=" + hero.id} />
                </div>) : ''}
            </div>
        </div>
    </>)
}


export default PoolHeroStats