export const equipmentImg = (equipmentId) => {
  return `assets/img/goc/equipments/${equipmentId}.png`
}

export const equipmentSubtypeImg = (subtypeId) => {
  return `assets/img/goc/subtypes/${subtypeId}.png`
}

export const heroImg = (heroId) => {
  if (heroId) {
    return `assets/img/goc/heroes/${parseInt(heroId % 12 + 1)}.webp`
  } else {
    return `assets/img/goc/heroes/1.webp`
  }
}

export const monsterImg = (monsterId) => {
  return `assets/img/goc/monsters/${monsterId}.png`
}

export const skillImg = (skillId) => {
  return `assets/img/goc/skills/${skillId}.png`
}

export const slotImg = (slotId, rarity) => {
  return `assets/img/goc/itemraritylogo/${slotId}_${rarity}.webp`
}

export const classImg = (className) => {
  return `assets/img/goc/class/${className}.png`
}

export const materialImg = (materialId) => {
  return `assets/img/goc/materials/${materialId}.png`
}

export const statImg = (statId) => {
  return `assets/img/goc/stats/${statId}.webp`
}

export const crafterImg = (data) => {
  if (data.includes('Blacksmith')) {
    return `assets/img/goc/crafters/Blacksmith.png`
  }
  if (data.includes('Armorsmith')) {
    return `assets/img/goc/crafters/Armorsmith.png`
  }
  if (data.includes('Tailor')) {
    return `assets/img/goc/crafters/Tailor.png`
  }
  if (data.includes('Woodcarver')) {
    return `assets/img/goc/crafters/Woodcarver.png`
  }
  if (data.includes('Jeweler')) {
    return `assets/img/goc/crafters/Jeweler.png`
  }
}

export const zoneBackgroundImg = (data) => {
  if (data.includes('Emerald Canopy')) {
    return { backgroundImage: 'url(assets/img/goc/zones-bg/zone-1.webp)' }
  }
  if (data.includes('Crystal Cavern')) {
    return { backgroundImage: 'url(assets/img/goc/zones-bg/zone-2.webp)' }
  }
  if (data.includes('Crimson Torrent')) {
    return { backgroundImage: 'url(assets/img/goc/zones-bg/zone-3.webp)' }
  }
}