import { store } from '../../store'
import { chainPicker } from '../helpers/chainInfo'
import Web3 from 'web3'

// ABIS
import heroManagerABI from '../../chain/abis/abi_HeroManager.json'
import multicallABI from '../../chain/abis/abi_Multicall.json'
import gameMasterABI from "../../chain/abis/abi_GameMaster.json";
// JSON Data
import heroes from '../../chain/data/poolHeroes.json'

const heroStruct = heroManagerABI.find(o => o.name === 'getHero').outputs;
const poolHeroStruct = heroManagerABI.find(o => o.name === 'getHeroFromPool').outputs;

export const getHero = async (id) => {
  const heroManagerAddress = await store.getState().managers.managers.heroManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroManagerContract = new web3.eth.Contract(
    heroManagerABI,
    heroManagerAddress
  )
  let hero = Object.assign({selected: false}, await heroManagerContract.methods.getHero(id).call());
  hero.name = heroes[hero.poolId].name
  hero.description = heroes[hero.poolId].description
  return hero
}

export const isValidSkills = async (heroId, skills) => {
  const heroManagerAddress = await store.getState().managers.managers.heroManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroManagerContract = new web3.eth.Contract(
    heroManagerABI,
    heroManagerAddress
  )
  return await heroManagerContract.methods.isValidSkills(heroId, skills).call()
}

export const getHeroFallback = async (id, chainId) => {
  const { gameMasterAddress, fallbackRPC } = chainPicker(chainId)
  const web3 = new Web3(new Web3.providers.HttpProvider(fallbackRPC))
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  );
  const heroManagerAddress = await gameMasterContract.methods.heroManager().call();
  const heroManagerContract = new web3.eth.Contract(
    heroManagerABI,
    heroManagerAddress
  )
  return await heroManagerContract.methods.getHero(id).call()
}

export const getHeroFromPool = async (id) => {
  const heroManagerAddress = await store.getState().managers.managers.heroManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroManagerContract = new web3.eth.Contract(
    heroManagerABI,
    heroManagerAddress
  )
  return await heroManagerContract.methods.getHeroFromPool(id).call()
}


export const tokenOfOwnerByIndex = async (address, i) => {
  const heroManagerAddress = await store.getState().managers.managers.heroManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroManagerContract = new web3.eth.Contract(
    heroManagerABI,
    heroManagerAddress
  )
  return await heroManagerContract.methods.tokenOfOwnerByIndex(address, i).call()
}

export const getHeroes = async (ids) => {
  const { multicallAddress } = chainPicker(store.getState().wallet.wallet.chainId)
  const heroManagerAddress = await store.getState().managers.managers.heroManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroManagerContract = new web3.eth.Contract(
    heroManagerABI,
    heroManagerAddress
  )
  const multicallContract = new web3.eth.Contract(
    multicallABI,
    multicallAddress
  )

  const inputs = []

  for (let i = 0; i < ids.length; i++) {
    inputs.push({ target: heroManagerAddress, callData: heroManagerContract.methods.getHero(ids[i]).encodeABI() })
  }
  const result = await multicallContract.methods.aggregate(inputs).call()

  const heroes = []
  for (let i = 0; i < result.returnData.length; i++) {
    const hero = web3.eth.abi.decodeParameters(heroStruct, result.returnData[i])
    heroes.push(hero[0])
  }

  return heroes
}


export const getHeroesFromPool = async (ids) => {
  const { multicallAddress } = chainPicker(store.getState().wallet.wallet.chainId)
  const heroManagerAddress = await store.getState().managers.managers.heroManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroManagerContract = new web3.eth.Contract(
    heroManagerABI,
    heroManagerAddress
  )
  const multicallContract = new web3.eth.Contract(
    multicallABI,
    multicallAddress
  )

  const inputs = []

  for (let i = 0; i < ids.length; i++) {
    inputs.push({ target: heroManagerAddress, callData: heroManagerContract.methods.getHeroFromPool(ids[i]).encodeABI() })
  }

  const result = await multicallContract.methods.aggregate(inputs).call()

  const heroes = []
  for (let i = 0; i < result.returnData.length; i++) {
    const hero = web3.eth.abi.decodeParameters(poolHeroStruct, result.returnData[i])
    heroes.push(hero[0])
  }

  return heroes
}