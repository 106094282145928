import * as chainHelper from '../chain-service/helper'
Object.defineProperty(Object.prototype, "getProp", {
    value: function (prop) {
        var key, self = this;
        for (key in self) {
            if (key.toLowerCase() == prop.toLowerCase()) {
                return self[key];
            }
        }
    },
    //this keeps jquery happy
    enumerable: false
});

export const getHealtPoints = (stats) => {
    const hp = stats?.find(o => parseInt(o.getProp('statId')) === 5)
    return chainHelper.calculateStatValue(hp) ? chainHelper.calculateStatValue(hp) : chainHelper.calculateStatValueArray(hp)
}

export const getStamina = (stats) => {
    const stamina = stats?.find(o => parseInt(o.getProp('statId')) === 12)
    return chainHelper.calculateStatValue(stamina) ? chainHelper.calculateStatValue(stamina) : chainHelper.calculateStatValueArray(stamina)
}