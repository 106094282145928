import React from 'react';
import CountUp from "react-countup";
// Chain Services
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import stats from '../../chain/enumarations/stats.json'

export default function EquipmentStats(data) {
  return (
    <div className="fact-area">
      <h2>Equipment Stats:</h2>
      <div className="row justify-content-center">
        {data?.equipment?.flatModifiers?.map((stat, index) => (
          <>
            {chainHelper.calculateStatValueArray(stat) || chainHelper.calculateStatValueArray(data?.equipment?.percentageModifiers[index]) > 0 ? (
              <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                <div className="fact-item">
                  <div className="fact-icon">
                    <img src="assets/img/icon/fact_icon01.webp" alt="" />
                  </div>
                  <div className="counter-item">
                    {chainHelper.calculateStatValueArray(stat) > 0 ? (
                      <h2 style={{ color: 'green' }} className="counter">+<span>< CountUp end={chainHelper.calculateStatValueArray(stat)} /></span></h2>
                    ) : ''}
                    {chainHelper.calculateStatValueArray(data?.equipment?.percentageModifiers[index]) > 0 ? (
                      <h2 style={{ color: 'green' }} className="counter">+<span>< CountUp end={chainHelper.calculateStatValueArray(data?.equipment?.percentageModifiers[index])} />%</span></h2>
                    ) : ''}
                    <span>{stats[stat.statId]}</span>
                  </div>
                </div>
              </div>
            ) : ''
            }
          </>
        ))}
      </div>
    </div>
  )
}
