import React from 'react';
import { useSelector } from 'react-redux'
import { mastersSelector } from '../../store/selectors'
function Footer() {

  const { masters } = useSelector(mastersSelector)

  return (
    <footer>
      <div className="footer-top footer-bg">
        {/* newsletter-area */}
        <div className="newsletter-area">
          <div className="container mb-50">
            <div className="row">
            </div>
          </div>
        </div>
        {/* newsletter-area-end */}
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget mb-50">
                <div className="footer-logo mb-35">
                  <h2>Guild of Chains</h2>
                </div>
                <div className="footer-text">
                  <p>Players for players</p>
                  <p>GameMaster: {masters?.gameMasterAddress}</p>
                  <div className="footer-contact">
                    <ul>
                      <li><i className="fas fa-envelope-open" /><span>Email : </span>play@guildofchains.com</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6">
              <div className="footer-widget mb-50">
                {/* <div className="fw-title mb-35">
                    <h5>Products</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="/#">Graphics (26)</a></li>
                      <li><a  href="/#">Backgrounds (11)</a></li>
                      <li><a  href="/#">Fonts (9)</a></li>
                      <li><a  href="/#">Music (3)</a></li>
                      <li><a  href="/#">Photography (3)</a></li>
                    </ul>
                  </div> */}
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6">
              <div className="footer-widget mb-50">
                <div className="fw-title mb-35">
                  <h5>Need Help?</h5>
                </div>
                <div className="fw-link">
                  <ul>
                    <li><a target='_blank' href="https://docs.guildofchains.com">How to play?</a></li>
                    <li><a target='_blank' href="https://docs.guildofchains.com/faq/faq-and-troubleshooting">Troubleshooting</a></li>
                    <li><a target='_blank' href="https://docs.guildofchains.com/faq/faq-and-troubleshooting">FAQ</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget mb-50">
                <div className="fw-title mb-35">
                  <h5>Follow us</h5>
                </div>
                <div className="footer-social">
                  <ul>
                    <li><a target='_blank' href="https://discord.gg/atycQemZ8g"><i className="fab fa-discord" /></a></li>
                    <li><a target='_blank' href="https://twitter.com/guildofchains"><i className="fab fa-twitter" /></a></li>
                    <li><a target='_blank' href="https://medium.com/@guildofchains"><i className="fab fa-medium" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footer-fire"><img src="assets/img/images/footer_fire.webp" alt="" /></div>
          <div className="footer-fire footer-fire-right"><img src="assets/img/images/footer_fire.webp" alt="" /></div> */}
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="copyright-text">
                <p>Copyright © 2023 <a href="https://guildofchains.com">GoC</a> All Rights Reserved.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-none d-md-block">
              <div className="payment-method-img text-right">

              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
