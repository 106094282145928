import Web3 from 'web3'
import { store } from '../../store'
import { chainPicker } from '../helpers/chainInfo'

// ABIs
import TransferBatchABI from '../abis/events/TransferBatch.json'
import CombatResultsABI from '../abis/events/combatResult.json'
import RoundResultABI from '../abis/events/roundResult.json'
import ItemCraftedABI from '../abis/events/ItemCrafted.json'
import HeroHiredABI from '../abis/events/HeroHired.json'
import { getHealtPoints } from '../helpers/parseStats'
const int64Divider = 1000000

export const calculateStatValue = (stat) => {
  if (stat) {
    const result = ((parseInt(stat.getProp('baseValue')) + parseInt(stat.getProp('flatBonus'))) * (int64Divider + parseInt(stat.getProp('percentageBonus')) / 100) / int64Divider) / int64Divider
    if (!isNaN(result)) {
      return result
    } else {
      return ((parseInt(stat[1]) + parseInt(stat[2])) * (int64Divider + parseInt(stat[3]) / 100) / int64Divider) / int64Divider
    }
  }
}

export const calculateStatValueArray = (stat) => {
  if (stat)
    return ((parseInt(stat[1]) + parseInt(stat[2])) * (int64Divider + parseInt(stat[3]) / 100) / int64Divider) / int64Divider
}

export const calculateValue = (value) => {
  if (value)
    return parseInt((parseInt(value) / int64Divider).toFixed())
}

export const parseCombatResult = (event) => {
  if (event.length > 0) {
    const web3 = new Web3(window.ethereum)
    return web3.eth.abi.decodeLog(CombatResultsABI,
      event[0].raw.data,
      [event[0].raw.topics[1], event[0].raw.topics[2], event[0].raw.topics[3]])
  }
  else {
    return null
  }
}

export const parseTransferBatch = (event) => {
  if (event.length > 0) {
    const web3 = new Web3(window.ethereum)
    return web3.eth.abi.decodeLog(TransferBatchABI,
      event[0].raw.data,
      [event[0].raw.topics[1], event[0].raw.topics[2], event[0].raw.topics[3]])
  }
  else {
    return null
  }
}

export const parseItemCrafted = (event) => {
  if (event.length > 0) {
    const web3 = new Web3(window.ethereum)
    return web3.eth.abi.decodeLog(ItemCraftedABI,
      event[0].raw.data,
      [event[0].raw.topics[1], event[0].raw.topics[2], event[0].raw.topics[3]])
  }
  else {
    return null
  }
}

export const parseHeroHired = (event) => {
  if (event.length > 0) {
    const web3 = new Web3(window.ethereum)
    return web3.eth.abi.decodeLog(HeroHiredABI,
      event[0].raw.data,
      [event[0].raw.topics[1], event[0].raw.topics[2], event[0].raw.topics[3]])
  }
  else {
    return null
  }
}

export const parseRoundResults = (events, encounter, hero) => {

  let monsterCurrentHp = getHealtPoints(encounter?.monster?.bcData?.stats)
  let heroCurrentHp = calculateStatValueArray(hero?.stats?.[5])

  let monsterDamageReceived = 0
  let heroDamageReceived = 0
  if (events.length > 0) {
    let monsterResults = []
    let heroResults = []
    events.forEach((event, index) => {
      const web3 = new Web3(window.ethereum)
      const tempRound = web3.eth.abi.decodeLog(RoundResultABI,
        event.raw.data,
        [event.raw.topics[1], event.raw.topics[2], event.raw.topics[3]])
      monsterDamageReceived = monsterCurrentHp - calculateValue(tempRound._monsterHp)
      heroDamageReceived = heroCurrentHp - calculateValue(tempRound._heroHp)
      heroCurrentHp = Math.max(calculateValue(tempRound._heroHp), 0)
      monsterCurrentHp = Math.max(calculateValue(tempRound._monsterHp), 0)
      monsterResults.push({ roundOrder: index, monsterHp: monsterCurrentHp, monsterDamageReceived })
      heroResults.push({ roundOrder: index, heroHp: heroCurrentHp, heroDamageReceived })

    })
    return { monsterResults, heroResults }
  }
  else {
    return null
  }
}

export const getTxEvents = (events) => {
  const combatResultEvent = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0x64babf57060d859f054cd3270d9f6162d266dde8b972b6300d540a764a41c75e';
  })

  const transferBatch = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0x4a39dc06d4c0dbc64b70af90fd698a233a518aa5d07e595d983b8c0526c8f7fb';
  })

  const roundResult = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0x02e8fd1652ac9240f343712f18048d86cc6773d3f1280276de2f22f99e713e1e';
  })

  return { combatResultEvent, transferBatch, roundResult }
}


export const getCraftingResultTxEvents = (events) => {
  const itemCrafted = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0xe9f711606ecb80b189e7a3859f76bdeb29302a7ced6f4be7ce51ecba42dfb4ee';
  })

  const transferBatch = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0x4a39dc06d4c0dbc64b70af90fd698a233a518aa5d07e595d983b8c0526c8f7fb';
  })

  const transfer = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef';
  })

  return { itemCrafted, transferBatch, transfer }
}

export const getHeroMintTxEvents = (events) => {
  const heroHired = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0x88669de2dc5456a6a179d826128724d71b75adcd7cad2d0e721d24673f1ba885';
  })

  const transfer = Object.values(events).filter((event) => {
    return event.raw.topics[0] == '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef';
  })

  return { heroHired, transfer }
}


export const calculateStaminaValue = (selectedHero) => {
  const { staminaPerSecond } = chainPicker(store.getState().wallet.wallet.chainId)
  const staminaToRegen = parseInt((((Date.now() / 1000).toFixed() - parseInt(selectedHero.staminaUpdateTimestamp)) * staminaPerSecond).toFixed())
  const staminaLastvalue = calculateValue(selectedHero.staminaLastValue)
  const maxStamina = calculateStatValueArray(selectedHero.stats[12])

  return Math.min((parseInt(staminaLastvalue) + parseInt(staminaToRegen)), maxStamina)
}

export const percentage = (partialValue, totalValue) => {
  if (partialValue === 0 && totalValue === 0) { return 0 }
  return ((100 * partialValue) / totalValue).toFixed(2);
}

export const calculateHeroDropChance = (heroBucket, index) => {
  const heroes = heroBucket.bcData.getProp('heroes')
  const totalChance = calculateHeroTotalChance(heroes)
  const heroChance = heroes[index].getProp('chance')
  return percentage(heroChance, totalChance);
}

export const calculateEquipmentDropChance = (equipmentBucket, index) => {
  const equipments = equipmentBucket?.bcData?.getProp('craftableEquipments')
  const totalChance = calculateTotalDropChance(equipments)
  const craftChance = equipments[index].getProp('craftchance')
  return percentage(craftChance, totalChance);
}

export const calculateMaterialDropChance = (encounter, index) => {
  const materialrewards = encounter?.details?.bcData?.getProp('materialRewards')
  const totalChance = calculateMaterialTotalChance(materialrewards)
  const dropChance = materialrewards[index].getProp('chance')
  return percentage(dropChance, totalChance);
}

export const calculateHeroTotalChance = (array) => {
  let total = 0
  array.map((item, index) => {
    total += parseInt(item.getProp('chance'))
  })
  return total
}

export const calculateTotalDropChance = (array) => {
  let total = 0
  array.map((item, index) => {
    total += parseInt(item.getProp('craftchance'))
  })
  return total
}

export const calculateMaterialTotalChance = (array) => {
  let total = 0
  array.map((item, index) => {
    total += parseInt(item.getProp('chance'))
  })
  return total
}

export const sortBy = (function () {
  var toString = Object.prototype.toString,
    // default parser function
    parse = function (x) { return x; },
    // gets the item to be sorted
    getItem = function (x) {
      var isObject = x != null && typeof x === "object";
      var isProp = isObject && this.prop in x;
      return this.parser(isProp ? x[this.prop] : x);
    };

  /**
   * Sorts an array of elements.
   *
   * @param  {Array} array: the collection to sort
   * @param  {Object} cfg: the configuration options
   * @property {String}   cfg.prop: property name (if it is an Array of objects)
   * @property {Boolean}  cfg.desc: determines whether the sort is descending
   * @property {Function} cfg.parser: function to parse the items to expected type
   * @return {Array}
   */
  return function sortby(array, cfg) {
    if (!(array instanceof Array && array.length)) return [];
    if (toString.call(cfg) !== "[object Object]") cfg = {};
    if (typeof cfg.parser !== "function") cfg.parser = parse;
    cfg.desc = !!cfg.desc ? -1 : 1;
    return array.sort(function (a, b) {
      a = getItem.call(cfg, a);
      b = getItem.call(cfg, b);
      return cfg.desc * (parseInt(a) < parseInt(b) ? -1 : +(parseInt(a) > parseInt(b)));
    });
  };

}());



export const addNetworkToMetamask = async (type) => {
  var eth
  const web3 = new Web3(window.ethereum)
  var isTestnet = 'false'
  if (type === 'web3') {
    if (typeof ethereum !== 'undefined') {
      eth = new Web3(window.ethereum)
    } else if (typeof web3 !== 'undefined') {
      eth = new Web3(window.ethereum)
    } else {
      eth = new Web3(window.ethereum)
    }
  }

  if (typeof eth !== 'undefined') {
    var network = 0
    network = ''
    var netID = network.toString()
    var params
    if (isTestnet === 'false') {
      if (netID === '1337') {
        alert('GoC Network has already been added to Metamask.')
        return
      } else {
        params = [{
          chainId: '0x539',
          chainName: 'GoC Network',
          nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
          },
          rpcUrls: ['https://rpc.guildofchains.com'],
          blockExplorerUrls: ['https://mumbai.polygonscan.com/']
        }]
      }
    } else {
      if (netID === '80001') {
        alert('Polygon Mumbai Network has already been added to Metamask.')
        return
      } else {
        params = [{
          chainId: '0x13881',
          chainName: 'Mumbai',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
          },
          rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
          blockExplorerUrls: ['https://mumbai.polygonscan.com/']
        }]
      }
    }

    window.ethereum.request({ method: 'wallet_addEthereumChain', params })
      .then(() => console.log('Success'))
      .catch((error) => console.log('Error', error.message))
  } else {
    alert('Unable to locate a compatible web3 browser!')
  }
}
