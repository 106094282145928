import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { walletSelector, collectionsSelector, selectedHeroSelector, mastersSelector } from '../../store/selectors'
import { equipmentImg, equipmentSubtypeImg, heroImg, materialImg } from '../../store/image-service'
import * as transactionModals from '../../components/modals/Transactions.jsx'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EquipmentCard from '../../components/equipment/EquipmentCard'
import HeroCard from '../../components/hero/HeroCard'
import EquipmentStats from '../../components/equipment/EquipmentStats'
import EquipmentBasicStats from '../../components/equipment/EquipmentBasicStats'
// Store
import {
  SET_SELECTED_HERO
} from '../../store/modules/selectedHero'
// Chain Services
import * as equipmentCollection from '../../chain/chain-service/equipmentCollection'
import * as materialCollection from '../../chain/chain-service/materialCollection'
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import * as gameMaster from '../../chain/chain-service/gameMaster'
import { getRarityBorderColor, getRarityColor, parseHero } from '../../chain/helpers/parseChainData'
import * as chainHelper from '../../chain/chain-service/helper'
import * as heroManager from '../../chain/chain-service/heroManager'

// JSON Data
import equipments from '../../chain/data/equipmentPool.json'
import characterClasses from '../../chain/data/characterClasses.json'
import race from '../../chain/enumarations/race.json'
import slotName from '../../chain/enumarations/slotName.json'
import stats from '../../chain/enumarations/stats.json'
import materials from '../../chain/data/materials.json'
import subTypes from '../../chain/enumarations/subTypes.json'
import { getRarityTextColor } from '../../chain/helpers/parseChainData'
import { Fade } from 'react-reveal'

function HeroItems() {
  const navigate = useHistory()
  const dispatch = useDispatch()
  const [heroEquipments, setHeroEquipments] = useState([])
  const [inventoryEquipments, setInventoryEquipments] = useState([])
  const [selectedItemType, setSelectedItemType] = useState('')
  const [selectedRarity, setSelectedRarity] = useState('')
  const [showMaterials, setShowMaterials] = useState(false)
  const [filterInventoryEquipments, setFilterInventoryEquipments] = useState([])
  const [isApproved, setIsApproved] = useState(false)
  const { wallet } = useSelector(walletSelector)
  const { collections } = useSelector(collectionsSelector)
  const { masters } = useSelector(mastersSelector)
  const { selectedHero } = useSelector(selectedHeroSelector)
  const [materialInventory, setMaterialInventory] = useState([])
  const MySwal = withReactContent(Swal)


  const openStats = (equipment) => {
    if (equipment) {
      MySwal.fire({
        html: (
          <div className="pt-50">
            <EquipmentStats equipment={equipment} />
          </div>
        ),
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        background: '#1a1b20'
      })
    }
  }

  const getEquipmentOfHero = async () => {
    setHeroEquipments([])
    const arr = [0, 1, 2, 3, 4, 5]
    arr.map(async (slotId) => {
      const equipmentId = await equipmentManager.getEquipmentOfHero(
        selectedHero.id,
        slotId
      )
      if (equipmentId > 0) {
        const equipment = await equipmentManager.getEquipment(equipmentId)
        setHeroEquipments((prev) => [...prev, { slotId, equipment }])
      }
    })
  }

  const filterItemType = (categItem) => {
    setSelectedItemType(categItem)
    const updatedItems = filterInventoryEquipments.filter((curElem) => {
      return subTypes[curElem.subtypeId].slotId === parseInt(categItem);
    })
    setInventoryEquipments(updatedItems);
  }

  const filterItemRarity = (categItem) => {
    setSelectedRarity(categItem)
    const updatedItems = filterInventoryEquipments.filter((curElem) => {
      if (selectedItemType == '')
        return curElem?.rarity === categItem;
      else return curElem?.rarity === categItem && subTypes[curElem.subtypeId].slotId == parseInt(selectedItemType);
    })
    setInventoryEquipments(updatedItems);
  }

  const getEquipmentList = async () => {
    setSelectedItemType('')
    setSelectedRarity('')
    setInventoryEquipments([])
    setFilterInventoryEquipments([])
    const equipmentIds = await equipmentCollection.tokensOfOwner(wallet.address);
    const equipmentList = await equipmentManager.getEquipments(equipmentIds.returnData)
    setInventoryEquipments(equipmentList)
    setFilterInventoryEquipments(equipmentList)
  }

  const getMaterials = async () => {
    const playerBalance = await materialCollection.balanceOfBatch(
      Array(170).fill(wallet.address),
      Array.from(Array(170).keys())
    )
    setMaterialInventory(playerBalance)
  }

  const checkisApproved = async () => {
    const isApproved = await equipmentCollection.isApprovedForAll(wallet.address)
    setIsApproved(isApproved)
  }

  const setApprovalForAll = async () => {
    const txGas = await equipmentCollection.estimateGasSetApprovalForAll()
    await equipmentCollection
      .setApprovalForAll(collections?.equipmentCollectionAddress, masters?.equipmentMasterAddress, txGas)
      .on('sending', async () => {
        transactionModals.StartTransaction()
      })
      .on('transactionHash', async () => {
        transactionModals.TransactionSent()
        checkisApproved()
      })
      .on('receipt', async () => {
        checkisApproved()
      })
      .on('error', (err) => {
        transactionModals.TransactionError(err.message)
      })
  }

  const equip = async (equipment) => {
    try {
      const txGas = await gameMaster.estimateGasEquip(
        wallet.address,
        selectedHero.id,
        equipment.id
      )
      await gameMaster
        .equip(masters.gameMasterAddress, wallet.address, selectedHero.id, equipment.id, txGas)
        .on('sending', async () => {
          transactionModals.StartTransaction()
        })
        .on('transactionHash', async () => {
          transactionModals.TransactionSent()
        })
        .on('receipt', async () => {
          getEquipmentList()
          getEquipmentOfHero()
          setSelectedHero()
        })
        .on('error', (err) => {
          transactionModals.TransactionError(err.message)
        })
    } catch (err) {
      if (err.message.includes('caller is not token owner or approved')) {
        transactionModals.TransactionError('Please approve Equipment Contract.')
      } else if (err.message.includes('Hero level is too low')) {
        transactionModals.TransactionError('Hero level is too low!')
      } else {
        transactionModals.TransactionError(err.message)
      }
    }
  }

  const setSelectedHero = async () => {
    const hero = await heroManager.getHero(selectedHero.id)
    dispatch(SET_SELECTED_HERO(parseHero(hero)))
    navigate.push('/inventory')
  }


  useEffect(() => {
    if (selectedHero) {
      getEquipmentOfHero()
    }
  }, [selectedHero])
  useEffect(() => {
    if (wallet) {
      getEquipmentList()
      getMaterials()
      checkisApproved()
    }
  }, [wallet, collections])
  return (
    <>
      {selectedHero?.id ? (<>
        <section className="latest-match-area latest-match-bg pt-115 pb-10">
          <div className="container pt-80">
            <div className="row justify-content-center">
              {isApproved ? '' : (
                <div className="col-xl-7 col-lg-8">
                  <div className="section-title title-style-two text-center pt-10">
                    <a onClick={() => setApprovalForAll()} className="btn btn-style-two">approve!</a>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 mb-10">
                <div className="just-gamers-list">
                  <ul>
                    <li>
                      <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 0)?.equipment)} style={getRarityBorderColor(equipments[heroEquipments?.find(o => o.slotId === 0)?.equipment?.poolId]?.bcData)} className="inventory-item-icon-right">
                        <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 0)?.equipment.poolId ? equipmentSubtypeImg(equipments[heroEquipments?.find(o => o.slotId === 0)?.equipment.poolId]?.bcData.subtypeId) : 'assets/img/goc/icon/Weapons_icon.webp'} alt="" />
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>{slotName["0"]}</h5>
                        <h6 style={getRarityTextColor(equipments[heroEquipments?.find(o => o.slotId === 0)?.equipment?.poolId]?.bcData)}>{equipments[heroEquipments?.find(o => o.slotId === 0)?.equipment?.poolId]?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 0)?.equipment?.upgradeLevel})</span></h6>
                        <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 0)?.equipment} />
                      </div>
                    </li>
                    <li>
                      <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 5)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 5)?.equipment)} className="inventory-item-icon-right">
                        <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 5)?.equipment.poolId ? equipmentSubtypeImg(equipments[heroEquipments?.find(o => o.slotId === 5)?.equipment.poolId]?.bcData.subtypeId) : 'assets/img/goc/icon/Gem_icon.webp'} alt="" />
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>{slotName["5"]}</h5>
                        <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 5)?.equipment)}>{equipments[heroEquipments?.find(o => o.slotId === 5)?.equipment?.poolId]?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 5)?.equipment?.upgradeLevel})</span></h6>
                        <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 5)?.equipment} />
                      </div>
                    </li>
                    <li>
                      <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 4)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 4)?.equipment)} className="inventory-item-icon-right">
                        <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 4)?.equipment.poolId ? equipmentSubtypeImg(equipments[heroEquipments?.find(o => o.slotId === 4)?.equipment.poolId]?.bcData.subtypeId) : 'assets/img/goc/icon/Bot_icon.webp'} alt="" />
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>{slotName["4"]}</h5>
                        <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 4)?.equipment)}>{equipments[heroEquipments?.find(o => o.slotId === 4)?.equipment?.poolId]?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 4)?.equipment?.upgradeLevel})</span></h6>
                        <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 4)?.equipment} />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 mb-10">
                <HeroCard hero={selectedHero} handleClick={console.log} isShareable={true} />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 mb-10">
                <div className="just-gamers-list">
                  <ul>
                    <li>
                      <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 1)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 1)?.equipment)} className="inventory-item-icon">
                        <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 1)?.equipment.poolId ? equipmentSubtypeImg(equipments[heroEquipments?.find(o => o.slotId === 1)?.equipment.poolId]?.bcData.subtypeId) : 'assets/img/goc/icon/Helmet_icon.webp'} alt="" />
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>{slotName["1"]} </h5>
                        <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 1)?.equipment)}>{equipments[heroEquipments?.find(o => o.slotId === 1)?.equipment?.poolId]?.name}<span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 1)?.equipment?.upgradeLevel})</span></h6>
                        <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 1)?.equipment} />
                      </div>
                    </li>
                    <li>
                      <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 2)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 2)?.equipment)} className="inventory-item-icon">
                        <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 2)?.equipment.poolId ? equipmentSubtypeImg(equipments[heroEquipments?.find(o => o.slotId === 2)?.equipment.poolId]?.bcData.subtypeId) : 'assets/img/goc/icon/Top_Armor_icon.webp'} alt="" />
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>{slotName["2"]}</h5>
                        <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 2)?.equipment)}>{equipments[heroEquipments?.find(o => o.slotId === 2)?.equipment?.poolId]?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 2)?.equipment?.upgradeLevel})</span></h6>
                        <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 2)?.equipment} />
                      </div>
                    </li>
                    <li>
                      <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 3)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 3)?.equipment)} className="inventory-item-icon">
                        <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 3)?.equipment.poolId ? equipmentSubtypeImg(equipments[heroEquipments?.find(o => o.slotId === 3)?.equipment.poolId]?.bcData.subtypeId) : 'assets/img/goc/icon/Hand_icon.webp  '} alt="" />
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>{slotName["3"]}</h5>
                        <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 3)?.equipment)}>{equipments[heroEquipments?.find(o => o.slotId === 3)?.equipment?.poolId]?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 3)?.equipment?.upgradeLevel})</span></h6>
                        <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 3)?.equipment} />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {showMaterials ? (
          <>
            <section className="latest-match-area latest-match-bg pb-10">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 text-center">
                    <a onClick={() => setShowMaterials(false)} className="btn btn-info">Equipments</a>
                  </div>
                </div >
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8 pt-20">
                    <div className="section-title title-style-three white-title text-center mb-20">
                      <h2> <span>Materials</span></h2>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  {materialInventory?.map((materialCount, materialId) => {
                    if (parseInt(materialCount) == 0) return;
                    return (
                      <Fade up>
                        <div key={materialId} className="col-lg-3 col-6">
                          <div className='row'>
                            <div className="col-lg-12">
                              <div className="text-center" style={{ display: 'block' }}>
                                <h6> <span >{materials[materialId]?.name}</span></h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 pt-10">
                            <div style={{ float: 'unset', margin: '0 auto 0px' }} className="just-gamers-list-icon text-center">
                              <img src={materialImg(materialId)} alt="" />
                            </div>
                            <div className="col-lg-12">
                              <div className="text-center">
                                <p><a><span style={{ color: 'gold' }}> <b> {materialCount}</b></span></a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className="latest-match-area latest-match-bg pt-10">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 text-center">
                  <a onClick={() => setShowMaterials(true)} className="btn btn-info">Materials</a>
                </div>
              </div >
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8 pt-20">
                  <div className="section-title title-style-three white-title text-center mb-20">
                    <h2> <span>Equipment</span> List</h2>
                    <p> Click on item to equip</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="tournament-top-wrap">
                    <div className="section-title tournament-title">
                      <h2>Slot <span>Filter</span></h2>
                    </div>
                    <div className="tournament-menu">
                      <button className={selectedItemType == '' ? 'active' : ''} data-filter="*" onClick={() => getEquipmentList()}>All</button>
                      <button className={selectedItemType == '0' ? 'active' : ''} data-filter=".cat-one" onClick={() => filterItemType("0")}>MAINHAND</button>
                      <button className={selectedItemType == '1' ? 'active' : ''} data-filter=".cat-two" onClick={() => filterItemType("1")}>HELMET</button>
                      <button className={selectedItemType == '2' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("2")}>CHEST</button>
                      <button className={selectedItemType == '3' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("3")}>GLOVES</button>
                      <button className={selectedItemType == '4' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("4")}>BOOTS</button>
                      <button className={selectedItemType == '5' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemType("5")}>NECKLACE</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-40">
                <div className="col-12">
                  <div className="tournament-top-wrap">
                    <div className="section-title tournament-title">
                      <h2>Rarity <span>Filter</span></h2>
                    </div>
                    <div className="tournament-menu">
                      <button className={selectedRarity == '' ? 'active' : ''} data-filter="*" onClick={() => getEquipmentList()}>All</button>
                      <button style={getRarityTextColor({ rarity: '0' })} className={selectedRarity == '0' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("0")}>COMMON</button>
                      <button style={getRarityTextColor({ rarity: '1' })} className={selectedRarity == '1' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("1")}>UNCOMMON</button>
                      <button style={getRarityTextColor({ rarity: '2' })} className={selectedRarity == '2' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("2")}>RARE</button>
                      <button style={getRarityTextColor({ rarity: '3' })} className={selectedRarity == '3' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemRarity("3")}>EPIC</button>
                      <button style={getRarityTextColor({ rarity: '4' })} className={selectedRarity == '4' ? 'active' : ''} data-filter=".cat-two" onClick={() => filterItemRarity("4")}>LEGENDARY</button>
                      <button style={getRarityTextColor({ rarity: '5' })} className={selectedRarity == '5' ? 'active' : ''} data-filter=".cat-one" onClick={() => filterItemRarity("5")}>MYTHIC</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {inventoryEquipments?.map((equipment, index) => (
                  <div key={index} className="col-xl-3 col-lg-3 col-md-4 col-6 mb-10">
                    <Fade up>
                      <EquipmentCard equipment={equipment} index={index} handleClick={equip} selectedHero={selectedHero} isShareable={true} />
                    </Fade>
                  </div>
                ))}
              </div>
            </div >
          </section >
        )
        }

      </>
      ) : (
        <section className="latest-match-area latest-match-bg pt-200 pb-200">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8">
                <div className="section-title title-style-two text-center pt-60">
                  <a onClick={() => navigate.push('/select-hero')} className="btn btn-style-two">Please select hero first!</a>
                </div>
              </div>
            </div></div>
        </section>
      )
      }
    </>
  )
}

export default HeroItems