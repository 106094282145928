import React from 'react';
import CountUp from "react-countup";
// Chain Services
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import stats from '../../chain/enumarations/stats.json'

export default function HeroStats(data) {
  return (
    <div className="fact-area pt-90">
      <div className="row justify-content-center">
        {data?.hero?.stats?.map((heroStat, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-sm-6">
            <div className="fact-item">
              <div className="fact-icon">
                <img src="assets/img/icon/fact_icon01.webp" alt="" />
              </div>
              <div className="counter-item">
                <h2 className="counter"><span>< CountUp end={chainHelper.calculateStatValueArray(heroStat)} /></span></h2>
                <span>{stats[heroStat.statId]}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
