import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';
import CraftingBucketList from './CraftingBucketList';

function SelectCraftingBucket() {
    return (
        <>
            <HeaderCustom />
            <main>
                <CraftingBucketList />
            </main>
            <Footer />
        </>
    )
}

export default SelectCraftingBucket
