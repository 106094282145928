import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import PoolEquipmentStats from './PoolEquipmentStats';
import { equipmentSubtypeImg, slotImg } from "../../store/image-service"
import { getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData';
// JSON Data
import subTypes from '../../chain/enumarations/subTypes.json'


function PoolEquipmentCard({ equipment, dropChance, index, handleClick, selectedHero, isShareable, chainId }) {
    const MySwal = withReactContent(Swal)
    const openStats = () => {
        MySwal.fire({
            html: (
                <div className="pt-50">
                    <PoolEquipmentStats equipment={equipment} isShareable={isShareable} chainId={chainId} />
                </div>
            ),
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            background: '#1a1b20'
        })
    }
    return (
        <div key={index} className="latest-games-items mb-30">
            <div className="latest-games-thumb">
                <div className="level-tag">
                    {selectedHero && selectedHero?.level ? (<a style={{ color: (parseInt(selectedHero.level) < parseInt(equipment?.bcData?.levelRequirement) ? 'red' : 'white') }}> lv {equipment?.bcData?.levelRequirement}</a>) : (<a> lv {equipment?.bcData?.levelRequirement}</a>)}
                </div>
                <div className="subtype-tag">
                    <img src={slotImg(subTypes[equipment?.bcData?.subtypeId]?.slotId, equipment?.bcData?.rarity)} />
                </div>
                <a><img onClick={() => handleClick(equipment)} src={equipmentSubtypeImg(equipment?.bcData?.subtypeId)} alt="" /></a>
            </div>
            <div style={getRarityColor(equipment.bcData)} className=" latest-games-content">
                {dropChance ? (
                    <div className="chance-tag">
                        <a style={getRarityTextColor(equipment?.bcData)}>🍀 {dropChance}</a>
                        <br />
                    </div>
                ) : ''}
                <div className="stat-tag">
                    <a onClick={() => openStats(equipment)} style={{ color: 'white' }} className="btn transparent-btn"> <i className="fas fa-info" /> info</a>
                </div>
                <h6><a style={getRarityTextColor(equipment.bcData)}>{equipment?.name} <span>(+{equipment?.bcData?.upgradeLevel})</span></a></h6>
            </div>
        </div>
    )
}

export default PoolEquipmentCard