import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EquipmentCard from '../equipment/EquipmentCard'
import HeroCard from '../hero/HeroCard'
import MonsterCard from '../monster/MonsterCard'
import { skillImg } from '../../store/image-service'

function Prefight({ selectedHero, encounter }) {
    const MySwal = withReactContent(Swal)

    const [selectedSkill, setSelectedSkill] = useState(0)
    const [selectedSkills, setSelectedSkills] = useState([1, 1, 1, 1, 1])
    const [skillList, setSkillList] = useState([1])

    const selectSkill = (skillId) => {
        if (skillId == selectedSkill) {
            setSelectedSkill(0)
        } else {
            setSelectedSkill(skillId)
        }
    }

    const applySkill = (slotId) => {
        if (selectedSkill != 0) {
            let tempSkills = [selectedSkills[0], selectedSkills[1], selectedSkills[2], selectedSkills[3], selectedSkills[4]]
            tempSkills[slotId] = selectedSkill
            setSelectedSkills(tempSkills)
        }
    }

    return (<>
        <div className="col-lg-15">
            <h4>Encounter Review</h4>
            <div className="row" >
                <div className='col-lg-4'>
                    <HeroCard hero={selectedHero} handleClick={console.log} />
                </div>
                <div className='col-lg-4 pb-20'>
                    <h2>vS</h2>
                    <div className='row pt-10 justify-content-center'>
                        <div className='row'>
                            <div className='col-4'>
                                <div className="sidebar-new-game-thumb ">
                                    <a style={{ color: 'white' }}>
                                        <img className="skill-img" onClick={() => applySkill(0)} src={skillImg(selectedSkills[0])} alt="" />
                                        1
                                    </a>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className="sidebar-new-game-thumb">
                                    <a style={{ color: 'white' }}>
                                        <img className="skill-img" onClick={() => applySkill(1)} src={skillImg(selectedSkills[1])} alt="" />
                                        2
                                    </a>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className="sidebar-new-game-thumb">
                                    <a style={{ color: 'white' }}>
                                        <img className="skill-img" onClick={() => applySkill(2)} src={skillImg(selectedSkills[2])} alt="" />
                                        3
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-10'>
                            <div className='col-2'>
                            </div>
                            <div className='col-4'>
                                <div className="sidebar-new-game-thumb">
                                    <a style={{ color: 'white' }}>
                                        <img className="skill-img" onClick={() => applySkill(3)} src={skillImg(selectedSkills[3])} alt="" />
                                        4
                                    </a>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className="sidebar-new-game-thumb">
                                    <a style={{ color: 'white' }}>
                                        <img className="skill-img" onClick={() => applySkill(4)} src={skillImg(selectedSkills[4])} alt="" />
                                        5
                                    </a>
                                </div>
                            </div>
                            <div className='col-2'>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-10 justify-content-center'>
                        <div className='col-lg-12'>
                            <h3> Skills</h3>
                        </div>
                        <div className='row'>
                            {skillList?.map((skillId, index) => (
                                <>
                                    <div className='col-3 pt-10'>
                                        <div className="sidebar-new-game-thumb">
                                            <a >
                                                <img style={parseInt(selectedSkill) == parseInt(skillId) ? { border: '3px solid #6e6b6f', borderColor: 'gold' } : {}} onClick={() => selectSkill(skillId)} src={skillImg(skillId)} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    {selectedSkill ? (
                        <div className='row pt-20 justify-content-center'>
                            <h3 ><span style={{ color: 'orange' }}> Basic Attack </span>🗡️</h3>
                            <p>
                                Description: Basic Attack is the foundation of combat for every hero in Guild of Chains. This fundamental skill enables your hero to deliver a balanced attack that inflicts both physical and magical damage on a single enemy. </p>
                            <p> +{selectedSkill} Nova power</p>
                        </div>
                    ) : ''}

                </div>
                <div className='col-lg-4 col-12 pl-20' >
                    <MonsterCard encounter={encounter} handleClick={console.log} />
                </div>
            </div>
            <input
                id="input1"
                type="hidden"
                value={selectedSkills}
            />
        </div>
    </>)
}

export default Prefight