import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { walletSelector, managersSelector } from '../../store/selectors'
import { useHistory } from 'react-router-dom'
import { heroImg } from '../../store/image-service'

// Chain Services
import * as heroBucketManager from '../../chain/chain-service/heroBucketManager'
import { SET_HERO_BUCKET } from '../../store/modules/heroBucket'

export default function HeroBucketList() {

  const filterItem = (categItem) => {
    const updatedItems = heroChests.filter((curElem) => {
      return curElem.category === categItem;
    })
    setHeroChests(updatedItems);
  }

  const dispatch = useDispatch()
  const navigate = useHistory()
  const [heroChests, setHeroChests] = useState([])
  const { wallet } = useSelector(walletSelector)
  const { managers } = useSelector(managersSelector)

  const getHeroBuckets = async () => {
    const bucketsData = await heroBucketManager.getHeroBuckets()
    setHeroChests(bucketsData)
  }

  const setHeroBucket = async (heroBucket) => {
    dispatch(SET_HERO_BUCKET(heroBucket.id))
    navigate.push('/hero-bucket-details')
  }
  useEffect(() => {
    if (managers) {
      getHeroBuckets()
    }
  }, [managers])
  return (
    <section className="game-manage-area area-bg-hero-bucket-details pt-120 pb-120">
      {wallet ? (
        <div className="container pt-60">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <div className="section-title title-style-two text-center mb-60">
                <span>HIRING NEW HERO</span>
                <h2> <span>Campaigns</span></h2>
              </div>
            </div>
          </div>
          <div className="tournament-area mt-30">
            <div className="row">
              <div className="col-12">
                <div className="tournament-top-wrap">
                  <div className="section-title tournament-title">
                    <h2>Hero <span>Campaigns</span></h2>
                  </div>
                  <div className="tournament-menu">
                    <button className="active" data-filter="*" onClick={() => getHeroBuckets()}>All</button>
                    {/* <button data-filter=".cat-one" onClick={() => filterItem('Gamer')}>HUMAN</button>
                    <button data-filter=".cat-two" onClick={() => filterItem('Profesional')}>ORC</button>
                    <button data-filter=".cat-three" onClick={() => filterItem('Adventure')}>LEGENDARY</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {
                heroChests.map((heroChest) => {
                  return (
                    <div className="col-12" key={heroChest.id}>
                      <div className="tournament-schedule-wrap">
                        <div className="tournament-active">
                          <div className="single-tournament mb-15 grid-item cat-three cat-two">
                            <div className="row no-gutters">
                              <div className="col-lg-4">
                                <div className="tournament-schedule-item">
                                  <div className="match-team-info">
                                    <div className="match-team-logo">
                                      <a onClick={() => setHeroBucket(heroChest)}><img style={{ maxWidth: '100px' }} src={heroImg(1)} alt="" /></a>
                                    </div>
                                  </div>
                                  <div className="coming-match-status">
                                    <img style={{ maxWidth: '100px' }} src={heroImg(3)} alt="" />
                                  </div>
                                  <div className="match-team-info">
                                    <div className="match-team-logo">
                                      <a onClick={() => setHeroBucket(heroChest)}><img style={{ maxWidth: '100px' }} src={heroImg(2)} alt="" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-8">
                                <div className="tournament-schedule-content">
                                  <h3><a onClick={() => setHeroBucket(heroChest)}>Campaign Id: <span>{heroChest.id}</span></a></h3>
                                  <div className="tournament-schedule-meta">
                                    <h5>price : <span>0 {wallet ? wallet.symbol : ''}</span></h5>
                                    <a className='button-hover-custom' onClick={() => setHeroBucket(heroChest)}>Details</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      ) : (
        <div className="container pt-60">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <div className="section-title title-style-two text-center mb-60">
                <h2>Connect  <span>Wallet!</span></h2>
                <span>To begin your adventure in "Guild of Chains," simply connect your wallet</span>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </section >
  )
}
