import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { walletSelector, managersSelector, transactionHistorySelector } from '../../store/selectors'
import { useHistory } from 'react-router-dom'
import { heroImg, monsterImg } from '../../store/image-service'
// Chain Services
import { SET_LATEST_TRANSACTION } from '../../store/modules/latestTransaction'

export default function TransactionList() {

  const filterItem = (categItem) => {
    console.log(categItem)
    const updatedItems = transactions.filter((curElem) => {
      return curElem.type === categItem;
    })
    setFilteredTransactions(updatedItems);
  }

  const dispatch = useDispatch()
  const navigate = useHistory()
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const { managers } = useSelector(managersSelector)
  const { transactionHistory } = useSelector(transactionHistorySelector)

  const getTransactions = async () => {
    setTransactions([])
    setFilteredTransactions([])
    console.log('transactionHistory', transactionHistory)
    setTransactions(transactionHistory)
    setFilteredTransactions(transactionHistory)
  }

  const setSelectedTransaction = async (transaction) => {
    dispatch(SET_LATEST_TRANSACTION(transaction.blockHash))
    navigate.push('/encounter-result')
  }
  useEffect(() => {
    if (managers && transactionHistory) {
      getTransactions()
    }
  }, [managers, transactionHistory])
  return (

    <section className="game-manage-area game-mange-bg pt-30 pb-60">
      <div className="container">
        <div className="tournament-area mt-30">
          <div className="row">
            <div className="col-12">
              <div className="tournament-top-wrap">
                <div className="section-title tournament-title">
                  <h2><span>Transactions</span></h2>
                </div>
                <div className="tournament-menu">
                  <button className="active" data-filter="*" onClick={() => getTransactions()}>All</button>
                  <button data-filter=".cat-one" onClick={() => filterItem('sendHeroToEncounter')}>Encounters</button>
                  <button data-filter=".cat-two" onClick={() => filterItem('crafting')}>Crafting</button>
                  <button data-filter=".cat-three" onClick={() => filterItem('marketBuy')}>Premium Market</button>
                  <button data-filter=".cat-three" onClick={() => filterItem('newHero')}>New Hero</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {
              filteredTransactions && filteredTransactions.length > 0 ? filteredTransactions?.map((transaction) => {
                return (
                  <div className="col-12" key={transaction?.blockHash}>
                    <div className="tournament-schedule-wrap">
                      <div className="tournament-active">
                        <div className="single-tournament mb-15 grid-item cat-three cat-two">
                          <div className="row no-gutters">
                            <div className="col-lg-4">
                              <div className="tournament-schedule-item">
                                <div className="match-team-info">
                                  <div className="match-team-logo">
                                    <a><img style={{ maxWidth: '120px' }} src={heroImg(transaction.heroPoolId)} alt="" /></a>
                                  </div>
                                </div>
                                <div className="coming-match-status">
                                  <img src="assets/img/team/match_vs.webp" alt="" />
                                </div>
                                <div className="match-team-info">
                                  <div className="match-team-logo">
                                    <a><img style={{ maxWidth: '120px' }} src={monsterImg(transaction.encounter)} alt="" /></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="tournament-schedule-content">
                                <h3><a>Type: <span>{transaction.type} </span> BlockNumber :<span>{transaction.rawTx.blockNumber} </span></a></h3>
                                <p>From: {transaction.rawTx.from}</p>
                                <p>To: {transaction.rawTx.to}</p>
                                <p>BlockHash: {transaction.blockHash.slice(0, 10)}</p>
                                <p>attackerWon: {transaction?.combatResults?.attackerWon?.toString()}</p>
                                <p>Rewards:{transaction?.rewards?.ids?.toString()} </p>
                                <p>Amounts:{transaction?.rewards?.values?.toString()} </p>
                                <div className="tournament-schedule-meta">
                                  <h5>GasUsed : <span>{transaction?.rawTx?.gasUsed?.toString()}</span></h5>
                                  <a className='button-hover-custom' onClick={() => setSelectedTransaction(transaction)}>Details</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) : ''
            }

          </div>
        </div>
      </div>
    </section>
  )
}
