import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    transactionHistory: []
}

export const setTransActionHistory = (state) => async (dispatch) => {
    await dispatch(SET_TRANSACTION_HISTORY(state))
}

export const transactionHistorySlice = createSlice({
    name: 'transactionHistory',
    initialState,
    reducers: {
        SET_TRANSACTION_HISTORY: (state, action) => {
            state.transactionHistory = action.payload
        }
    }
})

export const { SET_TRANSACTION_HISTORY } = transactionHistorySlice.actions
export default transactionHistorySlice.reducer
