import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedHero: null
}

export const setSelectedHero = (state) => async (dispatch) => {
    await dispatch(SET_SELECTED_HERO(state))
}

export const heroSlice = createSlice({
    name: 'selectedHero',
    initialState,
    reducers: {
        SET_SELECTED_HERO: (state, action) => {
            state.selectedHero = action.payload
        }
    }
})

export const { SET_SELECTED_HERO } = heroSlice.actions
export default heroSlice.reducer
