import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { walletSelector, collectionsSelector, latestTransactionSelector, transactionHistorySelector, selectedHeroSelector } from '../../store/selectors'
import { materialImg } from '../../store/image-service'
// Chain Services
import * as heroCollection from '../../chain/chain-service/heroCollection'
import * as heroManager from '../../chain/chain-service/heroManager'
import * as chainHelper from '../../chain/chain-service/helper'
import * as zoneManager from '../../chain/chain-service/zoneManager'
// JSON Data
import encounters from '../../chain/data/encounters.json'
import monsters from '../../chain/data/monsters.json'
import materialData from '../../chain/data/materials.json'
import MonsterCard from '../../components/monster/MonsterCard'
import { Fade } from 'react-reveal'
import HeroCard from '../../components/hero/HeroCard'
let delay = 100

function ResultDetails() {
  const { wallet } = useSelector(walletSelector)
  const { transactionHistory } = useSelector(transactionHistorySelector)
  const { latestTransaction } = useSelector(latestTransactionSelector)
  const navigate = useHistory()
  const [newHero, setNewHero] = useState([])
  const [transaction, setTransaction] = useState([])
  const [encounter, setEncounter] = useState()

  const getResults = async () => {
    const transaction = transactionHistory.filter((currentTransaction) => {
      return currentTransaction.blockHash === latestTransaction;
    })
    const encounter = await getEncounter(transaction?.[0]?.encounter)
    let hero = await heroManager.getHero(transaction?.[0]?.hero)
    setNewHero(hero)
    setEncounter(encounter)
    setTransaction(transaction?.[0])
  }

  const getEncounter = async (encounterId) => {
    const tempEncounter = encounters[encounterId]
    const tempMonster = monsters[tempEncounter.bcData.getProp('monsterId')]
    return {
      details: tempEncounter,
      monster: tempMonster
    }
  }

  useEffect(() => {
    if (latestTransaction && wallet && transactionHistory) {
      getResults()
    }
  }, [latestTransaction, wallet, transactionHistory])
  return (
    <section className="just-gamers-area latest-match-bg pt-115 pb-120">
      <div className="container pt-60">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-8">
            <div className="row justify-content-center">
              <Fade left delay={400}>
                <h4>Hero</h4>
                <HeroCard handleClick={console.log} hero={newHero} index={1} />
              </Fade>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="row justify-content-center pb-20">
                  <a onClick={() => navigate.push('/emerald-canopy')} className="btn btn-info">Back to Zone</a>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="features-item mb-30">
                  <div className="features-list-wrap">
                    <ul>
                      {transaction?.roundResults?.monsterResults?.map((res, index) => {
                        let round = transaction?.roundResults
                        console.log(round)
                        return (
                          <>
                            <Fade up delay={delay}>
                              <li>
                                <div className="features-list-content">
                                  <div className="product-tag"><a>Round {round?.monsterResults[index]?.roundOrder + 1}</a></div>
                                  <h6><a> ⚔️ {encounter?.monster?.name}  </a></h6>
                                  <h6><a>❤️  {round?.heroResults[index]?.heroHp == 0 ? '☠️' : round?.heroResults[index]?.heroHp} <span style={{ color: 'red' }}>(-{round?.heroResults[index]?.heroDamageReceived})</span> </a></h6>
                                </div>
                              </li>
                            </Fade>
                          </>
                        )
                      })}
                      <Fade right delay={delay}>
                        <li>
                          <div className="features-list-img">
                            <img src="assets/img/icon/gamer_list_icon01.webp" alt="" />
                          </div>
                          <div className="features-list-content">
                            <div className="product-tag"><a>Result</a></div>
                            <h2>{transaction?.combatResults?.attackerWon == true ? (<span style={{ color: '#00fa00' }}>WIN</span>) : transaction?.combatResults?.attackerWon == false ? (<span style={{ color: 'red' }}>LOSE</span>) : ''}</h2>
                          </div>
                        </li>
                      </Fade>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="features-item mb-30">
                  <div className="features-list-wrap">
                    <ul>
                      {transaction?.roundResults?.monsterResults?.map((res, index) => {
                        let round = transaction?.roundResults
                        return (
                          <>
                            <Fade up delay={delay}>
                              <li>
                                <div className="features-list-content">
                                  <div className="product-tag"><a>Round {round?.monsterResults[index]?.roundOrder + 1}</a></div>
                                  <h6><a> ⚔️ {newHero?.name}  </a></h6>
                                  <h6><a>  ❤️ {round?.monsterResults[index]?.monsterHp == 0 ? '☠️' : round?.monsterResults[index]?.monsterHp} <span style={{ color: 'gold' }}>(-{round?.monsterResults[index]?.monsterDamageReceived})</span></a></h6>
                                </div>
                              </li>
                            </Fade>
                          </>
                        )
                      })}
                      <Fade right delay={delay}>
                        <li>
                          <div className="features-list-img">
                            <img src="assets/img/icon/gamer_list_icon01.webp" alt="" />
                          </div>
                          <div className="features-list-content">
                            <div className="product-tag"><a>Result</a></div>
                            <h2>{transaction?.combatResults?.attackerWon == false ? (<span style={{ color: '#00fa00' }}>WIN</span>) : transaction?.combatResults?.attackerWon == true ? (<span style={{ color: 'red' }}>LOSE</span>) : ''}</h2>
                          </div>
                        </li>
                      </Fade>
                    </ul>
                  </div>
                </div>
              </div>
              {transaction?.combatResults?.attackerWon == true ? (
                <div className="col-lg-12">
                  <div className="features-item mb-30">
                    <div className="features-head mb-35">
                      <div className="product-tag"><a>Rewards</a></div>
                      <p>Experience: <span style={{ color: 'yellow' }} >{chainHelper.calculateValue(encounter?.details?.bcData?.experienceReward)} 🟡</span></p>
                    </div>
                    <div className="features-list-wrap">
                      <ul>
                        {transaction?.rewards?.ids?.map((materialId, index) => {
                          if (transaction?.rewards?.values[index].toString() !== '0') {
                            return (
                              <>
                                <li>
                                  <div className="features-list-img">
                                    <div className="just-gamers-list-icon">
                                      <img src={materialImg(materialId)} alt="" />
                                    </div>
                                  </div>
                                  <div className="features-list-content">
                                    <h6><a style={{ color: 'gold' }}>{materialData[materialId].name}</a> <a >({transaction?.rewards?.values[index]})</a></h6>
                                    <h6> </h6>
                                  </div>
                                </li>
                              </>
                            )
                          } else {
                            return (<></>)
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
          <div className="col-lg-3 col-8">
            <div className="row justify-content-center">
              <Fade right delay={400}>
                <h4>Monster</h4>
                <MonsterCard handleClick={console.log} encounter={encounter} index={1} />
              </Fade>
            </div>
          </div>
        </div>
      </div>

    </section >
  )
}

export default ResultDetails