import { store } from '../../store'
import Web3 from 'web3'

// ABIS
import heroBucketManagerABI from '../../chain/abis/abi_HeroBucketManager.json'

export const getHeroBuckets = async () => {
  const heroBucketManagerAddress = await store.getState().managers.managers.heroBucketManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroBucketManagerContract = new web3.eth.Contract(
    heroBucketManagerABI,
    heroBucketManagerAddress
  )
  return await heroBucketManagerContract.methods.getHeroBuckets().call()
}

export const getHeroBucket = async (_heroBucketId) => {
  const heroBucketManagerAddress = await store.getState().managers.managers.heroBucketManagerAddress
  const web3 = new Web3(window.ethereum)
  const heroBucketManagerContract = new web3.eth.Contract(
    heroBucketManagerABI,
    heroBucketManagerAddress
  )
  return await heroBucketManagerContract.methods.getHeroBucket(_heroBucketId).call()
}
