import { store } from '../../store'
import { chainPicker } from '../helpers/chainInfo'
import Web3 from 'web3'

// ABIS
import gameMasterABI from '../../chain/abis/abi_GameMaster.json'

export const estimateGasBuyHero = async (id, address, cost) => {
  const { gasMultiplier } = chainPicker(store.getState().wallet.wallet.chainId)
  const gameMasterAddress = await store.getState().masters.masters.gameMasterAddress
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return [((await gameMasterContract.methods.buyHero(id).estimateGas({ from: address, value: cost })) * gasMultiplier).toFixed(), gameMasterContract]
}

export const buyHero = (gameMasterContract, id, address, cost, txGas) => {
  return gameMasterContract.methods.buyHero(id).send({ from: address, value: cost, gas: txGas })
}

export const estimateGasEncounter = async (address, _heroIds, _encounterId, skills) => {
  const { gasMultiplier } = chainPicker(store.getState().wallet.wallet.chainId)
  const gameMasterAddress = await store.getState().masters.masters.gameMasterAddress
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return ((await gameMasterContract.methods.sendHeroToEncounter(_heroIds, _encounterId, skills).estimateGas({ from: address })) * gasMultiplier).toFixed()
}

export const sendHeroToEncounter = (gameMasterAddress, address, _heroIds, _encounterId, skills, txGas) => {
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return gameMasterContract.methods.sendHeroToEncounter(_heroIds, _encounterId, skills).send({ from: address, gas: txGas })
}

export const craftEquipment = (gameMasterContract, address, _craftingBucketId, txGas) => {
  return gameMasterContract.methods.craftEquipment(_craftingBucketId).send({ from: address, gas: txGas })
}

export const estimateGasCraftEquipment = async (address, _craftingBucketId) => {
  const { gasMultiplier } = chainPicker(store.getState().wallet.wallet.chainId)
  const gameMasterAddress = await store.getState().masters.masters.gameMasterAddress
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return [((await gameMasterContract.methods.craftEquipment(_craftingBucketId).estimateGas({ from: address })) * gasMultiplier).toFixed(), gameMasterContract]
}

export const equip = (gameMasterAddress, address, _heroId, _equipmentId, txGas) => {
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return gameMasterContract.methods.equip(_heroId, _equipmentId).send({ from: address, gas: txGas })
}

export const estimateGasEquip = async (address, _heroId, _equipmentId) => {
  const { gasMultiplier } = chainPicker(store.getState().wallet.wallet.chainId)
  const gameMasterAddress = await store.getState().masters.masters.gameMasterAddress
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return ((await gameMasterContract.methods.equip(_heroId, _equipmentId).estimateGas({ from: address })) * gasMultiplier).toFixed()
}

export const upgradeEquipment = (gameMasterAddress, address, _equipmentId, _premiumUpgradeScrollUsed, txGas) => {
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return gameMasterContract.methods.upgradeEquipment(_equipmentId, _premiumUpgradeScrollUsed).send({ from: address, gas: txGas })
}

export const estimateGasUpgradeEquipment = async (address, _equipmentId, _premiumUpgradeScrollUsed) => {
  const { gasMultiplier } = chainPicker(store.getState().wallet.wallet.chainId)
  const gameMasterAddress = await store.getState().masters.masters.gameMasterAddress
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return ((await gameMasterContract.methods.upgradeEquipment(_equipmentId, _premiumUpgradeScrollUsed).estimateGas({ from: address })) * gasMultiplier).toFixed()
}

export const buyStoreItem = (gameMasterContract, address, _storeItemId, _amount, cost, txGas) => {
  return gameMasterContract.methods.buyStoreItem(_storeItemId, _amount).send({ from: address, value: cost, gas: txGas })
}

export const estimateGasBuyStoreItem = async (address, _storeItemId, _amount, cost) => {
  const { gasMultiplier } = chainPicker(store.getState().wallet.wallet.chainId)
  const gameMasterAddress = await store.getState().masters.masters.gameMasterAddress
  const web3 = new Web3(window.ethereum)
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  )
  return [((await gameMasterContract.methods.buyStoreItem(_storeItemId, _amount).estimateGas({ from: address, value: cost })) * gasMultiplier).toFixed(), gameMasterContract]
}
