import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    collections: null
}
export const setCollections = (collections) => async (dispatch) => {
    await dispatch(SET_COLLECTIONS({ collections }))
}

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        SET_COLLECTIONS: (state, action) => {
            state.collections = action.payload
        }
    }
})

export const {
    SET_COLLECTIONS
} = collectionsSlice.actions
export default collectionsSlice.reducer
