import * as chainHelper from '../../chain/chain-service/helper'
import { classImg, heroImg } from '../../store/image-service'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import PoolHeroStats from './PoolHeroStats'
import ImageLoader from '../imageloader/ImageLoader'
// Chain Services
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import { getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData'
import * as statParser from '../../chain/helpers/parseStats'
// JSON Data
import characterClasses from '../../chain/data/characterClasses.json'


function PoolHeroCard({ hero, handleClick, index, dropChance }) {
    const characterClass = characterClasses[hero?.bcData?.characterClass]
    const heroStats = characterClass?.bcData?.baseStats
    const MySwal = withReactContent(Swal)
    const openStats = (hero) => {
        MySwal.fire({
            html: (
                <PoolHeroStats hero={hero} />
            ),
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            background: '#1a1b20'
        })
    }
    return (
        <div index={index} className="latest-games-items mb-30">
            <div className="latest-games-thumb">
                <div className="hp-tag">
                    <a> ❤️ {statParser.getHealtPoints(heroStats)}</a>
                </div>
                <div className="stamina-tag">
                    <a> ⚡ {statParser.getStamina(heroStats)}</a>
                </div>
                <div className="level-tag">
                    <a> lv 1</a>
                </div>
                <div className="class-tag">
                    <img src={classImg(characterClass?.name)} />
                </div>
                <a onClick={() => handleClick(hero)}>  <ImageLoader source={heroImg(hero?.id)} alt="" /></a>
            </div>
            <div style={getRarityColor(hero?.bcData)} className="latest-games-content">

                {dropChance ? (
                    <div className="chance-tag">
                        <a style={getRarityTextColor(hero?.bcData)}>🍀 {dropChance}</a>
                    </div>
                ) : ''}
                <div className="stat-tag">
                    <a onClick={() => openStats(hero)} className="btn transparent-btn"> <i className="fas fa-info" /> info</a>
                </div>
                <h6><a style={getRarityTextColor(hero?.bcData)}>{hero?.name} <span></span></a></h6>
            </div>
        </div>
    )
}

export default PoolHeroCard