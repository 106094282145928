
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import Slider from 'react-slick';
import { materialImg, monsterImg, statImg } from '../../store/image-service';
import * as chainHelper from '../../chain/chain-service/helper'

// JSON Data
import stats from '../../chain/enumarations/stats.json'
import materials from '../../chain/data/materials.json'
import { getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData';
function MonsterStats({ encounter }) {
    const monsterStats = encounter?.monster?.bcData?.stats
    const primaryStats = [9, 10, 11, 0, 1, 2, 5, 6, 7, 8]
    const secondaryStats = [3, 4, 15, 14, 17]
    const miscellaneousStats = [12, 13, 16, 18]
    const [isStat1, setIsStat1] = useState(true)
    const [isStat2, setIsStat2] = useState(false)
    const [isStat3, setIsStat3] = useState(false)
    const [isRewards, setIsRewards] = useState(false)
    const [monsterStatIds, setMonsterStatIds] = useState(primaryStats)

    const selectPrimaryStats = () => {
        setIsStat1(true)
        setIsStat2(false)
        setIsStat3(false)
        setIsRewards(false)
        setMonsterStatIds(primaryStats)
    }

    const selectStat2 = () => {
        setIsStat1(false)
        setIsStat2(true)
        setIsStat3(false)
        setIsRewards(false)
        setMonsterStatIds(secondaryStats)
    }

    const selectStat3 = () => {
        setIsStat1(false)
        setIsStat2(false)
        setIsStat3(true)
        setIsRewards(false)
        setMonsterStatIds(miscellaneousStats)
    }

    const selectRewards = () => {
        setIsStat1(false)
        setIsStat2(false)
        setIsStat3(false)
        setIsRewards(true)
    }
    return (<>
        <div className="row justify-content-center">
            <div className="features-head ">
                <h4 style={getRarityTextColor(encounter?.monster)}>{encounter?.monster?.name}</h4>
            </div>
        </div >
        <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 order-2 order-lg-2">
                <div className="features-item" style={getRarityColor(encounter?.monster)}>
                    <ul className="nav nav-tabs">
                        <div className="col-lg-6 col-12">
                            <li key={1} className="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectPrimaryStats()} className={"nav-link " + (isStat1 ? "active" : '')}><h4>Primary</h4></a>
                            </li>
                        </div>
                        <div className="col-lg-6 col-12">
                            <li key={2} className="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat2()} className={"nav-link " + (isStat2 ? "active" : '')}><h4>Secondary</h4></a>
                            </li>
                        </div>
                        <div className="col-12">
                            <li key={3} className="nav-item">
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat3()} className={"nav-link " + (isStat3 ? "active" : '')}><h4>Miscellaneous</h4></a>
                            </li>
                        </div>
                        <div className="col-12">
                            <li key={3} className="nav-item">
                                <a style={{ color: '#ffc107', cursor: 'pointer' }} onClick={() => selectRewards()} className={"nav-link " + (isRewards ? "active" : '')}><h4 style={{ color: '#ffc107' }}>Rewards</h4></a>
                            </li>
                        </div>
                    </ul>

                    <div className="features-list-wrap" >
                        <ul>
                            {isRewards ? (
                                <>
                                    {encounter?.details?.bcData?.materialRewards.map((materialReward, index) => (
                                        <>
                                            <li key={index}>
                                                <div className="features-list-img">
                                                    <div className="just-gamers-list-icon">
                                                        <img src={materialImg(materialReward?.id)} alt="" />
                                                    </div>
                                                </div>
                                                <div className="features-list-content">

                                                </div>
                                                <div className="features-list-content">
                                                    <div className="product-tag"><a>{materials[materialReward?.id].name} </a></div>
                                                    <h6><a>Range: {materialReward?.amountRange?.min} - {materialReward?.amountRange?.max}</a></h6>
                                                    <div className="product-tag"><a>Chance </a></div>
                                                    <h6 ><a>{chainHelper.calculateMaterialDropChance(encounter, index)}%</a></h6>
                                                </div>

                                            </li>
                                        </>
                                    )
                                    )}
                                </>
                            ) :
                                (
                                    <>{monsterStatIds?.map((stat, index) => {
                                        if (index % 2 == 1) {
                                            return
                                        }
                                        return (
                                            <li key={index}>
                                                <div className='col-lg-12'>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-12'>
                                                            <div className="features-list-content">
                                                                <div className="stat-item">
                                                                    <div className="fact-icon">
                                                                        <img src={statImg(stats[stat])} alt="" />
                                                                    </div>
                                                                    <div className="counter-item">
                                                                        <h5 className="stat-value"><span>{chainHelper.calculateStatValue(monsterStats?.[stat]) || 0}</span></h5>
                                                                        <h6>{stats[stat].slice(0, 7)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-12'>
                                                            {stats?.[monsterStatIds[index + 1]] ? (
                                                                <div className="features-list-content">
                                                                    <div className="stat-item">
                                                                        <div className="fact-icon">
                                                                            <img src={statImg(stats[monsterStatIds[index + 1]])} alt={stats[monsterStatIds[index + 1]]} />
                                                                        </div>
                                                                        <div className="counter-item">
                                                                            <h5 className="stat-value"><span>{chainHelper.calculateStatValue(monsterStats?.[monsterStatIds[index + 1]]) || 0}</span></h5>
                                                                            <h6>{stats[monsterStatIds[index + 1]].slice(0, 7)}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}</>)
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)
    // return (<>
    //     <div className="row justify-content-center">
    //         <div className="col-lg-12 col-md-12 order-2 order-lg-2 mb-10">
    //             <ul className="nav nav-tabs">
    //                 <li className="nav-item">
    //                     <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat1()} className={"nav-link " + (isStat1 ? "active" : '')}><h4>Stats-1</h4></a>
    //                 </li>
    //                 <li className="nav-item">
    //                     <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat2()} className={"nav-link " + (isStat2 ? "active" : '')}><h4>Stats-2</h4></a>
    //                 </li>
    //                 <li className="nav-item">
    //                     <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => selectStat3()} className={"nav-link " + (isStat3 ? "active" : '')}><h4>Stats-3</h4></a>
    //                 </li>
    //                 <li className="nav-item">
    //                     <a style={{ color: '#ffc107', cursor: 'pointer' }} onClick={() => selectRewards()} className={"nav-link " + (isRewards ? "active" : '')}><h4 style={{ color: '#ffc107' }}>Rewards</h4></a>
    //                 </li>
    //             </ul>
    //         </div>
    //     </div >
    //     <div className="row justify-content-center">
    //         <div className="col-lg-12 col-md-12 order-2 order-lg-2">
    //             <div className="features-item">
    //                 <div className="features-head ">
    //                     <h4>{encounter?.monster?.name}</h4>
    //                 </div>
    //                 <div className="features-list-wrap" >
    //                     <ul>
    //                         {isRewards ? (
    // <>
    //     {encounter?.details?.materialRewards.map((materialReward, index) => (
    //         <>
    //             <li>
    //                 <div className="features-list-img">
    //                     <div className="just-gamers-list-icon">
    //                         <img src={materialImg(materialReward?.id)} alt="" />
    //                     </div>
    //                 </div>
    //                 <div className="features-list-content">

    //                 </div>
    //                 <div className="features-list-content">
    //                     <div className="product-tag"><a>materialId: {materialReward?.id} </a></div>
    //                     <h6><a>Range: {materialReward?.amountRange?.min} - {materialReward?.amountRange?.max}</a></h6>
    //                     <div className="product-tag"><a>Chance </a></div>
    //                     <h6 ><a>{chainHelper.percentage(materialReward?.chance, chainHelper.calculateTotalChance(encounter?.details?.materialRewards))}%</a></h6>
    //                 </div>

    //             </li>
    //         </>
    //     )
    //     )}
    // </>
    //                         ) :
    //                             (
    //                                 <>{monsterStats?.map((stat, index) => {
    //                                     index = index + iterator
    //                                     if (index % 2 == 1) {
    //                                         return
    //                                     }
    //                                     return (
    //                                         <li>
    //                                             <div className="features-list-content">
    //                                                 <div className="product-tag"><a><span style={{ color: 'white' }}> ({chainHelper.calculateStatValue(encounter?.monster?.stats?.[index + 1])}) </span>{stats[index + 1]} </a></div>
    //                                             </div>
    //                                             <div className="features-list-content">
    //                                                 <div className="product-tag"><a>{stats[index]}  <span style={{ color: 'white' }}> ({chainHelper.calculateStatValue(stat)})</span></a></div>
    //                                             </div>
    //                                         </li>
    //                                     )
    //                                 })}</>)
    //                         }
    //                     </ul>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </>)
}

export default MonsterStats