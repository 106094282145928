import { store } from '../../store'
import Web3 from 'web3'

// ABIS
import materialManagerABI from '../../chain/abis/abi_MaterialManager.json'


export const getUpgradeScrollOfZoneById = async (_zoneId) => {
  const materialManagerAddress = await store.getState().managers.managers.materialManagerAddress
  const web3 = new Web3(window.ethereum)
  const materialManagerContract = new web3.eth.Contract(
    materialManagerABI,
    materialManagerAddress
  )
  return await materialManagerContract.methods.getUpgradeScrollOfZoneById(_zoneId).call()
}

export const getPremiumUpgradeScrollId = async () => {
    const materialManagerAddress = await store.getState().managers.managers.materialManagerAddress
    const web3 = new Web3(window.ethereum)
    const materialManagerContract = new web3.eth.Contract(
      materialManagerABI,
      materialManagerAddress
    )
    return await materialManagerContract.methods.getPremiumUpgradeScrollId().call()
  }
