import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';
import ZoneList from './ZoneList';

function Zones() {
    return (
        <>
            <HeaderCustom />
            <main>
                <ZoneList />
            </main>
            <Footer />
        </>
    )
}

export default Zones
