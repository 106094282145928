import React from 'react'
import { useHistory } from 'react-router-dom'



function ZoneList() {
  const navigation = useHistory()
  const selectZone1 = async () => {
    navigation.push('/emerald-canopy')
  }

  return (
    <section className="upcoming-games-area zones-bg pt-120 pb-80">
      <div className="container pt-60">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              <h2>Discover <span style={{ color: '#f02d2d' }}>zones</span></h2>
              <p></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>Isle of the </span>
                  <h4><a href>Emerald Canopy</a></h4>
                </div>
                <div className="uc-game-price">
                  <h5>lv 1-10</h5>
                </div>
              </div>
              <div className="upcoming-game-thumb">
                <img src="assets/img/goc/zones-bg/zone-1.webp" alt="" />
                <div className="upcoming-game-cart">
                  <a href onClick={() => selectZone1()} style={{ color: 'white' }} className="btn transparent-btn">⚔️ Select Zone</a>
                </div>
              </div>
              <br />
              <p>A lush, verdant island teeming with life, this zone is a dense jungle filled with ancient ruins and hidden temples. The heroes must face a myriad of challenges, from navigating the tangled undergrowth to facing the exotic and deadly creatures that inhabit the canopy above. </p>
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>Isle of the </span>
                  <h4><a>Crystal Caverns</a></h4>
                </div>
                <div className="uc-game-price">
                  <h5>lv 11-20</h5>
                </div>
              </div>
              <div className="upcoming-game-thumb">
                <img src="assets/img/goc/zones-bg/zone-2.webp" alt="" />
                <div className="upcoming-game-cart">
                  <a href className="btn transparent-btn"> ⚔️ Select Zone</a>
                </div>
              </div>
              <br />
              <p>Beneath the surface of this seemingly barren island lies a vast network of caves, illuminated by the mesmerizing glow of luminescent crystals. The heroes will delve deep into the caverns, solving puzzles and navigating treacherous subterranean passages, all the while confronting the strange and ancient creatures that call this place home. </p>
            </div>
          </div> */}
          {/* <div className="col-lg-12 col-md-12">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>Isle of the </span>
                  <h4><a>Crimson Torrent</a></h4>
                </div>
                <div className="uc-game-price">
                  <h5>lv 21-30</h5>
                </div>
              </div>
              <div className="upcoming-game-thumb">
                <img src="assets/img/goc/zones-bg/zone-3.webp" alt="" />
                <div className="upcoming-game-cart">
                  <a href className="btn transparent-btn"><i className="fas fa-clock" />Select Zone</a>
                </div>
              </div>
              <br />
              <p>A volcanic landscape filled with rivers of lava and bubbling geysers, this fiery island tests the heroes' endurance and agility. The intense heat and harsh environment are home to monstrous beings born of fire and molten rock, eager to challenge the heroes' mettle.</p>
            </div>
          </div> */}
          {/* <div className="col-lg-6 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>Isle of the </span>
                  <h4><a>Whispering Shadows</a></h4>
                </div>
                <div className="uc-game-price">
                  <h5>lv 31-40</h5>
                </div>
              </div>
              <p>Shrouded in an ever-present mist, this eerie island is known for the chilling whispers that echo through the air, as if the shadows themselves are speaking. The heroes will need to navigate a labyrinth of treacherous paths and confront the enigmatic creatures that dwell within the darkness.</p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/goc/zones-bg/zone-4.webp" alt="" />
                <div className="upcoming-game-cart">
                  <a href className="btn transparent-btn"><i className="fas fa-clock" />Select Zone</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>Isle of the </span>
                  <h4><a>Howling Winds</a></h4>
                </div>
                <div className="uc-game-price">
                  <h5>lv 41-50</h5>
                </div>
              </div>
              <p>A desolate and frigid island swept by relentless icy gales, this zone demands resilience and fortitude. The heroes must brave the frozen tundra and scale towering peaks, facing off against fearsome ice-dwelling creatures and battling the elements themselves.</p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/goc/zones-bg/zone-5.webp" alt="" />
                <div className="upcoming-game-cart">
                  <a href className="btn transparent-btn"><i className="fas fa-clock" />Select Zone</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>Isle of the </span>
                  <h4><a>Shattered Sky</a></h4>
                </div>
                <div className="uc-game-price">
                  <h5>lv 51-60</h5>
                </div>
              </div>
              <p>A surreal and otherworldly island, where floating landmasses drift through the sky, connected by delicate bridges and winding staircases. The heroes will need to navigate this gravity-defying landscape, combating airborne enemies and harnessing the power of wind and lightning to overcome the challenges that await them. </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/goc/zones-bg/zone-6.webp" alt="" />
                <div className="upcoming-game-cart">
                  <a href className="btn transparent-btn"><i className="fas fa-clock" />Select Zone</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>Isle of the </span>
                  <h4><a>Fallen</a></h4>
                </div>
                <div className="uc-game-price">
                  <h5>lv 61-70</h5>
                </div>
              </div>
              <p>An ancient battleground and final resting place for countless warriors, this eerie island is haunted by the restless spirits of the past. The heroes must confront their own fears and inner demons as they face the spectral forces that linger on this cursed land, seeking to bring an end to the torment that has plagued the island for centuries.</p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/goc/zones-bg/zone-7.webp" alt="" />
                <div className="upcoming-game-cart">
                  <a href className="btn transparent-btn"><i className="fas fa-clock" />Select Zone</a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default ZoneList