import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { walletSelector, collectionsSelector } from '../../store/selectors'
// Chain Services
import * as equipmentManager from '../../chain/chain-service/equipmentManager'

// JSON Data
import EquipmentCard from '../../components/equipment/EquipmentCard'


function EquipmentDetail() {
    const { wallet } = useSelector(walletSelector)
    const { collections } = useSelector(collectionsSelector)
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const [currentEquipment, setCurrentEquipment] = useState([])

    const getEquipmentDetails = async () => {
        const equipmentId = searchParams.get('id')
        const equipment = await equipmentManager.getEquipmentFallback(equipmentId, searchParams.get('chainId'))
        setCurrentEquipment(equipment)
    }

    useEffect(() => {
        getEquipmentDetails()
    }, [])
    return (
        <section className="just-gamers-area select-crafting-bucket-bg pt-115 pb-120">
            <div className="container">
                <div className="row pt-120">
                    <div className='col-lg-12'>
                        <div className="row">
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                                {currentEquipment.id ? (<EquipmentCard equipment={currentEquipment} handleClick={console.log} isShareable={true} chainId={searchParams.get('chainId')} dropChance={'0.25%'} />) : (
                                    <>
                                        {wallet ? (<h2> Equipment Not Found</h2>) : (<h2>Connect wallet!</h2>)}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default EquipmentDetail