import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import TransactionList from './TransactionList';

function TransactionHistory() {
    return (
        <>
            <HeaderCustom />
            <main>
                <Breadcrumb />
                <TransactionList />
            </main>
            <Footer />
        </>
    )
}

export default TransactionHistory
