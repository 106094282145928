import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    managers: null
}

export const setManagers = (state) => async (dispatch) => {
    await dispatch(SET_MANAGERS({ state }))
}

export const managersSlice = createSlice({
    name: 'managers',
    initialState,
    reducers: {
        SET_MANAGERS: (state, action) => {
            state.managers = action.payload
        }
    }
})

export const {
    SET_MANAGERS
} = managersSlice.actions
export default managersSlice.reducer
