import { Triangle } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const UpgradeSucccess = () => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html: (<>
            <div className="row justify-content-center mb-20">
                <h3>Upgrade Success!</h3>
            </div>
        </>),
        timer: 3000,
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}
export const UpgradeFailure = () => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html: (<>
            <div className="row justify-content-center mb-20">
                <h5 style={{ color: 'red' }}>You lost item...</h5>
            </div>
        </>),
        icon: 'error',
        title: 'Oops...',
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}