import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { walletSelector, collectionsSelector } from '../../store/selectors'
import HeroStats from './HeroStats'
// Chain Services
import * as heroCollection from '../../chain/chain-service/heroCollection'
import * as heroManager from '../../chain/chain-service/heroManager'
// JSON Data
import race from '../../chain/enumarations/race.json'
import rarity from '../../chain/enumarations/rarity.json'
import HeroCard from '../../components/hero/HeroCard'


function HeroDetails() {
  const { wallet } = useSelector(walletSelector)
  const { collections } = useSelector(collectionsSelector)
  const navigate = useHistory()
  const [newHero, setNewHero] = useState([])

  const getHeroDetails = async () => {
    const tokenCount = await heroCollection.balanceOf(wallet.address)
    const tokenId = await heroCollection.tokenOfOwnerByIndex(
      wallet.address,
      tokenCount - 1
    )
    const hero = await heroManager.getHero(tokenId)
    setNewHero(hero)
  }
  useEffect(() => {
    if (wallet && collections) {
      getHeroDetails()
    }
  }, [wallet, collections])
  return (
    <section className="just-gamers-area just-gamers-bg pt-115 pb-120">
      <div className="container">
        <div className="row mb-50">
          <div className="col-lg-8 col-md-8 order-2 order-lg-2">
            <div className="section-title title-style-three white-title mb-70">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-3 order-3 order-lg-3  mb-30">
                  <a onClick={() => navigate.push('/hero-bucket-details')} className="active btn btn-style-two">MINT ANOTHER</a>
                </div>
                <div className="col-lg-3 col-md-3 order-3 order-lg-3  mb-30">
                  <a onClick={() => navigate.push('/select-hero')} className="btn btn-style-two">SELECT HERO</a>
                </div>
              </div>
              <h2><span>{rarity[newHero?.rarity]}</span></h2>
              <h2>{newHero?.name} <span>{race[newHero?.race]}</span></h2>
              <p>Compete with 100 player on a remote island for winner
                known issue where certain strategic</p>
            </div>
            <div className="just-gamers-list">
              <ul>
                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon01.webp" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Skill 1</h5>
                    <p>See how well critics rating new video game release with 100 players</p>
                  </div>
                </li>
                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon02.webp" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Skill 2</h5>
                    <p>Village and the subline of her own road, the Line Lane. Pityful a rethoric question</p>
                  </div>
                </li>
                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon03.webp" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Skill 3</h5>
                    <p>Village and the subline of her own road, the Line Lane. Pityful a rethoric question</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <HeroCard hero={newHero} handleClick={console.log} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8">
            <div className="section-title title-style-two text-center">
              <span>Stats with details</span>
              <h2>Hero <span>Stats</span></h2>
            </div>
          </div>
        </div>
        <HeroStats hero={newHero} />
      </div>

    </section>
  )
}

export default HeroDetails