import React, { useState, useEffect } from 'react'
import Slider from 'react-slick';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MonsterCard from '../../components/monster/MonsterCard';
import Prefight from '../../components/modals/Prefight';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { walletSelector, selectedHeroSelector, managersSelector, transactionHistorySelector, mastersSelector } from '../../store/selectors'
import { chainPicker } from '../../chain/helpers/chainInfo';
import { SET_SELECTED_HERO } from '../../store/modules/selectedHero';
import { SET_TRANSACTION_HISTORY } from '../../store/modules/transactionHistory';
import { SET_LATEST_TRANSACTION } from '../../store/modules/latestTransaction';
import * as transactionModals from '../../components/modals/Transactions.jsx'
// Chain Services
import * as gameMaster from '../../chain/chain-service/gameMaster'
import * as zoneManager from '../../chain/chain-service/zoneManager'
import * as heroManager from '../../chain/chain-service/heroManager'
import * as chainHelper from '../../chain/chain-service/helper'
import { parseHero } from '../../chain/helpers/parseChainData';
// JSON Data
import zones from '../../chain/data/zones.json';
import encounters from '../../chain/data/encounters.json';
import monsters from '../../chain/data/monsters.json'

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-right"></i></button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-left"></i></button>
  );
}
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  nav: true,
  autoplay: false,
  arrows: true,
  autoplaySpeed: 3000,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  slidesToShow: 4,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        Padding: '100px 50px',
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
  ]
}

function EncounterList() {

  const dispatch = useDispatch()
  const navigation = useHistory()
  const { wallet } = useSelector(walletSelector)
  const { managers } = useSelector(managersSelector)
  const { masters } = useSelector(mastersSelector)
  const { selectedHero } = useSelector(selectedHeroSelector)
  const { transactionHistory } = useSelector(transactionHistorySelector)
  const [encounterList, setEncounterList] = useState([])

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  }, []);


  const openPrefight = async (encounter) => {
    if (selectedHero?.id) {
      if (parseInt(encounter?.details?.requiredLevel) > parseInt(selectedHero?.level)) {
        transactionModals.TransactionError('Hero level too low!')
        return
      }

      const MySwal = withReactContent(Swal)
      MySwal.fire({
        html: (
          <Prefight selectedHero={selectedHero} encounter={encounter} />),
        showConfirmButton: true,
        confirmButtonText: 'Engage!',
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20',
        width: isMobile ? '95%' : '65%',
        confirmButtonColor: 'red',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-danger'
        },
        preConfirm: (res) => {
          return [
            document.getElementById("input1").value
          ];
        }
      }).then((result) => {
        if (result.isConfirmed) {
          sendHeroToEncounter(encounter, result.value[0].split(','))
        }
      })
    } else {
      transactionModals.TransactionError('Please Select Hero first', navigation)
    }
  }

  const sendHeroToEncounter = async (encounter, skills) => {
    const { sendEncounterFallBackGasAmount } = chainPicker(wallet.chainId)
    if (selectedHero?.id) {
      const isValidSkills = await heroManager.isValidSkills(selectedHero.id, skills)
      if (isValidSkills) {
        transactionModals.StartTransaction()
        let txGas = 0
        const gameMasterAddress = masters.gameMasterAddress
        if (parseInt(encounter?.details?.requiredLevel) > parseInt(selectedHero?.level)) {
          transactionModals.TransactionError('Hero level too low!')
          return
        }
        try {
          txGas = await gameMaster.estimateGasEncounter(wallet?.address, selectedHero.id, encounter.details.id, skills)
        } catch (err) {
          console.log(err)
          console.log('setting sendEncounterFallBackGasAmount', sendEncounterFallBackGasAmount)
          txGas = sendEncounterFallBackGasAmount
          if (chainHelper.calculateValue(encounter.details.staminaCost) > chainHelper.calculateStaminaValue(selectedHero)) {
            transactionModals.TransactionError('Not enough stamina!')
            return
          }
        }

        if (parseInt(txGas) > 30000000)
          txGas = 30000000
        await gameMaster.sendHeroToEncounter(gameMasterAddress, wallet.address, selectedHero.id, encounter.details.id, skills, txGas)
          .on('sending', async () => {
          })
          .on('transactionHash', async () => {
            transactionModals.TransactionSent()
          })
          .on('receipt', async (tx) => {
            const txEvents = chainHelper.getTxEvents(tx.events)
            const chainCombatResult = chainHelper.parseCombatResult(txEvents?.combatResultEvent)
            const chainRewards = chainHelper.parseTransferBatch(txEvents?.transferBatch)
            const roundResults = chainHelper.parseRoundResults(txEvents?.roundResult, encounter, selectedHero)
            const tempCombatResult = {
              _attacker: chainCombatResult?._attacker,
              aliveHeroCount: chainCombatResult?._combatResult?.aliveHeroCount,
              attackerWon: chainCombatResult?._combatResult?.attackerWon,
              experienceEarned: chainCombatResult?._combatResult?.experienceEarned,
              heroes: chainCombatResult?._combatResult?.heroes,
              isHeroAlive: chainCombatResult?._combatResult?.isHeroAlive,
              monsterDisappeared: chainCombatResult?._combatResult?.monsterDisappeared,
              monsterIsAlive: chainCombatResult?._combatResult?.monsterIsAlive,
            }
            const tempChainRewards = {
              to: chainRewards?.to,
              ids: chainRewards?.ids,
              values: chainRewards?.values
            }
            const txData = {
              blockHash: tx.blockHash,
              rawTx: tx,
              from: tx.from,
              type: 'sendHeroToEncounter',
              combatResults: tempCombatResult,
              rewards: tempChainRewards,
              roundResults: roundResults,
              encounter: encounter.details.id,
              hero: selectedHero.id,
              heroPoolId: selectedHero.poolId,
              time: Date.now()
            }
            dispatch(SET_LATEST_TRANSACTION(tx.blockHash))
            if (transactionHistory == null) {
              dispatch(SET_TRANSACTION_HISTORY([txData]))
            } else {
              dispatch(SET_TRANSACTION_HISTORY([...transactionHistory, txData]))
            }
            // Dummy workaround to update header
            dispatch(SET_SELECTED_HERO(parseHero(await heroManager.getHero(selectedHero.id))))
            await delay(500);
            navigation.push('/encounter-result')
          })
          .on('error', (err) => {
            transactionModals.TransactionError(err.message)
          })
      } else {
        transactionModals.TransactionError('Selected skills are not valid.')
      }
    } else {
      transactionModals.TransactionError('Please Select Hero first', navigation)
    }
  }

  const getMonsters = async () => {
    setEncounterList([])
    const zone = zones['1']
    zone?.bcData.getProp('encounters')?.map((encounterId, index) => {
      const tempEncounter = encounters[encounterId]
      const tempMonster = monsters[tempEncounter?.bcData?.getProp('monsterId')]
      const tempObj = {
        details: tempEncounter,
        monster: tempMonster
      }
      setEncounterList((prev) => [...prev, tempObj])
    })
  }

  useEffect(() => {
    if (managers) {
      getMonsters()
    }
  }, [managers])
  return (
    <div className="area-bg-zone-one">
      <section className="latest-games-area pt-120 pb-100">
        <div className="container pt-60">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="section-title mb-50">
                <span>EMERALD CANOPY</span>
                <h2>Encounter <span style={{ color: '#f02d2d' }}>List</span></h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {encounterList.length > 0 ? (
                <Slider className="latest-games-active slick" {...settings}>
                  {encounterList?.map((encounter, index) => (
                    <MonsterCard key={index} handleClick={openPrefight} encounter={encounter} index={index} isLocked={parseInt(encounter?.details?.bcData?.getProp('requiredLevel')) <= parseInt(selectedHero?.level) ? false : true} />
                  ))}
                </Slider>
              ) : ''}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default EncounterList;
