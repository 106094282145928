import { store } from '../../store'
import { chainPicker } from '../helpers/chainInfo'
import Web3 from 'web3'


// ABIS
import equipmentManagerABI from '../../chain/abis/abi_EquipmentManager.json'
import multicallABI from '../../chain/abis/abi_Multicall.json'
import gameMasterABI from "../../chain/abis/abi_GameMaster.json";

const equipmentStruct = equipmentManagerABI.find(o => o.name === 'getEquipment').outputs;

export const getEquipment = async (id) => {
  const equipmentManagerAddress = await store.getState().managers.managers.equipmentManagerAddress
  const web3 = new Web3(window.ethereum)
  const equipmentManagerContract = new web3.eth.Contract(
    equipmentManagerABI,
    equipmentManagerAddress
  )
  return await equipmentManagerContract.methods.getEquipment(id).call()
}

export const getEquipmentFallback = async (id, chainId) => {
  const { gameMasterAddress, fallbackRPC } = chainPicker(chainId)
  const web3 = new Web3(new Web3.providers.HttpProvider(fallbackRPC))
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  );
  const equipmentManagerAddress = await gameMasterContract.methods.equipmentManager().call();
  const equipmentManagerContract = new web3.eth.Contract(
    equipmentManagerABI,
    equipmentManagerAddress
  )
  return await equipmentManagerContract.methods.getEquipment(id).call()
}

export const getEquipmentFromPool = async (id) => {
  const equipmentManagerAddress = await store.getState().managers.managers.equipmentManagerAddress
  const web3 = new Web3(window.ethereum)
  const equipmentManagerContract = new web3.eth.Contract(
    equipmentManagerABI,
    equipmentManagerAddress
  )
  return await equipmentManagerContract.methods.getEquipmentFromPool(id).call()
}

export const getEquipmentOfHero = async (heroId, slot) => {
  const equipmentManagerAddress = await store.getState().managers.managers.equipmentManagerAddress
  const web3 = new Web3(window.ethereum)
  const equipmentManagerContract = new web3.eth.Contract(
    equipmentManagerABI,
    equipmentManagerAddress
  )
  return await equipmentManagerContract.methods.getEquipmentOfHero(heroId, slot).call()
}

export const getEquipmentOfHeroFallback = async (heroId, slot, chainId) => {
  const { gameMasterAddress, fallbackRPC } = chainPicker(chainId)
  const web3 = new Web3(new Web3.providers.HttpProvider(fallbackRPC))
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  );
  const equipmentManagerAddress = await gameMasterContract.methods.equipmentManager().call();
  const equipmentManagerContract = new web3.eth.Contract(
    equipmentManagerABI,
    equipmentManagerAddress
  )
  return await equipmentManagerContract.methods.getEquipmentOfHero(heroId, slot).call()
}


export const getEquipments = async (ids) => {
  const { multicallAddress } = chainPicker(store.getState().wallet.wallet.chainId)
  const equipmentManagerAddress = await store.getState().managers.managers.equipmentManagerAddress
  const web3 = new Web3(window.ethereum)
  const equipmentManagerContract = new web3.eth.Contract(
    equipmentManagerABI,
    equipmentManagerAddress
  )
  const multicallContract = new web3.eth.Contract(
    multicallABI,
    multicallAddress
  )

  const inputs = []

  for (let i = 0; i < ids.length; i++) {
    inputs.push({ target: equipmentManagerAddress, callData: equipmentManagerContract.methods.getEquipment(ids[i]).encodeABI() })
  }

  const result = await multicallContract.methods.aggregate(inputs).call()

  const equipments = []
  for (let i = 0; i < result.returnData.length; i++) {
    const equipment = web3.eth.abi.decodeParameters(equipmentStruct, result.returnData[i])
    equipments.push(equipment[0])
  }

  return equipments
}

export const getEquipmentsFromPool = async (ids) => {
  const { multicallAddress } = chainPicker(store.getState().wallet.wallet.chainId)
  const equipmentManagerAddress = await store.getState().managers.managers.equipmentManagerAddress
  const web3 = new Web3(window.ethereum)
  const equipmentManagerContract = new web3.eth.Contract(
    equipmentManagerABI,
    equipmentManagerAddress
  )
  const multicallContract = new web3.eth.Contract(
    multicallABI,
    multicallAddress
  )

  const inputs = []

  for (let i = 0; i < ids.length; i++) {
    inputs.push({ target: equipmentManagerAddress, callData: equipmentManagerContract.methods.getEquipmentFromPool(ids[i]).encodeABI() })
  }

  const result = await multicallContract.methods.aggregate(inputs).call()

  const equipments = []
  for (let i = 0; i < result.returnData.length; i++) {
    const equipment = web3.eth.abi.decodeParameters(equipmentStruct, result.returnData[i])
    equipments.push(equipment[0])
  }

  return equipments
}
