import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import HeroCard from '../hero/HeroCard'
// import { useHistory } from 'react-router-dom'

export const HeroMintResult = (hero, navigation) => {
    const MySwal = withReactContent(Swal)
    // const navigation = useHistory()

    const goToSelectHero = () =>{
        navigation.push('/select-hero')
        Swal.close()
    }

    MySwal.fire({
        html: (<>
            <div className="col-lg-12">
                <h4>New Hero! </h4>
                <div className="row" >
                    <div className="col-lg-2">
                    </div>
                    <div className='col-lg-8'>
                        <HeroCard hero={hero} handleClick={console.log} />
                        <a onClick={() => goToSelectHero()} className="btn btn-style-two">Select Hero</a>
                    </div>
                    <div className="col-lg-2">
                    </div>
                </div>
            </div>
        </>),
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}