import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    heroBucket: null
}

export const setHeroBucket = (state) => async (dispatch) => {
    await dispatch(SET_HERO_BUCKET(state))
}

export const heroBucketSlice = createSlice({
    name: 'heroBucket',
    initialState,
    reducers: {
        SET_HERO_BUCKET: (state, action) => {
            state.heroBucket = action.payload
        }
    }
})

export const { SET_HERO_BUCKET } = heroBucketSlice.actions
export default heroBucketSlice.reducer
