import localHeroes from '../data/poolHeroes.json'

export const parseHero = (hero) => {
    const localHero = localHeroes[hero?.poolId]
    const tempHero = {
        id: hero.id,
        name: localHero.name,
        level: hero.level,
        poolId: hero.poolId,
        race: hero.race,
        rarity: hero.rarity,
        staminaLastValue: hero.staminaLastValue,
        staminaUpdateTimestamp: hero.staminaUpdateTimestamp,
        stats: hero.stats,
        characterClass: hero.characterClass,
        exists: hero.exists,
        activeSkills: hero.activeSkills,
        experience: hero.experience
    }
    return tempHero
}

export const getRarityColor = (data) => {
    if (data?.getProp('rarity') == "0") {
        return { backgroundColor: 'rgba(52, 58, 64, 0.1)' }
    }
    if (data?.getProp('rarity') == "1") {
        return { backgroundColor: 'rgba(13, 100, 0, 0.1)' }
    }
    if (data?.getProp('rarity') == "2") {
        return { backgroundColor: 'rgba(0, 68, 229, 0.2)' }
    }
    if (data?.getProp('rarity') == "3") {
        return { backgroundColor: 'rgba(66, 1, 98, 0.1)' }
    }
    if (data?.getProp('rarity') == "4") {
        return { backgroundColor: 'rgba(255, 113, 0, 0.16)' }
    }
    if (data?.getProp('rarity') == "5") {
        return { backgroundColor: 'rgba(250, 1, 1, 0.12)' }
    }
}
export const getRarityTextColor = (data) => {
    if (data?.getProp('rarity') == "0") {
        return { color: 'rgba(166, 172, 178, 1)' }
    }
    if (data?.getProp('rarity') == "1") {
        return { color: 'rgba(27, 208, 0, 1)' }
    }
    if (data?.getProp('rarity') == "2") {
        return { color: 'rgba(0, 118, 255, 1)' }
    }
    if (data?.getProp('rarity') == "3") {
        return { color: '#891fbd' }
    }
    if (data?.getProp('rarity') == "4") {
        return { color: 'rgba(255, 113, 0, 1)' }
    }
    if (data?.getProp('rarity') == "5") {
        return { color: 'rgba(250, 1, 1, 1)' }
    }
}

export const getRarityBorderColor = (data) => {
    if (data?.getProp('rarity') == "0") {
        return { borderColor: 'rgba(52, 58, 64, 0.43)' }
    }
    if (data?.getProp('rarity') == "1") {
        return { borderColor: '#46ff2b8f' }
    }
    if (data?.getProp('rarity') == "2") {
        return { borderColor: 'rgba(0, 68, 229, 0.43)' }
    }
    if (data?.getProp('rarity') == "3") {
        return { borderColor: 'rgba(66, 1, 98, 0.49)' }
    }
    if (data?.getProp('rarity') == "4") {
        return { borderColor: 'rgba(255, 113, 0, 0.46)' }
    }
    if (data?.getProp('rarity') == "5") {
        return { borderColor: 'rgba(250, 1, 1, 0.32)' }
    }
}
