import React from 'react';
import Slider from 'react-slick';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import { useHistory } from 'react-router-dom';



function SliderMain() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    center: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  }

  const navigation = useHistory()
  // useEffect(()=>{
  // new WOW.WOW({
  //   boxClass: 'wow',
  // 	animateClass: 'animated',
  // 	offset: 0,
  // 	mobile: false,
  // 	live: true,
  //   loop:Infinity,
  // }).init();

  // },[])

  return (


    <section className="slider-area">
      <Slider className="slider-active" {...settings}>
        <div className="single-slider slider-bg slider-style-two">
          <div className="container-fluid container-full-padding">
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-11">
                <div className="slider-content ">
                  <Fade up delay={.5000}>
                    <h2 >Guild <span>of</span><br /> Chains</h2>
                  </Fade>
                  <Fade up delay={.5000}>
                    <br />
                    <p>On-chain RPG game with AI-Generated Assets and Storylines.</p>
                  </Fade >
                  <div className='row'>
                    <div className='col-lg-2 pt-10'>
                      <Fade up delay={.8000}>
                        <a onClick={() => navigation.push('/select-hero-bucket')} className="btn btn-info">PLAY NOW!</a>
                      </Fade>
                    </div>
                    <div className='col-lg-2 pt-10'>
                      <div className='pl-10'>
                        <Fade up delay={.8000}>
                          <a target='_blank' href='https://rpc.guildofchains.com/faucet' className="btn btn-info">FAUCET</a>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Slide right delay={.4000}>
            <div className="slider-img"><img src="assets/img/goc/breadcrumb/hero1.png" alt="" /></div>
          </Slide>
          <Slide left delay={.4000}>
            <div className="slider-img slider-img2 wow slideInRightS"><img src="assets/img/goc/breadcrumb/hero2.png" alt="" /></div>
          </Slide>
          {/* <div className="slider-circle-shape"><img src="assets/img/slider/slider_circle.webp" alt="" className="rotateme" /></div> */}
        </div>
      </Slider>
    </section >
  )
}

export default SliderMain