
import React from 'react';
import CountUp from "react-countup";
import { store } from '../../store'
import { getRarityTextColor } from '../../chain/helpers/parseChainData';
import { statImg } from '../../store/image-service';
import * as chainHelper from '../../chain/chain-service/helper'
import ClipboardCopy from '../hero/ClipboradCopy';
// JSON Data
import stats from '../../chain/enumarations/stats.json'
import equipments from '../../chain/data/equipmentPool.json'


function EquipmentStats({ equipment, chainId }) {
    const poolEquipment = equipments[equipment.poolId]
    return (<div className="fact-area">
        <h4 style={getRarityTextColor(equipment)} className='mb-30'>{poolEquipment?.name} <span style={{ color: '#2DABF0' }}>(+{equipment?.upgradeLevel})</span></h4>
        <div className="row justify-content-center">
            {equipment?.flatModifiers?.map((stat, index) => (
                <>
                    {chainHelper.calculateStatValue(stat) || chainHelper.calculateStatValueArray(equipment?.percentageModifiers[index]) > 0 ? (
                        <div key={index} className="col-lg-6 col-md-6 col-sm-6">
                            <div className="fact-item">
                                <div className="fact-icon">
                                    <img src={statImg(stats[stat.statId])} alt="" />
                                </div>
                                <div className="counter-item">
                                    {chainHelper.calculateStatValue(stat) > 0 ? (
                                        <h2 style={{ color: 'green' }} className="counter">+<span>< CountUp end={chainHelper.calculateStatValue(stat)} /></span></h2>
                                    ) : ''}
                                    {chainHelper.calculateStatValue(equipment?.percentageModifiers[index]) > 0 ? (
                                        <h2 style={{ color: 'green' }} className="counter">+<span>< CountUp end={chainHelper.calculateStatValue(equipment?.percentageModifiers[index])} />%</span></h2>
                                    ) : ''}
                                    <span style={{ color: 'white' }}>{stats[stat.statId].slice(0, 7)}</span>
                                </div>
                            </div>
                        </div>
                    ) : ''
                    }
                </>
            ))}

            <div className='col-12 pt-20'>
                <ClipboardCopy copyText={chainId ? "https://guildofchains.com/equipment?chainId=" + chainId + "&id=" + equipment.id : "https://guildofchains.com/equipment?chainId=" + store.getState().wallet.wallet.chainId + "&id=" + equipment.id} />
            </div>
        </div>
    </div>)
}

export default EquipmentStats