import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { materialImg } from '../../store/image-service'
import { walletSelector } from '../../store/selectors'
import * as premiumStoreModals from '../../components/modals/PremiumStore.jsx'
import * as transactionModals from '../../components/modals/Transactions.jsx'
// Chain Services
import * as gameMaster from '../../chain/chain-service/gameMaster'
import * as storeManager from '../../chain/chain-service/storeManager'
import * as materialCollection from '../../chain/chain-service/materialCollection'
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import materials from '../../chain/data/materials.json'

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function StoreList() {
  const { wallet } = useSelector(walletSelector)
  const [storeItems, setStoreItems] = useState([])
  const [itemAmounts, setItemAmounts] = useState({})

  const getStoreItems = async () => {
    setStoreItems([])
    const tempStoreItems = await storeManager.getStoreItems()
    await getPlayerStoreItemBalance(tempStoreItems)
  }


  // @Bora bunu tek seferde nasil cekeriz? butun materialIdleri tek seferde gonderebiliyoruz
  const getPlayerStoreItemBalance = async (tempStoreItems) => {
    setStoreItems([])
    tempStoreItems.map(async (details, index) => {
      const playerBalance = await materialCollection.balanceOfBatch(
        [wallet.address],
        [details.materialId]
      )
      itemAmounts[details.id] = 1
      setStoreItems((prev) => [...prev, { id: parseInt(details.id), details, playerBalance }])
      return true
    })
  }

  const setItemAmount = async (amount, storeItem) => {
    itemAmounts[storeItem.details.id] = parseInt(amount)
    setItemAmounts(itemAmounts)
  }

  const buyStoreItem = async (storeItem) => {
    const cost = parseInt(storeItem?.details?.cost) * itemAmounts[storeItem.details.id]

    const [txGas, gameMasterContract] = await gameMaster.estimateGasBuyStoreItem(
      wallet.address,
      storeItem.details.id,
      itemAmounts[storeItem.details.id],
      cost
    )
    await gameMaster
      .buyStoreItem(gameMasterContract, wallet.address, storeItem.details.id, itemAmounts[storeItem.details.id], cost, txGas)
      .on('sending', async () => {
        transactionModals.StartTransaction()
      })
      .on('transactionHash', async () => {
        transactionModals.TransactionSent()
      })
      .on('receipt', async () => {
        premiumStoreModals.BuySuccess(storeItem, itemAmounts[storeItem.details.id])
        await delay(500)
        await getStoreItems()
      })
      .on('error', (err) => {
        transactionModals.TransactionError(err.message)
      })
  }

  useEffect(() => {
    if(wallet)
    getStoreItems()
  }, [wallet])
  return (
    <section className="latest-match-area premium-market-bg pt-115 pb-90">
      <div className="container pt-60">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three white-title text-center mb-70">
              <h2> <span style={{ color: 'gold' }}>Premium</span> Store</h2>
              <p>Get your items from store long text required lorem ipsum dolar sit amet here store  </p>
            </div>
          </div>
        </div>
        <div className="row">
          {chainHelper.sortBy(storeItems, { prop: 'id' })?.map((storeItem, index) => {
            return (
              <div key={index} className="col-lg-4">
                <div className="latest-match-box mb-30">
                  <div className="latest-match-thumb">
                    <div className="just-gamers-list-icon">
                      <img src={materialImg(storeItem?.details?.materialId)} alt="" />
                    </div>
                    <p><a>Balance :<span> <b>{storeItem?.playerBalance?.[0]}</b></span></a></p>
                  </div>
                  <div className="tournament-schedule-content">
                    <h3><a><span style={{ color: 'gold' }}> {materials[storeItem?.details?.materialId]?.name}</span></a></h3>
                    <p><a>Price :<span style={{ color: '#2DABF0' }}> 0 </span></a></p>
                    {/* {storeItem?.details?.cost} */}
                    <p></p>
                    <div className="tournament-schedule-meta">
                      <h5>
                        <input type="text" onChange={e => setItemAmount(e.target.value, storeItem)} placeholder='1' size={5} pattern="[0-9]*" />
                      </h5>
                      <a className='button-hover-custom' onClick={() => { buyStoreItem(storeItem) }}>Purchase</a>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default StoreList