import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { walletSelector, collectionsSelector } from '../../store/selectors'
import HeroStats from './EquipmentStats'
// Chain Services
import * as equipmentCollection from '../../chain/chain-service/equipmentCollection'
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
// JSON Data
import rarity from '../../chain/enumarations/rarity.json'
import subTypes from '../../chain/enumarations/subTypes.json'
import EquipmentCard from '../../components/equipment/EquipmentCard'

function EquipmentDetails() {
  const { wallet } = useSelector(walletSelector)
  const { collections } = useSelector(collectionsSelector)
  const navigate = useHistory()
  const [newEquipment, setNewEquipment] = useState([])

  const getEquipmentDetails = async () => {
    const tokenCount = await equipmentCollection.balanceOf(wallet.address)
    const tokenId = await equipmentCollection.tokenOfOwnerByIndex(
      wallet.address,
      tokenCount - 1
    )
    const equipment = await equipmentManager.getEquipment(tokenId)
    console.log(equipment)
    setNewEquipment(equipment)
  }
  useEffect(() => {
    if (wallet && collections) {
      getEquipmentDetails()
    }
  }, [wallet, collections])
  return (
    <section className="area-bg-one pt-115 pb-120">
      <div className="container">
        <div className="row mb-50">
          <div className="col-lg-8 col-md-8 order-2 order-lg-2">
            <div className="section-title title-style-three white-title mb-70">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-3 order-3 order-lg-3  mb-30">
                  <a onClick={() => navigate.push('/crafting-bucket-details')} className="active btn btn-style-two">CRAFT ANOTHER</a>
                </div>
                <div className="col-lg-3 col-md-3 order-3 order-lg-3  mb-30">
                  <a onClick={() => navigate.push('/inventory')} className="btn btn-style-two">INVENTORY</a>
                </div>
              </div>
              <h2><span>{rarity[newEquipment?.rarity]}</span></h2>
              <h2>{newEquipment?.name} <span>{subTypes[newEquipment?.subtypeId]?.name}</span></h2>
              <p>Compete with 100 player on a remote island for winner
                known issue where certain strategic</p>
            </div>
            <div className="just-gamers-list">
              <HeroStats equipment={newEquipment} />
            </div>
          </div>
          <div className="col-lg-4">
            <EquipmentCard equipment={newEquipment} handleClick={console.log} />
          </div>
        </div>
      </div>

    </section>
  )
}

export default EquipmentDetails