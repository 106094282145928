import React from 'react';
import HeaderCustom from '../../components/header/HeaderCustom';
import Footer from '../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import ResultDetails from './ResultDetails';

function EncounterResult() {
    return (
        <>
            <HeaderCustom />
            <main>
                <ResultDetails />
            </main>
            <Footer />
        </>
    )
}

export default EncounterResult
