import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { walletSelector, collectionsSelector } from '../../store/selectors'
import HeroCard from '../../components/hero/HeroCard'
// Store
import {
  SET_SELECTED_HERO
} from '../../store/modules/selectedHero'
// Chain Services
import * as heroCollection from '../../chain/chain-service/heroCollection'
import * as heroManager from '../../chain/chain-service/heroManager'
import * as chainHelper from '../../chain/chain-service/helper'
import { getRarityTextColor, parseHero } from '../../chain/helpers/parseChainData'

function HeroList() {
  const { wallet } = useSelector(walletSelector)
  const { collections } = useSelector(collectionsSelector)
  const dispatch = useDispatch()
  const navigate = useHistory()
  const [selectedRarity, setSelectedRarity] = useState('')
  const [heroList, setHeroList] = useState([])
  const [filterHeroList, setFilterHeroList] = useState([])

  const getHeroList = async () => {
    setHeroList([])
    setFilterHeroList([])
    setSelectedRarity('')
    const heroIds = await heroCollection.tokensOfOwner(wallet.address);
    const heroList = await heroManager.getHeroes(heroIds.returnData)
    console.log(heroList)
    setHeroList(heroList)
    setFilterHeroList(heroList)
  }

  const resetFilter = async () => {
    setSelectedRarity('')
    setHeroList(filterHeroList)
  }

  const setSelectedHero = async (hero) => {
    const tempHero = {
      id: hero.id,
      name: hero.name,
      level: hero.level,
      poolId: hero.poolId,
      race: hero.race,
      rarity: hero.rarity,
      staminaLastValue: hero.staminaLastValue,
      staminaUpdateTimestamp: hero.staminaUpdateTimestamp,
      stats: hero.stats,
      characterClass: hero.characterClass,
      exists: hero.exists,
      activeSkills: hero.activeSkills,
      experience: hero.experience
    }
    dispatch(SET_SELECTED_HERO(parseHero(tempHero)))
    navigate.push('/inventory')
  }

  const filterHeroRarity = (categItem) => {
    setSelectedRarity(categItem)
    const updatedHeroes = filterHeroList.filter((curElem) => {
      return curElem?.rarity === categItem;
    })
    setHeroList(updatedHeroes);
  }

  useEffect(() => {
    if (wallet && collections) {
      getHeroList()
    }
  }, [wallet, collections])
  return (
    <section className="game-manage-area game-mange-bg position-relative pt-115 pb-90">
      <div className="featured-game-bg" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-two text-center mb-60">
              <span>Guild Hall</span>
              <h2>Select New <span>Hero</span></h2>
            </div>
          </div>
        </div>
        <div className="row mb-40">
          <div className="col-12">
            <div className="tournament-top-wrap">
              <div className="section-title tournament-title">
                <h2>Rarity <span>Filter</span></h2>
              </div>
              <div className="tournament-menu">
                <button className={selectedRarity == '' ? 'active' : ''} data-filter="*" onClick={() => resetFilter()}>All</button>
                <button style={getRarityTextColor({ rarity: '0' })} className={selectedRarity == '0' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterHeroRarity("0")}>COMMON</button>
                <button style={getRarityTextColor({ rarity: '1' })} className={selectedRarity == '1' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterHeroRarity("1")}>UNCOMMON</button>
                <button style={getRarityTextColor({ rarity: '2' })} className={selectedRarity == '2' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterHeroRarity("2")}>RARE</button>
                <button style={getRarityTextColor({ rarity: '3' })} className={selectedRarity == '3' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterHeroRarity("3")}>EPIC</button>
                <button style={getRarityTextColor({ rarity: '4' })} className={selectedRarity == '4' ? 'active' : ''} data-filter=".cat-two" onClick={() => filterHeroRarity("4")}>LEGENDARY</button>
                <button style={getRarityTextColor({ rarity: '5' })} className={selectedRarity == '5' ? 'active' : ''} data-filter=".cat-one" onClick={() => filterHeroRarity("5")}>MYTHIC</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row featured-active">
          {chainHelper.sortBy(heroList, { prop: 'level', desc: true })?.map((hero, index) => (
            <div key={index} className="col-lg-3 col-6 grid-item">
              <HeroCard hero={hero} handleClick={setSelectedHero} isShareable={true} />
            </div>
          ))}


        </div>
        <div className="row justify-content-center">
          {heroList && heroList.length == 0 ? (
            <a onClick={() => {
              navigate.push('/select-hero-bucket')
            }} className="btn btn-style-two">Mint New Hero</a>
          ) : ''}
        </div>
      </div>
    </section>
  )
}

export default HeroList