import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    latestTransaction: null
}

export const setLatestTransaction = (state) => async (dispatch) => {
    await dispatch(SET_LATEST_TRANSACTION(state))
}

export const latestTransactionSlice = createSlice({
    name: 'latestTransaction',
    initialState,
    reducers: {
        SET_LATEST_TRANSACTION: (state, action) => {
            state.latestTransaction = action.payload
        }
    }
})

export const { SET_LATEST_TRANSACTION } = latestTransactionSlice.actions
export default latestTransactionSlice.reducer
