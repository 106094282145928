
import React from 'react';
import CountUp from "react-countup";
import * as chainHelper from '../../chain/chain-service/helper'
import { store } from '../../store'
// JSON Data
import stats from '../../chain/enumarations/stats.json'
import { getRarityTextColor } from '../../chain/helpers/parseChainData';
import { statImg } from '../../store/image-service';
import ClipboardCopy from '../hero/ClipboradCopy';

function PoolEquipmentStats({ equipment, isShareable, chainId }) {

    return (<div className="fact-area">
        <h2 style={getRarityTextColor(equipment.bcData)} className='mb-30'>{equipment?.name} <span style={{ color: '#2DABF0' }}>(+{equipment?.bcData?.upgradeLevel})</span></h2>
        <div className="row justify-content-center">
            {equipment?.bcData?.flatModifiers?.map((stat, index) => (
                <>
                    {chainHelper.calculateStatValue(stat) || chainHelper.calculateStatValueArray(equipment?.bcData?.percentageModifiers[index]) > 0 ? (
                        <div key={index} className="col-lg-6 col-md-6 col-sm-6">
                            <div className="fact-item">
                                <div className="fact-icon">
                                    <img src={statImg(stats[stat.statId])} alt="" />
                                </div>
                                <div className="counter-item">
                                    {chainHelper.calculateStatValue(stat) > 0 ? (
                                        <h2 style={{ color: 'green' }} className="counter">+<span>< CountUp end={chainHelper.calculateStatValue(stat)} /></span></h2>
                                    ) : ''}
                                    {chainHelper.calculateStatValue(equipment?.bcData?.percentageModifiers[index]) > 0 ? (
                                        <h2 style={{ color: 'green' }} className="counter">+<span>< CountUp end={chainHelper.calculateStatValue(equipment?.bcData?.percentageModifiers[index])} />%</span></h2>
                                    ) : ''}
                                    <span style={{ color: 'white' }}>{stats[stat.statId].slice(0, 7)}</span>
                                </div>
                            </div>
                        </div>
                    ) : ''
                    }
                </>
            ))}
            {isShareable ? (
                <div className='col-12 pt-20'>
                    <ClipboardCopy copyText={chainId ? "https://guildofchains.com/equipment?chainId=" + chainId + "&id=" + equipment.id : "https://guildofchains.com/equipment?chainId=" + store.getState().wallet.wallet.chainId + "&id=" + equipment.id} />
                </div>) : ''}
        </div>
    </div>)
}

export default PoolEquipmentStats