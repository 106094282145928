import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EquipmentCard from '../equipment/EquipmentCard'

export const CraftingResult = (equipment) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html: (<>
            <div className="col-lg-12">
                <h4>New Equipment! </h4>
                <div className="row" >
                    <div className='col-lg-2'>

                    </div>
                    <div className='col-lg-8'>
                        <EquipmentCard equipment={equipment} handleClick={console.log} />
                    </div>
                    <div className='col-lg-2'>

                    </div>
                </div>
            </div>
        </>),
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}