import * as chainHelper from '../../chain/chain-service/helper'
import { classImg, heroImg } from '../../store/image-service'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import HeroStats from './HeroStats'
import ImageLoader from '../imageloader/ImageLoader'
// Chain Services
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import { getRarityColor, getRarityTextColor } from '../../chain/helpers/parseChainData'
import * as statParser from '../../chain/helpers/parseStats'
// JSON Data
import characterClasses from '../../chain/data/characterClasses.json'
import localHeroes from '../../chain/data/poolHeroes.json'


function HeroCard({ hero, handleClick, index, chainId }) {
    const [heroEquipments, setHeroEquipments] = useState([])
    const poolHero = localHeroes[hero.poolId]
    const MySwal = withReactContent(Swal)
    const openStats = (hero) => {
        MySwal.fire({
            html: (
                <HeroStats hero={hero} chainId={chainId} />
            ),
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            background: '#1a1b20'
        })
    }
    const getEquipmentOfHero = async (hero) => {
        setHeroEquipments([])
        const arr = [0, 1, 2, 3, 4, 5]
        arr.map(async (slotId) => {
            const equipmentId = await equipmentManager.getEquipmentOfHero(
                hero.id,
                slotId
            )
            if (equipmentId > 0) {
                const equipment = await equipmentManager.getEquipment(equipmentId)
                setHeroEquipments((prev) => [...prev, { slotId, equipment }])
            }
        })
    }
    useEffect(() => {
        if (hero.id)
            getEquipmentOfHero(hero)
    }, [hero])
    return (
        <div index={index} className="latest-games-items mb-30">
            <div className="latest-games-thumb">
                <div className="hp-tag">
                    <a> ❤️ {statParser.getHealtPoints(hero?.stats)}</a>
                </div>
                <div className="stamina-tag">
                    <a> ⚡ {statParser.getStamina(hero?.stats)}</a>
                </div>
                <div className="level-tag">
                    <a> lv {hero?.level}</a>
                </div>
                <div className="class-tag">
                    <img src={classImg(characterClasses[hero.characterClass]?.name)} />
                </div>
                <div className="hero-card-equipment-slot-1-tag">
                    <a style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 0)?.equipment)}> {
                        heroEquipments?.find(o => o.slotId === 0)?.equipment ? '◈' : ''
                    }</a>
                </div>
                <div className="hero-card-equipment-slot-2-tag">
                    <a style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 1)?.equipment)}> {
                        heroEquipments?.find(o => o.slotId === 1)?.equipment ? '◈' : ''
                    }</a>
                </div>
                <div className="hero-card-equipment-slot-3-tag">
                    <a style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 2)?.equipment)}> {
                        heroEquipments?.find(o => o.slotId === 2)?.equipment ? '◈' : ''
                    }</a>
                </div>
                <div className="hero-card-equipment-slot-4-tag">
                    <a style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 3)?.equipment)}> {
                        heroEquipments?.find(o => o.slotId === 3)?.equipment ? '◈' : ''
                    }</a>
                </div>
                <div className="hero-card-equipment-slot-5-tag">
                    <a style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 4)?.equipment)}> {
                        heroEquipments?.find(o => o.slotId === 4)?.equipment ? '◈' : ''
                    }</a>
                </div>
                <div className="hero-card-equipment-slot-6-tag">
                    <a style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 5)?.equipment)}> {
                        heroEquipments?.find(o => o.slotId === 5)?.equipment ? '◈' : ''
                    }</a>
                </div>
                <a onClick={() => handleClick(hero)}>  <ImageLoader source={heroImg(hero?.poolId)} alt="" /></a>
            </div>
            <div style={getRarityColor(hero)} className="latest-games-content">
                <div className="stat-tag">
                    <a onClick={() => openStats(hero)} className="btn transparent-btn" style={{ color: 'white' }}> <i className="fas fa-info" /> info</a>
                </div>
                <h6><a style={getRarityTextColor(hero)}>{poolHero?.name} <span></span></a></h6>
            </div>
        </div>
    )
}

export default HeroCard