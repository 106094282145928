import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { walletSelector, collectionsSelector, selectedHeroSelector, mastersSelector } from '../../store/selectors'
import { equipmentSubtypeImg } from '../../store/image-service'
import * as transactionModals from '../../components/modals/Transactions.jsx'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EquipmentCard from '../../components/equipment/EquipmentCard'
import HeroCard from '../../components/hero/HeroCard'
import EquipmentStats from '../../components/equipment/EquipmentStats'
import EquipmentBasicStats from '../../components/equipment/EquipmentBasicStats'
// Store
import {
    SET_SELECTED_HERO
} from '../../store/modules/selectedHero'
// Chain Services
import * as equipmentCollection from '../../chain/chain-service/equipmentCollection'
import * as heroCollection from '../../chain/chain-service/heroCollection'
import * as equipmentManager from '../../chain/chain-service/equipmentManager'
import * as gameMaster from '../../chain/chain-service/gameMaster'
import { getRarityBorderColor, getRarityColor, parseHero } from '../../chain/helpers/parseChainData'
import * as chainHelper from '../../chain/chain-service/helper'
import * as heroManager from '../../chain/chain-service/heroManager'
// JSON Data
import slotName from '../../chain/enumarations/slotName.json'
import { getRarityTextColor } from '../../chain/helpers/parseChainData'



function HeroDetail() {
    const [heroEquipments, setHeroEquipments] = useState([])
    const MySwal = withReactContent(Swal)

    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const [selectedHero, setSelectedHero] = useState([])
    const [owner, setOwner] = useState([])

    const getHeroDetails = async () => {
        const heroId = searchParams.get('id')
        const hero = await heroManager.getHeroFallback(heroId, searchParams.get('chainId'))
        const tempOwner = await heroCollection.ownerOfFallback(heroId, searchParams.get('chainId'))
        setOwner(tempOwner)
        setSelectedHero(hero)
    }

    const openStats = (equipment) => {
        if (equipment) {
            MySwal.fire({
                html: (
                    <div className="pt-50">
                        <EquipmentStats equipment={equipment} />
                    </div>
                ),
                showConfirmButton: false,
                showCancelButton: true,
                showCloseButton: true,
                background: '#1a1b20'
            })
        }
    }

    const getEquipmentOfHero = async () => {
        setHeroEquipments([])
        const arr = [0, 1, 2, 3, 4, 5]
        arr.map(async (slotId) => {
            const equipmentId = await equipmentManager.getEquipmentOfHeroFallback(
                selectedHero.id,
                slotId,
                searchParams.get('chainId')
            )
            if (equipmentId > 0) {
                const equipment = await equipmentManager.getEquipmentFallback(equipmentId, searchParams.get('chainId'))
                setHeroEquipments((prev) => [...prev, { slotId, equipment }])
            }
        })
    }

    useEffect(() => {
        if (selectedHero) {
            getEquipmentOfHero()
        }
    }, [selectedHero])
    useEffect(() => {
        getHeroDetails()
    }, [])
    return (
        <>
            {selectedHero?.id ? (<>
                <section className="latest-match-area latest-match-bg pt-115 pb-10">
                    <div className="container pt-50">
                        <div className="row justify-content-center pb-20">
                            <div className="col-xl-7 col-lg-8">
                                <div className="section-title title-style-two text-center">
                                    <h2>Hero Id: <span>{selectedHero?.id}</span></h2>
                                    <h6>Owner: <span style={{ color: 'red' }}>{owner}</span></h6>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-12 mb-50">
                                <div className="just-gamers-list">
                                    <ul>
                                        <li>
                                            <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 0)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 0)?.equipment)} className="inventory-item-icon-right">
                                                <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 0)?.equipment.poolId ? equipmentSubtypeImg(heroEquipments?.find(o => o.slotId === 0)?.equipment.subtypeId) : 'assets/img/goc/icon/Weapons_icon.webp'} alt="" />
                                            </div>
                                            <div className="just-gamers-list-content fix">
                                                <h5>{slotName["0"]}</h5>
                                                <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 0)?.equipment)}>{heroEquipments?.find(o => o.slotId === 0)?.equipment?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 0)?.equipment?.upgradeLevel})</span></h6>
                                                <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 0)?.equipment} />
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 5)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 5)?.equipment)} className="inventory-item-icon-right">
                                                <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 5)?.equipment.poolId ? equipmentSubtypeImg(heroEquipments?.find(o => o.slotId === 5)?.equipment.subtypeId) : 'assets/img/goc/icon/Gem_icon.webp'} alt="" />
                                            </div>
                                            <div className="just-gamers-list-content fix">
                                                <h5>{slotName["5"]}</h5>
                                                <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 5)?.equipment)}>{heroEquipments?.find(o => o.slotId === 5)?.equipment?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 5)?.equipment?.upgradeLevel})</span></h6>
                                                <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 5)?.equipment} />
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 4)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 4)?.equipment)} className="inventory-item-icon-right">
                                                <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 4)?.equipment.poolId ? equipmentSubtypeImg(heroEquipments?.find(o => o.slotId === 4)?.equipment.subtypeId) : 'assets/img/goc/icon/Bot_icon.webp'} alt="" />
                                            </div>
                                            <div className="just-gamers-list-content fix">
                                                <h5>{slotName["4"]}</h5>
                                                <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 4)?.equipment)}>{heroEquipments?.find(o => o.slotId === 4)?.equipment?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 4)?.equipment?.upgradeLevel})</span></h6>
                                                <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 4)?.equipment} />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 mb-50">
                                <HeroCard hero={selectedHero} handleClick={console.log} chainId={searchParams.get('chainId')} isShareable={true} />
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 mb-50">
                                <div className="just-gamers-list">
                                    <ul>
                                        <li>
                                            <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 1)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 1)?.equipment)} className="inventory-item-icon">
                                                <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 1)?.equipment.poolId ? equipmentSubtypeImg(heroEquipments?.find(o => o.slotId === 1)?.equipment.subtypeId) : 'assets/img/goc/icon/Helmet_icon.webp'} alt="" />
                                            </div>
                                            <div className="just-gamers-list-content fix">
                                                <h5>{slotName["1"]}</h5>
                                                <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 1)?.equipment)}>{heroEquipments?.find(o => o.slotId === 1)?.equipment?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 1)?.equipment?.upgradeLevel})</span></h6>
                                                <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 1)?.equipment} />
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 2)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 2)?.equipment)} className="inventory-item-icon">
                                                <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 2)?.equipment.poolId ? equipmentSubtypeImg(heroEquipments?.find(o => o.slotId === 2)?.equipment.subtypeId) : 'assets/img/goc/icon/Top_Armor_icon.webp'} alt="" />
                                            </div>
                                            <div className="just-gamers-list-content fix">
                                                <h5>{slotName["2"]}</h5>
                                                <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 2)?.equipment)}>{heroEquipments?.find(o => o.slotId === 2)?.equipment?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 2)?.equipment?.upgradeLevel})</span></h6>
                                                <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 2)?.equipment} />
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => openStats(heroEquipments?.find(o => o.slotId === 3)?.equipment)} style={getRarityBorderColor(heroEquipments?.find(o => o.slotId === 3)?.equipment)} className="inventory-item-icon">
                                                <img style={{ maxWidth: '100px', maxHeight: '173px' }} src={heroEquipments?.find(o => o.slotId === 3)?.equipment.poolId ? equipmentSubtypeImg(heroEquipments?.find(o => o.slotId === 3)?.equipment.subtypeId) : 'assets/img/goc/icon/Hand_icon.webp  '} alt="" />
                                            </div>
                                            <div className="just-gamers-list-content fix">
                                                <h5>{slotName["3"]}</h5>
                                                <h6 style={getRarityTextColor(heroEquipments?.find(o => o.slotId === 3)?.equipment)}>{heroEquipments?.find(o => o.slotId === 3)?.equipment?.name} <span style={{ color: '#2DABF0' }}> (+{heroEquipments?.find(o => o.slotId === 3)?.equipment?.upgradeLevel})</span></h6>
                                                <EquipmentBasicStats equipment={heroEquipments?.find(o => o.slotId === 3)?.equipment} />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            ) : (
                <section className="latest-match-area latest-match-bg pt-200 pb-200">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-8">
                                <div className="section-title title-style-two text-center pt-60">
                                    Hero not found!
                                </div>
                            </div>
                        </div></div>
                </section>
            )
            }
        </>
    )
}

export default HeroDetail