import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { walletSelector, managersSelector } from '../../store/selectors'
import { useHistory } from 'react-router-dom'
import { materialImg, crafterImg, zoneBackgroundImg } from '../../store/image-service'
// Chain Services
import * as materialCollection from '../../chain/chain-service/materialCollection'
import { SET_CRAFTING_BUCKET } from '../../store/modules/craftingBucket'
// JSON Data
import craftingBucketsJson from '../../chain/data/craftingBuckets.json'
import materialData from '../../chain/data/materials.json'
import { Fade } from 'react-reveal'
const craftingBucketsData = Object.values(craftingBucketsJson)
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export default function CraftingBucketList() {

  const dispatch = useDispatch()
  const navigate = useHistory()
  const [craftingChests, setCraftingChests] = useState([])
  const [selectedZone, setSelectedZone] = useState('')
  const [selectedCrafter, setSelectedCrafter] = useState()
  const [filteredCraftingChests, setFilteredCraftingChests] = useState([])
  const [materialInventory, setMaterialInventory] = useState([])
  const { wallet } = useSelector(walletSelector)
  const { managers } = useSelector(managersSelector)
  const startScreenRef = useRef(null)

  const filterItemZone = (categItem) => {
    const updatedItems = craftingBucketsData.filter((curElem) => {
      return curElem.name.includes(categItem) && curElem.name.includes(selectedCrafter)
    })
    setFilteredCraftingChests(updatedItems);
    setSelectedZone(categItem)
  }

  const goBack = () => {
    setSelectedCrafter('')
    setSelectedZone('')
  }

  const filterCrafter = (crafter) => {
    startScreenRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const updatedItems = craftingChests.filter((curElem) => {
      return curElem.name.includes(crafter)
    })
    console.log(filteredCraftingChests)
    setSelectedZone('Emerald Canopy')
    setFilteredCraftingChests(updatedItems);
    setSelectedCrafter(crafter)
  }

  const getCraftingBuckets = async () => {
    const craftingBucketData = craftingBucketsData
    // TODO: Implement filter mechanism for crafters.
    const playerBalance = await materialCollection.balanceOfBatch(
      Array(170).fill(wallet.address),
      Array.from(Array(170).keys())
    )
    setSelectedZone('Emerald Canopy')
    setMaterialInventory(playerBalance)
    setFilteredCraftingChests(craftingBucketData)
    console.log(craftingBucketData)
    setCraftingChests(craftingBucketData)
  }

  const checkMaterialAmount = (craftingChest, materialId, index) => {
    console.log(materialInventory)
    console.log(materialId)
    console.log(index)
    if (parseInt(materialInventory?.[materialId]) >= parseInt(craftingChest?.bcData?.requiredMaterialAmounts?.[index])) {
      return { color: '#00fa00' }
    } else {
      return { color: 'red' }
    }
  }

  const setChestInfo = async (chest) => {
    const tempChest = {
      id: chest.id,
      name: chest.name,
      chanceSum: chest.chanceSum,
      craftableEquipments: chest.craftableEquipments,
      enabled: chest.enabled,
      exists: chest.exists,
      requiredMaterialAmounts: chest.requiredMaterialAmounts,
      requiredMaterialIds: chest.requiredMaterialIds
    }
    dispatch(SET_CRAFTING_BUCKET(tempChest))
    await delay(500)
    navigate.push('/crafting-bucket-details')
  }

  useEffect(() => {
    if (managers && wallet) {
      getCraftingBuckets()
    }
  }, [managers, wallet])
  return (
    <section ref={startScreenRef} className="game-manage-area select-crafting-bucket-bg pt-120 pb-120">
      <div className="container pt-60">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8">
            <div className="section-title title-style-two text-center mb-60">
              <h2>Craft <span style={{ color: 'gold' }}>Equipment</span></h2>
            </div>
          </div>
        </div>
        {selectedCrafter ? (
          <>
            <div className='row'>
              <div className='col-lg-11'>
                <h2> Crafter: <span style={{ color: 'gold' }}>{selectedCrafter}</span></h2>
              </div>
              <div className='col-lg-1'>
                <div className="justify-content-center pb-20">
                  <a onClick={() => goBack()} className="btn btn-info">Back</a>
                </div>
              </div>
            </div>
            <div className="crafting-buckets-area">
              <div className="row">
                <div className="col-12">
                  <div className="crafting-buckets-top-wrap">
                    <div className="section-title tournament-title">
                      <h2>Zone <span>Filter</span></h2>
                    </div>
                    <div className="tournament-menu">
                      <button className={selectedZone == 'Emerald Canopy' ? 'active' : ''} data-filter=".cat-one" onClick={() => filterItemZone('Emerald Canopy')}>Emerald Canopy</button>
                      <button className={selectedZone == 'Crystal Caverns' ? 'active' : ''} data-filter=".cat-two" onClick={() => filterItemZone('Crystal Caverns')}>Crystal Caverns</button>
                  {/*  <button className={selectedZone == 'Crimson Torrent' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemZone('Crimson Torrent')}>Crimson Torrent</button>
                       <button className={selectedZone == 'Whispering Shadows' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemZone('Whispering Shadows')}>Whispering Shadows</button>
                  <button className={selectedZone == 'Howling Winds' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemZone('Howling Winds')}>Howling Winds</button>
                  <button className={selectedZone == 'Shattered Sky' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemZone('Shattered Sky')}>Shattered Sky</button>
                  <button className={selectedZone == 'The Fallen' ? 'active' : ''} data-filter=".cat-three" onClick={() => filterItemZone('The Fallen')}>The Fallen</button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  filteredCraftingChests?.map((craftingChest, index) => {
                    return (<>
                      <Fade up>
                        <div className="col-12" key={index}>
                          <div className="tournament-schedule-wrap">
                            <div className="tournament-active">
                              <div className="single-tournament grid-item cat-three cat-two">
                                <div className="row no-gutters">
                                  <div className="col-lg-4 ">
                                    <h4 className='mb-20'></h4>
                                    <div index={index} className="latest-games-items">
                                      <div className="latest-games-thumb mb-20 pr-10 pl-10">
                                        <img src={crafterImg(craftingChest?.name)} alt="" />
                                      </div>
                                      <div className='row pr-10 pl-10'>
                                        <div className="col-lg-2 col-2 mb-20">
                                          <div className="latest-games-thumb">
                                            <img src="assets/img/goc/itemraritylogo/2_2.webp" alt="" />
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                          <div className="latest-games-thumb">
                                            <img src="assets/img/goc/itemraritylogo/1_1.webp" alt="" />
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                          <div className="latest-games-thumb">
                                            <img src="assets/img/goc/itemraritylogo/3_3.webp" alt="" />
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                          <div className="latest-games-thumb">
                                            <img src="assets/img/goc/itemraritylogo/4_4.webp" alt="" />
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                          <div className="latest-games-thumb">
                                            <img src="assets/img/goc/itemraritylogo/5_5.webp" alt="" />
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                          <div className="latest-games-thumb">
                                            <img src="assets/img/goc/itemraritylogo/0_3.webp" alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="tournament-schedule-content" style={zoneBackgroundImg(craftingChest?.name)}>
                                      <h3><a onClick={() => setChestInfo(craftingChest)}>Recipe: <span>{craftingChest?.name}</span></a></h3>
                                      <div className='row'>
                                        {craftingChest?.bcData?.requiredMaterialIds?.map((materialId, index) => {
                                          return (<>
                                            <div key={index} className="col-lg-6">
                                              <div className="latest-match-box mb-30">
                                                <div className="just-gamers-list-icon">
                                                  <img src={materialImg(materialId)} alt="" />
                                                </div>
                                                <div className="just-gamers-list-content justify-content-center fix">
                                                  <h5 style={checkMaterialAmount(craftingChest, materialId, index)} >{materialData[materialId]?.name}</h5>
                                                  <p style={checkMaterialAmount(craftingChest, materialId, index)} >{materialInventory?.[materialId]}/{craftingChest?.bcData?.requiredMaterialAmounts[index]} </p>
                                                </div>
                                              </div>
                                            </div>
                                          </>)
                                        })}
                                      </div>
                                      <div className="tournament-schedule-meta">
                                        {/* <h5>price : <span>{craftingChest?.cost} {wallet ? wallet.symbol : ''}</span></h5> */}
                                        {/* <a className='button-hover-custom' onClick={() => setChestInfo(craftingChest)}>Zone</a> */}

                                        <a className='button-hover-custom' onClick={() => setChestInfo(craftingChest)}>Details</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </>
                    )
                  })
                }

              </div>
            </div>
          </>) : (
          <Fade up>
            <div className='row'>
              <div className="col-lg-6 col-md-6">
                <div className="upcoming-game-item mb-10">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4><a href style={{ color: 'gold' }}>Blacksmith</a></h4>
                    </div>
                  </div>
                  <div className="upcoming-game-thumb">
                    <img src={crafterImg('Blacksmith')} alt="" />
                    <div className="upcoming-game-cart" style={{ color: 'black' }}>
                      <a href style={{ color: 'gold' }} onClick={() => filterCrafter('Blacksmith')} className="btn transparent-btn">Details</a>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-6 col-md-6"></div>
              <div className="col-lg-6 col-md-6">
                <div className="upcoming-game-item mb-10">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4><a href style={{ color: 'gold' }} >Armorsmith</a></h4>
                    </div>
                  </div>
                  <div className="upcoming-game-thumb">
                    <img src={crafterImg('Armorsmith')} alt="" />
                    <div className="upcoming-game-cart">
                      <a href style={{ color: 'gold' }} onClick={() => filterCrafter('Armorsmith')} className="btn transparent-btn">Details</a>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-6 col-md-6">
                <div className="upcoming-game-item mb-10">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4><a href style={{ color: 'gold' }}>Tailor</a></h4>
                    </div>
                  </div>
                  <div className="upcoming-game-thumb">
                    <img src={crafterImg('Tailor')} alt="" />
                    <div className="upcoming-game-cart">
                      <a href style={{ color: 'gold' }} onClick={() => filterCrafter('Tailor')} className="btn transparent-btn">Details</a>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-6 col-md-6"></div>
              <div className="col-lg-6 col-md-6">
                <div className="upcoming-game-item mb-10">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4><a href style={{ color: 'gold' }}>Woodcarver</a></h4>
                    </div>
                  </div>
                  <div className="upcoming-game-thumb">
                    <img src={crafterImg('Woodcarver')} alt="" />
                    <div className="upcoming-game-cart">
                      <a href style={{ color: 'gold' }} onClick={() => filterCrafter('Woodcarver')} className="btn transparent-btn">Details</a>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-6 col-md-6">
                <div className="upcoming-game-item mb-10">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4><a href style={{ color: 'gold' }}   >Jeweler</a></h4>
                    </div>
                  </div>
                  <div className="upcoming-game-thumb">
                    <img src={crafterImg('Jeweler')} alt="" />
                    <div className="upcoming-game-cart">
                      <a href style={{ color: 'gold' }} onClick={() => filterCrafter('Jeweler')} className="btn transparent-btn">Details</a>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </Fade>
        )}
      </div>
    </section >
  )
}
