import { store } from '../../store'
import Web3 from 'web3'

// ABIS
import materialCollectionABI from '../../chain/abis/abi_MaterialCollection.json'

export const balanceOfBatch = async (addresses, materialIds) => {
  const materialCollectionAddress = await store.getState().collections.collections.materialCollectionAddress
  const web3 = new Web3(window.ethereum)
  const materialCollectionContract = new web3.eth.Contract(
    materialCollectionABI,
    materialCollectionAddress
  )
  return await materialCollectionContract.methods.balanceOfBatch(addresses, materialIds).call()
}

export const tokenOfOwnerByIndex = async (address, i) => {
  const materialCollectionAddress = await store.getState().collections.collections.materialCollectionAddress
  const web3 = new Web3(window.ethereum)
  const materialCollectionContract = new web3.eth.Contract(
    materialCollectionABI,
    materialCollectionAddress
  )
  return materialCollectionContract.methods.tokenOfOwnerByIndex(address, i).call()
}

export const tokenURI = async (tokenId) => {
  const materialCollectionAddress = await store.getState().collections.collections.materialCollectionAddress
  const web3 = new Web3(window.ethereum)
  const materialCollectionContract = new web3.eth.Contract(
    materialCollectionABI,
    materialCollectionAddress
  )
  return await materialCollectionContract.methods.tokenURI(tokenId).call()
}
