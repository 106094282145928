import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  wallet: null
}

export const setWallet = state => async (dispatch) => {
  await dispatch(SET_WALLET(state))
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    SET_WALLET: (state, action) => {
      state.wallet = action.payload
    }
  }
})

export const {
  SET_WALLET
} = walletSlice.actions
export default walletSlice.reducer
