import { Triangle } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { addNetworkToMetamask } from '../../chain/chain-service/helper'

export const StartTransaction = () => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html: (<>
            <div className="row justify-content-center mb-50">
                <Triangle
                    height="80"
                    width="80"
                    color="#2DABF0"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
            <h3>Waiting for confirmation...</h3>
        </>),
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}

export const TransactionSent = () => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html: (<>
            <div className="row justify-content-center mb-20">
                <h3>Transaction sent!</h3>
            </div>
        </>),
        timer: 3000,
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}

export const TransactionError = (message, navigation) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html: (<>
            <div className="row justify-content-center mb-20">
                <h5 style={{ color: 'red' }}>{message}</h5>
            </div>
            <div className="row justify-content-center mb-20">
                {message == 'Please Select Hero first' ? (
                    <a onClick={() => {
                        Swal.close()
                        navigation.push('/select-hero')
                    }} className="btn btn-style-two">Select Hero</a>
                ) : ''}
            </div>
        </>),
        icon: 'error',
        title: 'Oops...',
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}

export const NotSupportedNetworkError = (message) => {
    const MySwal = withReactContent(Swal)
    const addNetwork = (type) => {
        addNetworkToMetamask(type)
        Swal.close()
    }
    MySwal.fire({
        html: (<>
            <div className="row justify-content-center mb-20">
                <h4 style={{ color: 'white' }}>Supported Networks:</h4>
            </div>
            <div className="row justify-content-center mb-20">
                <br />
                <a href className="btn btn-style-two" onClick={() => addNetwork('web3')}> Add GoC Network</a>
            </div>
        </>),
        icon: 'info',
        title: message,
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}