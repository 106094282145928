export const chainPicker = (chainId) => {
  const chainInfos = [
    {
      1337: {
        name: 'GOCCHAIN',
        symbol: 'ETH',
        gasMultiplier: 2,
        staminaPerSecond: 0.1,
        sendEncounterFallBackGasAmount: 30000000,
        gameMasterAddress: '0x5304E91833475f045CAD67C896116b0ebB8070e7',
        multicallAddress: '0x15a1453ec870B8DE3c0EF5cB4Cc03A08f8a8DE11',
        fallbackRPC: 'https://rpc.guildofchains.com'
      }
    }
  ]
  return Object.values(chainInfos).map(
    (chainInfo) => Object.keys(
      chainInfo
    )
  ).flat().includes(chainId.toString()) ? chainInfos[0][chainId] : null
}
