import { store } from '../../store'
import { chainPicker } from '../helpers/chainInfo'
import Web3 from 'web3'

// ABIS
import heroCollectionABI from '../../chain/abis/abi_HeroCollection.json'
import multicallABI from '../../chain/abis/abi_Multicall.json'
import gameMasterABI from "../../chain/abis/abi_GameMaster.json";

export const balanceOf = async (address) => {
  const heroCollectionAddress = await store.getState().collections.collections.heroCollectionAddress
  const web3 = new Web3(window.ethereum)
  const heroCollectionContract = new web3.eth.Contract(
    heroCollectionABI,
    heroCollectionAddress
  )
  return await heroCollectionContract.methods.balanceOf(address).call()
}

export const ownerOf = async (tokenId) => {
  const heroCollectionAddress = await store.getState().collections.collections.heroCollectionAddress
  const web3 = new Web3(window.ethereum)
  const heroCollectionContract = new web3.eth.Contract(
    heroCollectionABI,
    heroCollectionAddress
  )
  return await heroCollectionContract.methods.ownerOf(tokenId).call()
}

export const ownerOfFallback = async (tokenId, chainId) => {
  const { gameMasterAddress, fallbackRPC } = chainPicker(chainId)
  const web3 = new Web3(new Web3.providers.HttpProvider(fallbackRPC))
  const gameMasterContract = new web3.eth.Contract(
    gameMasterABI,
    gameMasterAddress
  );
  const heroCollectionAddress = await gameMasterContract.methods.heroCollection().call();
  const heroCollectionContract = new web3.eth.Contract(
    heroCollectionABI,
    heroCollectionAddress
  )
  return await heroCollectionContract.methods.ownerOf(tokenId).call()
}

export const tokenOfOwnerByIndex = async (address, i) => {
  const heroCollectionAddress = await store.getState().collections.collections.heroCollectionAddress
  const web3 = new Web3(window.ethereum)
  const heroCollectionContract = new web3.eth.Contract(
    heroCollectionABI,
    heroCollectionAddress
  )
  return await heroCollectionContract.methods.tokenOfOwnerByIndex(address, i).call()
}

export const tokenURI = async (tokenId) => {
  const heroCollectionAddress = await store.getState().collections.collections.heroCollectionAddress
  const web3 = new Web3(window.ethereum)
  const heroCollectionContract = new web3.eth.Contract(
    heroCollectionABI,
    heroCollectionAddress
  )
  return await heroCollectionContract.methods.tokenURI(tokenId).call()
}

export const tokensOfOwner = async (address) => {
  const { multicallAddress } = chainPicker(store.getState().wallet.wallet.chainId)
  const heroCollectionAddress = await store.getState().collections.collections.heroCollectionAddress
  const balance = await balanceOf(address);
  const web3 = new Web3(window.ethereum)
  const heroCollectionContract = new web3.eth.Contract(
    heroCollectionABI,
    heroCollectionAddress
  )
  const multicallContract = new web3.eth.Contract(
    multicallABI,
    multicallAddress
  )

  const inputs = []

  for (let i = 0; i < balance; i++) {
    inputs.push({ target: heroCollectionAddress, callData: heroCollectionContract.methods.tokenOfOwnerByIndex(address, i).encodeABI() })
  }

  return await multicallContract.methods.aggregate(inputs).call()
}