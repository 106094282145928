import { combineReducers, configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import wallet from './modules/wallet'
import collections from './modules/collections'
import selectedHero from './modules/selectedHero'
import managers from './modules/managers'
import masters from './modules/masters'
import storage from 'redux-persist/lib/storage'
import heroBucket from './modules/heroBucket'
import transactionHistory from './modules/transactionHistory'
import latestTransaction from './modules/latestTransaction'
import craftingBucket from './modules/craftingBucket'

import { persistReducer, persistStore } from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['wallet', 'collections', 'selectedHero', 'managers', 'masters', 'heroBucket', 'transactionHistory', 'latestTransaction', 'craftingBucket'],
}
const persistedReducer = persistReducer(persistConfig, combineReducers({
    wallet,
    collections,
    selectedHero,
    managers,
    masters,
    heroBucket,
    transactionHistory,
    latestTransaction,
    craftingBucket,
}))
const middlewares = [thunk]
export const store = configureStore({
    reducer: persistedReducer,
    middleware: middlewares
})
// middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//   serializableCheck: false,
// }),
export const persistor = persistStore(store)
