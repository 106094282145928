
import React from 'react';
import CountUp from "react-countup";
import * as chainHelper from '../../chain/chain-service/helper'
// JSON Data
import stats from '../../chain/enumarations/stats.json'
import { getRarityTextColor } from '../../chain/helpers/parseChainData';
import { statImg } from '../../store/image-service';

function EquipmentBasicStats({ equipment }) {

    return (<>
        {equipment?.flatModifiers?.map((stat, index) => (
            <>
                {chainHelper.calculateStatValueArray(stat) || chainHelper.calculateStatValueArray(equipment?.percentageModifiers[index]) > 0 ? (
                    <>
                        {chainHelper.calculateStatValueArray(stat) > 0 ? (
                            <h6><img width={'25px'} src={statImg(stats[stat.statId])} alt="" />{stats[stat.statId].slice(0, 7)} <span style={{ color: '#1bd000' }}>+{chainHelper.calculateStatValueArray(stat)}</span></h6>
                        ) : ''}
                        {chainHelper.calculateStatValueArray(equipment?.percentageModifiers[index]) > 0 ? (
                            <h6><img src={statImg(stats[stat.statId])} alt="" />{stats[stat.statId].slice(0, 7)} <span style={{ color: '#1bd000' }}>+{chainHelper.calculateStatValueArray(equipment?.percentageModifiers[index])}%</span></h6>
                        ) : ''}
                        <span style={{ color: 'white' }}></span>
                    </>
                ) : ''
                }
            </>
        ))}
    </>)
}

export default EquipmentBasicStats