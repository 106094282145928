import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { materialImg } from '../../store/image-service'
import materials from '../../chain/data/materials.json'
export const BuySuccess = (storeItem, count) => {

    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html: (<>
            <div className="col-lg-12">
                <h4>Store item added to your inventory! </h4>
                <div className="latest-match-box mb-30">
                    <div className="latest-match-thumb">
                        <div className="just-gamers-list-icon">
                            <img src={materialImg(storeItem?.details?.materialId)} alt="" />
                        </div>
                        <p><a>Balance :<span> <b>{parseInt(storeItem?.playerBalance?.[0]) + parseInt(count)}</b></span></a></p>
                    </div>
                    <div className="tournament-schedule-content">
                        <h3><a><span>{materials[storeItem?.details?.materialId]?.name}</span></a></h3>
                        <p></p>
                        <div className="tournament-schedule-meta">
                            <h5>Count : <span> {count}</span></h5>
                            <a> Purchased!</a>
                        </div>
                    </div>
                </div>
            </div>
        </>),
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: true,
        background: '#1a1b20'
    })
}